import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Button, Table, message } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Widget as IWidget } from '../../../../Types'
import Context from '../../context'
import { Card, ContentTitle, Title as TitleCard, Text, Content } from './styles'

interface Props {
  config: IWidget
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

interface DataType {
  id: string
  name: string
  vehicles: number
  maintenances: number
  agencies: number
  users: number
  charges: number
  fines: number
  migrations: number
  leases: number
  reports: number
  files: number
  budgets: number
  revolving_funds: number
}

const Report: React.FC<Props> = ({ config, handleDeleteWidget }) => {
  const { widget, id } = config
  const { name, end_point, var_name, download_endpoint } = widget

  const { t } = useTranslation('translate')

  const [data, setData] = useState<DataType[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const [tableHeight, setTableHeight] = useState<number>(0)

  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const { paramsGeneral, newWidgetCreate } = useContext(Context)

  const parentRef = useRef<HTMLDivElement>(null)

  const changeSize = () => {
    const parentHeight = parentRef.current?.clientHeight
    setTableHeight(parentHeight ?? 0)
  }

  useEffect(() => {
    changeSize()
  }, [parentRef.current?.clientHeight])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Cliente',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Vehículos',
      dataIndex: 'vehicles',
      align: 'center',
    },
    {
      title: 'Mantenimientos',
      dataIndex: 'maintenances',
      align: 'center',
    },
    {
      title: 'Gestorías',
      dataIndex: 'agencies',
      align: 'center',
    },
    {
      title: 'Usuarios',
      dataIndex: 'users',
      align: 'center',
    },
    {
      title: 'Cargas de gas',
      dataIndex: 'charges',
      align: 'center',
    },
    {
      title: 'Multas',
      dataIndex: 'fines',
      align: 'center',
    },
    {
      title: 'Migraciones',
      dataIndex: 'migrations',
      align: 'center',
    },
    {
      title: 'Arrendamientos',
      dataIndex: 'leases',
      align: 'center',
    },
    {
      title: 'Descargas de reportes',
      dataIndex: 'reports',
      align: 'center',
    },
    {
      title: 'Archivos',
      dataIndex: 'files',
      align: 'center',
    },
    {
      title: 'Presupuestos',
      dataIndex: 'budgets',
      align: 'center',
    },
    {
      title: 'Fondo revolvente',
      dataIndex: 'revolving_funds',
      align: 'center',
    },
  ]

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${end_point}`, {
        params: {
          ...paramsGeneral,
        },
      })
      setData(res.data)
    } catch (e) {
      message.error('error')
    } finally {
      setLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_point, var_name, paramsGeneral])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const downloandInfo = async () => {
    try {
      setLoader(true)
      const res = await axios.get(end_point, {
        params: {
          ...paramsGeneral,
          download: true,
        },
        responseType: 'blob',
      })
      if (res.data.type === 'application/json') {
        message.info(
          'Descarga en segundo plano solicitada. Verifique el resultado en IAN'
        )
      } else {
        var FileDownload = require('js-file-download')
        FileDownload(
          res.data,
          `${t(`widgets.${var_name}.name`)}-${moment().format('L')}.xlsx`
        )
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  return (
    <Card
      id={`widget_id_${var_name}_card`}
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      title={
        <ContentTitle>
          <TitleCard>{name}</TitleCard>
        </ContentTitle>
      }
      extra={
        <>
          {download_endpoint && (
            <Button
              disabled={loader}
              type="primary"
              shape="circle"
              icon={<i className="fa-solid fa-download" />}
              onClick={() => downloandInfo()}
            />
          )}
          <Button
            type="primary"
            danger
            shape="circle"
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      shadow={+(newWidgetCreate === id)}
    >
      <Content ref={parentRef}>
        <Table
          size="middle"
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          loading={loader}
          scroll={{ y: tableHeight }}
          summary={(pageData) => {
            let totals = {
              vehicles: 0,
              maintenances: 0,
              agencies: 0,
              users: 0,
              charges: 0,
              fines: 0,
              migrations: 0,
              leases: 0,
              reports: 0,
              files: 0,
              budgets: 0,
              revolving_funds: 0,
            }

            pageData.forEach((info): void => {
              Object.entries(info).forEach(([key, value]) => {
                if (totals[key as keyof typeof totals] !== undefined)
                  totals[key as keyof typeof totals] += value
              })
            })

            return (
              <>
                <Table.Summary.Row className="total_sumary_row">
                  <Table.Summary.Cell index={0} align="center">
                    <Text>Total</Text>
                  </Table.Summary.Cell>
                  {Object.entries(totals).map(([_key, value], idx) => {
                    return (
                      <Table.Summary.Cell index={idx} key={idx} align="center">
                        <Text>{value}</Text>
                      </Table.Summary.Cell>
                    )
                  })}
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </Content>
    </Card>
  )
}

export default Report
