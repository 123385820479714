/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Button, message, Badge, Typography } from 'antd'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { Widget } from '../../../../Types'
import { formatDecimal } from '../../../../Utilities/formatDecimal'
import Context from '../../context'
import {
  Card,
  ContentTitle,
  Title as TitleCard,
  Content,
  Info,
  Text,
} from './styles'

const { Title } = Typography

interface StatusCardT {
  value: string
  detail_endpoint: string
  label: string
}
interface Data {
  data: Array<StatusCardT>
  total: number
}
const DEF_DATA: Data = {
  data: [],
  total: 0,
}
interface Props {
  config: Widget
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const MXN = Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
})

const StatusCard: React.FC<Props> = ({ config, handleDeleteWidget }) => {
  const { t } = useTranslation('translate')
  const theme = useContext(ThemeContext)
  const {
    paramsGeneral,
    setModalGeneralVisible,
    setInfoWidget,
    newWidgetCreate,
    setNewWidgetCreate,
  } = useContext(Context)

  const { widget, id } = config
  const [data, setData] = useState<Data>(DEF_DATA)

  const { name, end_point, var_name, money_signal } = widget
  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${end_point}`, {
        params: {
          ...paramsGeneral,
        },
      })

      if (res.data[var_name] && res) {
        setData(res.data[var_name])
      }
    } catch (e) {
      setData(DEF_DATA)
      message.error(
        `${t('components.common.request_error_graph')} ${t(
          `widgets.${var_name}.name`
        )}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_point, var_name, paramsGeneral])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const openModal = (endpoint: string, value: string) => {
    setModalGeneralVisible({
      active: true,
      endpoint: endpoint,
      varName: var_name,
      totalItem: parseInt(value),
    })
  }
  const openInfo = () => {
    setInfoWidget({ active: true, varName: var_name })
  }

  useEffect(() => {
    if (newWidgetCreate !== 0) {
      setTimeout(() => {
        setNewWidgetCreate(0)
      }, 4000)
    }
  }, [newWidgetCreate])

  return (
    <Card
      title={
        <ContentTitle>
          <TitleCard>{t(`widgets.${var_name}.name`)}</TitleCard>
          {data.total > 0 && (
            <Badge
              count={
                money_signal
                  ? MXN.format(data.total)
                  : formatDecimal.format(data.total)
              }
              style={{
                backgroundColor: theme.colors.primary[6],
              }}
              overflowCount={9999999}
            />
          )}
        </ContentTitle>
      }
      extra={
        <>
          <Button
            type="primary"
            shape="circle"
            icon={<i className="fa-solid fa-info" />}
            onClick={() => openInfo()}
          />
          <Button
            type="primary"
            danger
            shape="circle"
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      id={`widget_id_${var_name}_card`}
      shadow={+(newWidgetCreate === id)}
    >
      <Content>
        {data.data.map((el, index) => {
          return (
            <Info key={index}>
              <Text>{t(`widgets.${var_name}.items.${el.label}`)}</Text>
              {el.detail_endpoint && (
                <Title
                  level={4}
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  underline
                  onClick={() => openModal(el.detail_endpoint, el.value)}
                >
                  {money_signal
                    ? MXN.format(parseInt(el.value))
                    : formatDecimal.format(parseInt(el.value))}
                </Title>
              )}
              {!el.detail_endpoint && (
                <Title level={4} style={{ textAlign: 'center' }}>
                  {money_signal
                    ? MXN.format(parseInt(el.value))
                    : formatDecimal.format(parseInt(el.value))}
                </Title>
              )}
            </Info>
          )
        })}
      </Content>
    </Card>
  )
}

export default StatusCard
