import styled from 'styled-components'

export const Text = styled.p`
  /* text-align: center; */
  margin: 0;
`

export const Icon = styled.i`
  color: #fff;
  font-size: 12px;
`

export const ContentActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
