import axios from 'axios'
import i18next from 'i18next'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { GlobalContextProvider } from './context'
import enUS_Dashboard from './Lang/Dashboard/en-US.json'
import esMX_Dashboard from './Lang/Dashboard/es-MX.json'
import enUS_Main from './Lang/Main/en-US.json'
import esMX_Main from './Lang/Main/es-MX.json'
import LanguageProvider from './Utilities/LanguageCTX'
import ThemeCTXProvider from './Utilities/ThemeCTX'
import Views from './Views'
import LogRocket from 'logrocket'

const themes = {
  light: '/light.css',
  dark: '/dark.css',
}

let BASEURL = ''
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('yabpom/ian')
  const DOMAIN = process.env.REACT_APP_BASE_URL_DOMAIN || ''
  if (DOMAIN) {
    const domain = JSON.parse(`${DOMAIN}`)
    BASEURL = `https://${
      domain[`${window.location.hostname}`] || 'test.sfleet.mx'
    }`
  } else {
    BASEURL = 'https://test.sfleet.mx'
  }
} else {
  BASEURL = 'http://localhost:3000'
}

axios.defaults.baseURL = `${BASEURL}/api`
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  (response) => {
    response.headers.Accept = 'application/json'
    return response
  },
  (error) => {
    const { status } = error.response
    if (status === 401) {
      return window.location.replace(
        `${BASEURL}/users/sign_in?redirect_to=${window.location.href}`
      )
    }

    return Promise.reject(error)
  }
)
const App = () => {
  const defLng = localStorage.getItem('i18next')

  // eslint-disable-next-line import/no-named-as-default-member
  i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: defLng || 'es-MX', // language to use
    resources: {
      'es-MX': {
        translate: {
          ...esMX_Main,
          ...esMX_Dashboard,
        },
      },
      'en-US': {
        translate: {
          ...enUS_Main,
          ...enUS_Dashboard,
        }, // 'common' is our custom namespace
      },
    },
  })

  return (
    <GlobalContextProvider baseUrl={BASEURL}>
      <ThemeSwitcherProvider
        themeMap={themes}
        insertionPoint={document.getElementById('styles-insertion-point')}
      >
        <I18nextProvider i18n={i18next}>
          <LanguageProvider>
            <ThemeCTXProvider>
              <Views />
            </ThemeCTXProvider>
          </LanguageProvider>
        </I18nextProvider>
      </ThemeSwitcherProvider>
    </GlobalContextProvider>
  )
}

export default App
