/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Typography, message, Spin } from 'antd'
import axios from 'axios'
import { Layouts } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { KeyValue, Widget as IWidget } from '../../../../Types'
import Context from '../../context'
import DataDisplay from './DataDisplay'
import Filters from './Filters'
import { BodyWrapper, Card, SeeTable } from './styles'
import TableUI from './Table'

interface Props {
  config: IWidget
  layouts: Layouts
  setLayouts: Dispatch<SetStateAction<Layouts>>
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const { Link } = Typography
const Chart: React.FC<Props> = ({
  config: propsConfig,
  setLayouts,
  layouts,
  handleDeleteWidget,
}) => {
  const { t } = useTranslation('translate')
  const theme = useContext(ThemeContext)

  // TODO: Properly type config object
  const { widget, id, open_table } = propsConfig
  const {
    name,
    var_name,
    end_point,
    especial_filter,
    date_parameter,
    status_msr,
    download_endpoint,
    download_table_modal,
  } = widget
  const [chartHeight, setChartHeight] = useState<number>(0)
  const { paramsGeneral, setInfoWidget, newWidgetCreate, setNewWidgetCreate } =
    useContext(Context)

  // TODO: Properly type params object
  const [typeGraph, setTypeGraph] = useState<string>('pie')
  const [loader, setLoader] = useState<boolean>(false)
  const [params, setParams] = useState<KeyValue>({
    filter_range_date: date_parameter ? 'date_close_provider' : undefined,
    status_msr: status_msr ? 'total' : undefined,
  })

  const [typeGraphActive, setTypeGraphActive] = useState<string>('agruped')
  const [data, setData] = useState([])
  const [openTable, setOpenTable] = useState<boolean>(open_table || false)
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false)

  const parentRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement>(null)

  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const changeSize = () => {
    const parentHeight = parentRef.current?.clientHeight || 0
    const filtersHeight = filtersRef.current?.clientHeight || 150
    const tableHeight = tableRef.current?.clientHeight || 0
    let tableH = tableHeight > 460 ? 460 : tableHeight
    if (openTable) {
      tableH = 460
    }
    const height = parentHeight - filtersHeight - 12 - tableH
    setChartHeight(height)
  }
  useEffect(() => {
    changeSize()
  }, [
    parentRef.current?.clientHeight,
    filtersRef.current?.clientHeight,
    tableRef.current?.clientHeight,
    openTable,
  ])

  const openTableWidget = async (newLayouts: Layouts) => {
    try {
      await axios.patch(`/ala/dashboard_widgets/${id}`, {
        open_table: !openTable,
      })
      setOpenTable(!openTable)
      setLayouts(newLayouts)
    } catch {}
  }

  const getDataLayout = (Data: { h: number; i: string }[]) => {
    const newData = Data.map((el) => {
      if (el.i === String(id)) {
        return {
          ...el,
          h: openTable ? el.h - 5 : el.h + 5,
          isResizable: openTable ? true : false,
        }
      }
      return el
    })
    return newData
  }
  const handleOpenTable = () => {
    let newLayouts = {}

    if (layouts.lg) {
      const lg = getDataLayout(layouts.lg)
      newLayouts = { ...newLayouts, lg }
    }
    if (layouts.md) {
      const md = getDataLayout(layouts.md)
      newLayouts = { ...newLayouts, md }
    }
    if (layouts.sm) {
      const sm = getDataLayout(layouts.sm)
      newLayouts = { ...newLayouts, sm }
    }
    if (layouts.xs) {
      const xs = getDataLayout(layouts.xs)
      newLayouts = { ...newLayouts, xs }
    }
    if (layouts.xxs) {
      const xxs = getDataLayout(layouts.xxs)
      newLayouts = { ...newLayouts, xxs }
    }

    openTableWidget(newLayouts)
  }

  const getGraphic = useCallback(async () => {
    try {
      setLoader(true)
      const res = await axios.get(end_point, {
        params: {
          ...paramsGeneral,
          ...params,
        },
      })
      setData(res.data)

      setOpenTable(openTable || false)
    } catch (e) {
      setData([])
      message.error(
        `${t('components.common.request_error_graph')} ${t(
          `widgets.${var_name}.name`
        )}`
      )

      // if (axios.isAxiosError(e)) {
      //   const messageError = e.response?.data.message;
      //   console.log(Object.values(messageError));
      // }
      // TODO: setup sentry
    } finally {
      setLoader(false)
    }
  }, [params, paramsGeneral])

  useEffect(() => {
    // if (params.group_by) {
    getGraphic()
    // }
  }, [getGraphic])
  const openInfo = () => {
    setInfoWidget({ active: true, varName: var_name })
  }

  useEffect(() => {
    if (newWidgetCreate !== 0) {
      setTimeout(() => {
        setNewWidgetCreate(0)
      }, 4000)
    }
  }, [newWidgetCreate])
  return (
    <Card
      title={t(`widgets.${var_name}.name`)}
      // title={name}
      extra={
        <>
          <Button
            disabled={loader}
            type="primary"
            shape="circle"
            icon={
              downloadLoader ? (
                <LoadingOutlined />
              ) : (
                <i className="fa-solid fa-download" />
              )
            }
            onClick={() => setDownloadLoader(true)}
          />
          <Button
            type="primary"
            shape="circle"
            style={{ marginLeft: '5px' }}
            icon={<i className="fa-solid fa-info" />}
            onClick={() => openInfo()}
          />
          <Button
            type="primary"
            shape="circle"
            danger
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      shadow={+(newWidgetCreate === id)}
      // Feels more intuitive than passing value through styled props and then targetting a class.
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      id={`widget_id_${var_name}_card`}
    >
      <BodyWrapper ref={parentRef}>
        <div ref={filtersRef}>
          <Filters
            loader={loader}
            typeGraph={typeGraph}
            setTypeGraph={setTypeGraph}
            params={params}
            setParams={setParams}
            typeGraphActive={typeGraphActive}
            setTypeGraphActive={setTypeGraphActive}
            specialFilter={especial_filter}
            dateParameter={date_parameter}
            statusMsr={status_msr}
            varName={var_name}
          />
        </div>

        {/* Parents of Antd charts MUST have a SET height, otherwise y-axis responsiveness won't work as of @ant-design/charts v1.2.13 */}
        <Spin tip={t('components.common.loading_graph')} spinning={loader}>
          <div
            // 24 refers to the hardcoded padding value for ant desing's card component
            style={{
              height: chartHeight - 24,
            }}
          >
            <DataDisplay
              typeGraph={typeGraph}
              data={data}
              params={params}
              typeGraphActive={typeGraphActive}
              varName={var_name}
            />
          </div>
        </Spin>
        <SeeTable>
          <Link onClick={handleOpenTable} disabled={loader}>
            {t('components.common.see_table')}{' '}
            <i
              className="fa-solid fa-angle-down"
              style={{
                transform: openTable ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: `${theme.transitions.durations.normal}s}`,
              }}
            />
          </Link>
        </SeeTable>
        <div ref={tableRef}>
          <TableUI
            openTable={openTable}
            varName={var_name}
            data={data}
            loader={loader}
            downloadTable={downloadLoader}
            setDownloadTable={setDownloadLoader}
            downloadTableModal={download_table_modal}
          />
        </div>
      </BodyWrapper>
    </Card>
  )
}

export default Chart
