import { generate } from '@ant-design/colors'
import { DefaultTheme } from 'styled-components'

const red = generate('#e5054d')
const green = generate('#16b38f')
const gold = generate('#fabd23')

const light: DefaultTheme = {
  isDark: false,
  borderRadius: {
    normal: '0.5rem',
  },
  transitions: {
    durations: {
      short: 0.15,
      normal: 0.25,
    },
  },
  colors: {
    primary: generate('#3bbac8'),
    red,
    volcano: generate('#fe561e'),
    orange: generate('#fa9723'),
    gold,
    yellow: generate('#ffd300'),
    lime: generate('#8cbd23'),
    green,
    cyan: generate('#45c0f2'),
    blue: generate('#0776d5'),
    geekblue: generate('#2d55e4'),
    purple: generate('#8442e6'),
    magenta: generate('#b91294'),
    pink: generate('#b91294'),
    success: green[5],
    warning: gold[5],
    error: red[5],
    menuBackground: '#092134',
    componentBackground: '#fff',
    componentBorder: '#D9D9D9',
    bodyBackground: '#061623',
    menuBorderColor: '#154c7a',
    textColor: 'rgba(0, 0, 0, 0.85)',
  },
  borderColor: '#f0f0f0',
}

export default Object.freeze(light)
