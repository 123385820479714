import React, { Dispatch, SetStateAction } from 'react'
import { Select, Row, Col, Typography } from 'antd'
import { KeyValue } from '../../../../Types'
import { FiltersContainer } from './styles'

const { Text } = Typography

interface Props {
  params: KeyValue
  setParams: Dispatch<SetStateAction<KeyValue>>
  filters: []
}

export interface FiltersT {
  id: number
  name: string
}

const Filters: React.FC<Props> = ({ params, setParams, filters }) => {
  const handleChange = (val: any, type: string, multiple: boolean) => {
    setParams({
      ...params,
      [type]: multiple ? val.join() : val,
    })
  }
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <FiltersContainer>
      <Row gutter={[16, 10]}>
        {filters.map((el: any, index: number) => (
          <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
            <Text>{el.name}</Text>
            <Select
              allowClear
              mode={el.multiple ? 'multiple' : undefined}
              style={{ width: '100%' }}
              onChange={(val) => handleChange(val, el.var_name, el.multiple)}
              placeholder={el.placeholder}
              options={el.data}
              showSearch
              filterOption={filterOption}
            />
          </Col>
        ))}
      </Row>
    </FiltersContainer>
  )
}

export default Filters
