import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { TourWidget as Tour } from './styles'

const TourView = () => {
  const { colors } = useTheme()
  const { t } = useTranslation('translate')
  const [openTour, setOpenTour] = useState(false)
  const closeTour = () => {
    setOpenTour(false)
  }

  useEffect(() => {
    if (localStorage.getItem('tour') === 'false') {
      setOpenTour(true)
    }
  }, [])
  const onChange = (el: any) => {
    localStorage.setItem('tour', el.target.checked)
  }
  const tourConfig = [
    {
      selector: '[data-tut="reactour__init"]',
      content: t('tour.init'),
    },
    {
      selector: '[data-tut="reactour__create_newDashboard"]',
      content: t('tour.create_new_dashboard'),
    },
    {
      selector: '[data-tut="reactour__filter_general"]',
      content: t('tour.filter_general'),
    },
    {
      selector: '[data-tut="reactour__create_widget"]',
      content: (
        <div>
          {t('tour.create_widget')}
          <Checkbox onChange={onChange} style={{ marginTop: '10px' }}>
            No volver a mostrar
          </Checkbox>
        </div>
      ),
      highlightedSelectors: ['[data-tut="reactour__create_widget--observe"]'],
    },
  ]

  return (
    <Tour
      onRequestClose={closeTour}
      steps={tourConfig}
      isOpen={openTour}
      accentColor={colors.primary[5]}
      rounded={5}
      className={'tour'}
    />
  )
}

export default TourView
