/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { Typography, message, Space, Tooltip, Spin } from 'antd'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { Widget } from '../../../../Types'
import { formatDecimal } from '../../../../Utilities/formatDecimal'
import Context from '../../context'
import Chart from './Chart'
import {
  Card,
  CardTitle,
  CardBody,
  ButtonDelete,
  ButtonChange,
  Content,
} from './styles'
interface Props {
  config: Widget
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const { Text, Title } = Typography

interface DataPrediction {
  by_month: { x: string; y: number }[]
  prediction_next_month?: number
  expense_next_month?: number
}
interface Data {
  by_month: { x: string; y: number }[]
  total_expense?: number
  total_in_period: number
  percentage?: string
  prediction?: DataPrediction
  detail_endpoint?: string
  total?: number
}

const DEF_DATA: Data = {
  by_month: [],
  total_in_period: 0,
}

const MXN = Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
})

const KPI: React.FC<Props> = ({ config, handleDeleteWidget }) => {
  const { t } = useTranslation('translate')
  const theme = useContext(ThemeContext)
  const {
    paramsGeneral,
    newWidgetCreate,
    setNewWidgetCreate,
    setModalGeneralVisible,
  } = useContext(Context)
  const [data, setData] = useState<Data>(DEF_DATA)
  const { widget, id } = config
  const { name, color, end_point, var_name } = widget
  const {
    prediction,
    by_month,
    total_expense,
    total_in_period,
    percentage,
    detail_endpoint,
    total,
  } = data

  const parentRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [chartHeight, setChartHeight] = useState<number>(0)
  const [changePrediction, setChangePrediction] = useState<boolean>(false)
  const [predictionType, setPredictionType] = useState('monthly')
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const textHeight = textRef.current?.clientHeight || 0
    const parentHeight = parentRef.current?.clientHeight || 0
    const height = parentHeight - textHeight
    setChartHeight(height)
  }, [textRef.current?.clientHeight, parentRef.current?.clientHeight])

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${end_point}`, {
        params: {
          ...paramsGeneral,
          prediction_type: predictionType,
        },
      })
      if (res.data[var_name] && res) {
        setData(res.data[var_name])
      }
    } catch (e) {
      setData(DEF_DATA)
      message.error(
        `${t('components.common.request_error_graph')} ${t(
          `widgets.${var_name}.name`
        )}`
      )
    } finally {
      setLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_point, var_name, paramsGeneral, predictionType])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangeWidget = () => {
    setChangePrediction(!changePrediction)
  }

  useEffect(() => {
    if (newWidgetCreate !== 0) {
      setTimeout(() => {
        setNewWidgetCreate(0)
      }, 4000)
    }
  }, [newWidgetCreate])

  const openModal = (endpoint: string, value: number) => {
    setModalGeneralVisible({
      active: true,
      endpoint: endpoint,
      varName: var_name,
      totalItem: value,
    })
  }

  const handleChangePrediction = () => {
    if (predictionType === 'monthly') {
      setPredictionType('yearly')
    } else {
      setPredictionType('monthly')
    }
  }
  return (
    <Content>
      <Spin tip="Cargando" size="small" spinning={loader}>
        <Card
          color={theme?.colors[color ?? 'primary'][!changePrediction ? 6 : 8]}
          ref={parentRef}
          change={changePrediction}
          shadow={+(newWidgetCreate === id)}
        >
          {/* <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} /> */}
          <div ref={textRef}>
            {!changePrediction && (
              <>
                <CardTitle color="white">
                  {/* <Text strong>{name}</Text> */}
                  <Text strong style={{ marginRight: '20px' }}>
                    {t(`widgets.${var_name}.name`)}
                  </Text>
                  {percentage && (
                    <>
                      <Text strong style={{ marginRight: '5px' }}>
                        {percentage}
                      </Text>
                      <i className="fa-solid fa-circle-chevron-up" />
                    </>
                  )}
                </CardTitle>
                <CardBody color="white">
                  <div className="summary">
                    {detail_endpoint && (
                      <Title
                        level={5}
                        style={{ textAlign: 'center', cursor: 'pointer' }}
                        underline
                        onClick={() =>
                          openModal(
                            detail_endpoint,
                            total ? total : total_in_period
                          )
                        }
                      >
                        {formatDecimal.format(total_in_period || 0)}
                      </Title>
                    )}
                    {!detail_endpoint && (
                      <Title level={5}>
                        {formatDecimal.format(total_in_period || 0)}
                      </Title>
                    )}
                  </div>
                  {total_expense !== undefined && (
                    <div className="summary">
                      <Title level={5}>{MXN.format(total_expense || 0)}</Title>
                    </div>
                  )}
                </CardBody>
              </>
            )}
            {changePrediction && (
              <>
                <CardTitle color="white">
                  {/* <Text strong>{name}</Text> */}
                  <Text strong style={{ marginRight: '20px' }}>
                    {predictionType === 'monthly'
                      ? t('components.prediction.monthly')
                      : t('components.prediction.yearly')}
                  </Text>
                  <i className="fa-solid fa-circle-chevron-up" />
                </CardTitle>
                <CardBody color="white">
                  <div className="summary">
                    <Title level={5}>
                      {formatDecimal.format(
                        prediction?.prediction_next_month || 0
                      )}
                    </Title>
                  </div>
                  <div className="summary">
                    <Title level={5}>
                      {MXN.format(prediction?.expense_next_month || 0)}
                    </Title>
                  </div>
                </CardBody>
              </>
            )}
          </div>
          <div
            style={{
              height: chartHeight || 'inherit',
            }}
          >
            <Chart data={!changePrediction ? by_month : prediction?.by_month} />
          </div>
          <ButtonDelete
            type="primary"
            danger
            shape="circle"
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            className="buttonDelete"
          />
          {prediction && (
            <Space style={{ position: 'absolute', bottom: '2%', left: '1%' }}>
              <ButtonChange
                type="primary"
                shape="circle"
                icon={<i className="fa-solid fa-arrow-trend-up" />}
                onClick={() => handleChangeWidget()}
              />
              {changePrediction && (
                <Tooltip
                  title={
                    predictionType === 'yearly'
                      ? 'Predicción del mes'
                      : 'Predicción del Año'
                  }
                >
                  <ButtonChange
                    type="primary"
                    shape="circle"
                    icon={
                      predictionType === 'yearly' ? (
                        <i className="fa-solid fa-calendar-days" />
                      ) : (
                        <i className="fa-solid fa-calendar" />
                      )
                    }
                    onClick={() => handleChangePrediction()}
                  />
                </Tooltip>
              )}
            </Space>
          )}
        </Card>
      </Spin>
    </Content>
  )
}

export default KPI
