import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Select, Row, Col, Typography, Tag } from 'antd'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { KeyValue, WidgetGroups } from '../../../../Types'
import Context from '../../context'
import { FiltersContainer, IconGraph, BottomWrapper, TypeGraph } from './styles'

const { CheckableTag } = Tag
const { Option } = Select
const { Text } = Typography

interface Props {
  params: KeyValue
  setParams: Dispatch<SetStateAction<KeyValue>>
  services: WidgetGroups[]
  typeGraph: string
  setTypeGraph: Dispatch<SetStateAction<string>>
  typeGraphActive: string
  setTypeGraphActive: Dispatch<SetStateAction<string>>
  loader: boolean
  varName: string
  idWidget: number
  setOnlyMoneyGroup: Dispatch<SetStateAction<boolean>>
  dateParameter?: boolean
  statusMsr?: boolean
}

export interface FiltersT {
  id: number
  name: string
}
interface DataInput {
  id?: number
  name?: string
  value?: string
}

const Filters: React.FC<Props> = ({
  params,
  setParams,
  services = [],
  typeGraph,
  setTypeGraph,
  typeGraphActive,
  setTypeGraphActive,
  loader,
  varName,
  idWidget,
  setOnlyMoneyGroup,
  dateParameter,
  statusMsr,
}) => {
  const { carBrands, paramsGeneral } = useContext(Context)
  const [models, setModels] = useState<DataInput[]>([])

  const getClientCarModels = async () => {
    try {
      const res = await axios.get('/ala/asset_models', {
        params: {
          car_brand_id: params?.car_brand_id,
          client_id: paramsGeneral?.client_id,
          user_company_id: paramsGeneral?.user_company_id,
        },
      })

      setModels(res.data)
    } catch (error) {
      setModels([])
    }
  }

  useEffect(() => {
    if (params?.car_brand_id) {
      getClientCarModels()
    }
  }, [params?.car_brand_id])

  const updateWidgetDefault = (item: string) => {
    try {
      axios.patch(`/ala/dashboard_widgets/${idWidget}`, {
        group_by_default: item,
      })
    } catch {
      return
    }
  }

  const updateGraphDefault = (item: string) => {
    try {
      axios.patch(`/ala/dashboard_widgets/${idWidget}`, {
        graph_default: item,
      })
    } catch {
      return
    }
  }

  const { t } = useTranslation('translate')
  const typeGraphT = 'components.common.filters'
  const widgetsT = `widgets.${varName}.catalogues`

  const handleChangeServices = (item: string, index: number) => {
    let frequency: string | undefined = params.frequency
    if (services[index].frequency) {
      if (
        !services[index]?.frequency?.find(
          (el: string) => el === params.frequency
        )
      ) {
        frequency = services[index].frequency?.[0]
      }
    } else {
      frequency = undefined
    }

    setTypeGraph(
      services[index]?.graph_type[typeGraph]
        ? typeGraph
        : services[index]?.graph_default
    )
    setParams((p) => ({
      ...p,
      group_by: item,
      frequency: frequency,
    }))
    setOnlyMoneyGroup(services[index].only_money || false)
    updateWidgetDefault(item)
  }

  const getGraphs = () => {
    const index: number = services.findIndex(
      (el: WidgetGroups) => el.var_name === params.group_by
    )

    return (
      <>
        <Option value={'column'} disabled={!services[index]?.graph_type.column}>
          <div>
            <IconGraph className={'fa-solid fa-chart-column'} />
            {t(`${typeGraphT}.type_graphic.options.column`)}
          </div>
        </Option>
        <Option value="bar" disabled={!services[index]?.graph_type.bar}>
          <div>
            <IconGraph className="fa-solid fa-chart-bar" />
            {t(`${typeGraphT}.type_graphic.options.bar`)}
          </div>
        </Option>
        <Option value="pie" disabled={!services[index]?.graph_type.pie}>
          <div>
            <IconGraph className={'fa-solid fa-chart-pie'} />
            {t(`${typeGraphT}.type_graphic.options.pie`)}
          </div>
        </Option>
        <Option value="line" disabled={!services[index]?.graph_type.line}>
          <div>
            <IconGraph className={'fa-solid fa-chart-line'} />
            {t(`${typeGraphT}.type_graphic.options.line`)}
          </div>
        </Option>
        <Option value="area" disabled={!services[index]?.graph_type.area}>
          <div>
            <IconGraph className={'fa-solid fa-chart-area'} />
            {t(`${typeGraphT}.type_graphic.options.area`)}
          </div>
        </Option>
        {services[index]?.graph_type.double_axis && (
          <Option
            value="double_axis"
            disabled={!services[index]?.graph_type.double_axis}
          >
            <div>
              <IconGraph className={'fa-solid fa-chart-area'} />
              {t(`${typeGraphT}.type_graphic.options.double_axis`)}
            </div>
          </Option>
        )}
      </>
    )
  }

  let frequencyView = false

  services.forEach((el) => {
    if (el.frequency) {
      frequencyView = true
    }
  })

  const handleFilterChangeMultiple = (name: string, value = []) => {
    setParams({
      ...params,
      [name]: value.join(),
    })
  }
  return (
    <FiltersContainer>
      <Row gutter={[16, 10]}>
        {frequencyView && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>{t(`${typeGraphT}.periodicity.label`)}</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params.frequency}
              disabled={!params.frequency || loader}
              onSelect={(v: string) =>
                setParams((p) => ({ ...p, frequency: v }))
              }
              placeholder={
                !params.frequency
                  ? t(`${typeGraphT}.periodicity.placeholder`)
                  : ''
              }
            >
              {services.map((el) => {
                if (el.var_name === params.group_by && el.frequency) {
                  return el.frequency.map((item: string, key: number) => (
                    <Option key={key} value={item}>
                      {t(`${typeGraphT}.periodicity.options.${item}`)}
                    </Option>
                  ))
                }
                return ''
              })}
            </Select>
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Text>{t(`${typeGraphT}.type_graphic.label`)}</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            value={typeGraph}
            onSelect={(v: string) => {
              setTypeGraph(v)
              updateGraphDefault(v)
            }}
          >
            {getGraphs()}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Text>Marca</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            mode="multiple"
            showSearch
            value={
              (params?.car_brand_id
                ? params?.car_brand_id.split(',').map(Number)
                : undefined) as []
            }
            onChange={(value) =>
              handleFilterChangeMultiple('car_brand_id', value)
            }
            placeholder={t(`${typeGraphT}.select_all`)}
            allowClear
            filterOption={(input: string, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {carBrands.map((el: any) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Text>Modelo</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            mode="multiple"
            showSearch
            value={
              (params?.car_model_id
                ? params?.car_model_id.split(',').map(Number)
                : undefined) as []
            }
            onChange={(value) =>
              handleFilterChangeMultiple('car_model_id', value)
            }
            placeholder={t(`${typeGraphT}.select_all`)}
            allowClear
            disabled={!params?.car_brand_id}
            filterOption={(input: string, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {models.map((el: any) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Col>
        {dateParameter && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>Parámetro de fecha</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params?.filter_range_date}
              onChange={(value) =>
                setParams((p) => ({ ...p, filter_range_date: value }))
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
            >
              <Option value="date_close_provider">Fecha de cierre</Option>
              <Option value="created_at">Fecha de creación</Option>
            </Select>
          </Col>
        )}
        {statusMsr && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>Estatus del folio</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params?.status_msr}
              onChange={(value) =>
                setParams((p) => ({ ...p, status_msr: value }))
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
            >
              <Option value="total">Todos</Option>
              <Option value="open">Abiertos</Option>
              <Option value="closed">Cerrados</Option>
            </Select>
          </Col>
        )}
      </Row>
      <BottomWrapper>
        <div>{t(`${typeGraphT}.group_by.label`)}:</div>
        {services.map((el, index: number) => (
          <CheckableTag
            style={{
              pointerEvents: loader ? 'none' : 'all',
              cursor: loader ? 'not-allowed' : 'pointer',
            }}
            key={el.id}
            checked={el.var_name === params.group_by}
            onChange={() => handleChangeServices(el.var_name, index)}
          >
            {t(`${widgetsT}.${el.var_name}.name`, el.name)}
            {/* {el.name} */}
          </CheckableTag>
        ))}
      </BottomWrapper>
      {params.frequency && typeGraph === 'column' && (
        <TypeGraph>
          <Text style={{ padding: '0 0.5rem 0 0' }}>
            {t(`${typeGraphT}.type.label`)}:
          </Text>
          <CheckableTag
            checked={typeGraphActive === 'agruped'}
            onChange={() => setTypeGraphActive('agruped')}
          >
            {t(`${typeGraphT}.type.options.grouped`)}
          </CheckableTag>
          <CheckableTag
            checked={typeGraphActive === 'stack'}
            onChange={() => setTypeGraphActive('stack')}
          >
            {t(`${typeGraphT}.type.options.stacking`)}
          </CheckableTag>
        </TypeGraph>
      )}
    </FiltersContainer>
  )
}

export default Filters
