import { Card as AntCard } from 'antd'
import styled from 'styled-components'

interface CardProps {
  shadow: number
}

export const Card = styled(AntCard)<CardProps>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: ${({ shadow, theme }) =>
    shadow ? `0 0 10px 5px ${theme.colors.primary[5]}` : ''};
  transition: 0.5s;
`
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  margin: 0 10px 0 0;
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
`

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  :nth-child(n + 2) {
    border-left: 1px solid ${({ theme }) => theme.borderColor};
  }
`

export const Text = styled.p`
  text-align: center;
  margin: 0;
`

export const Icon = styled.i`
  color: #fff;
  font-size: 12px;
`

export const ContentActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FiltersContainer = styled.div`
  margin-bottom: 1rem;
`
