import { useState, useEffect } from 'react'
import { Button } from 'antd'
import { Layouts } from 'react-grid-layout'
import { Widget } from '../../Types'
import { Provider } from './context'
import Filters from './Filters'
import DrawerFilter from './Filters/Drawer'
import Layout from './Layout'
import ModalWidget from './ModalWidget'
import NewWidget from './NewWidget'
import { MainSpace, AffixElement } from './styles'
import WidgetInfo from './WidgetInfo'
const newData: Layouts = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: [],
}

const Main: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [drawerfilter, setDrawerfilter] = useState<boolean>(false)

  const [layouts, setLayouts] = useState<Layouts>(newData)
  const [widgets, setWidgets] = useState<Widget[]>([])

  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 217) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    })
  })

  return (
    <>
      <Filters />
      <MainSpace>
        <Layout
          widgets={widgets}
          setWidgets={setWidgets}
          layouts={layouts}
          setLayouts={setLayouts}
        />

        <NewWidget
          visible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          setDashboardWidgets={setWidgets}
          layouts={layouts}
          setLayouts={setLayouts}
          listWidgets={widgets}
        />
        <DrawerFilter
          visible={drawerfilter}
          setDrawerVisible={setDrawerfilter}
        />
        <AffixElement>
          <Button
            type="primary"
            icon={<i className="fa-solid fa-filter" />}
            onClick={() => {
              if (visible) setDrawerfilter(true)
            }}
            size="large"
            style={{
              marginBottom: '10px',
              opacity: `${visible ? 1 : 0}`,
              cursor: `${visible ? 'pointer' : 'initial'}`,
            }}
          />
          <Button
            data-tut="reactour__create_widget"
            type="primary"
            icon={<i className="fa-solid fa-plus" />}
            onClick={() => setDrawerVisible(true)}
            size="large"
          />
        </AffixElement>
      </MainSpace>
      <ModalWidget />
      <WidgetInfo />
    </>
  )
}

const Index = () => {
  return (
    <Provider>
      <Main />
    </Provider>
  )
}

export default Index
