/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Typography, Table } from 'antd'
import { Excel } from 'antd-table-saveas-excel'
import { useTranslation } from 'react-i18next'
import { KeyValue } from '../../../../Types'
import { formatDecimal } from '../../../../Utilities/formatDecimal'

const { Text } = Typography

interface Props {
  name: string
  varName: string
  data: { x: string; y: number; series?: string; time?: string }[]
  seriesWidget?: boolean
  loader: boolean
  params: KeyValue
  total: number
  onlyMoneyGroup: boolean
  totalColumns: { month: string; total: number }[]
  typeGraph: string
  dataChart: any
  downloadTable: boolean
  setDownloadTable: Dispatch<SetStateAction<boolean>>
  openTable: boolean
}

const MXN = Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
})

const TableUI: React.FC<Props> = ({
  varName,
  data,
  seriesWidget,
  loader,
  params,
  // total,
  totalColumns,
  onlyMoneyGroup,
  typeGraph,
  dataChart,
  downloadTable,
  setDownloadTable,
  openTable,
}) => {
  const { t } = useTranslation('translate')
  const [dataSource, setDataSource] = useState<any>([])
  const [totalDualAxis, setTotalDualAxis] = useState<any>([])

  const [columnsSource, setColumnsSource] = useState([])
  const SeriesT = `widgets.${varName}.catalogues.${params.group_by}.options`

  const changeStructureTable = useCallback(() => {
    if (data.length > 0) {
      if (seriesWidget) {
        const newStructure: any = {}
        data.forEach((el: any) => {
          if (el.series) {
            const x = el?.x.split(' ')
            const value = t(`components.common.datesTable.${x[0]}`, x[0]) // TODO: Refactor this
            const xAxis = `${value} ${x[1] || ''}`.trim() // TODO: Refactor
            newStructure[el.series] = {
              ...newStructure[el.series],
              [varName]: t(`${SeriesT}.${el.series}`, el.series),
              [xAxis]: el.y || 0,
            }
          }
        })
        let propertyValues: any = Object.values(newStructure)
        setDataSource([...propertyValues])
      } else {
        let dataInfo: any = []
        data.forEach((el) => {
          dataInfo.push({
            value: el.y || 0,
            [varName]: t([`${SeriesT}.${el.x}`, el.x]),
          })
        })
        setDataSource(dataInfo)
      }
    } else {
      setDataSource([])
    }
  }, [SeriesT, data, seriesWidget, t, varName])

  const changeStructureTableDoubleAxis = useCallback(() => {
    let newStructure = [...data]
    const items: any = dataChart.items || []
    if (items.length > 0) {
      const totalColumDualAxis = [
        {
          time: items[0],
          total: 0,
        },
        {
          time: items[1],
          total: 0,
        },
      ]
      newStructure = newStructure.map((el: any) => {
        totalColumDualAxis[0] = {
          time: items[0],
          total: totalColumDualAxis[0].total + el[items[0]],
        }
        totalColumDualAxis[1] = {
          time: items[1],
          total: totalColumDualAxis[1].total + el[items[1]],
        }
        return {
          ...el,
          [varName]: el.time,
        }
      })
      setTotalDualAxis(totalColumDualAxis)
      setDataSource(newStructure)
    }
  }, [data, varName])
  useEffect(() => {
    if (typeGraph === 'double_axis') {
      changeStructureTableDoubleAxis()
    } else changeStructureTable()
  }, [changeStructureTable, changeStructureTableDoubleAxis, typeGraph])

  const structureColumns = () => {
    let columns: any = [
      {
        title: t(`widgets.${varName}.name`),
        dataIndex: varName,
        fixed: true,
        // fixed: 'right',
      },
    ]

    if (seriesWidget) {
      totalColumns.forEach((el) => {
        columns.push({
          title: el.month,
          dataIndex: el.month,
          render: (val: number) =>
            onlyMoneyGroup
              ? MXN.format(val || 0)
              : formatDecimal.format(val || 0),
        })
      })
    } else {
      columns = [
        ...columns,
        {
          title: t('components.common.value'),
          dataIndex: 'value',
          render: (val: number) => formatDecimal.format(val || 0),
        },
      ]
    }

    setColumnsSource(columns)
  }

  const changeStructureColumnsDoubleAxis = () => {
    let columns: any = [
      {
        title: t(`widgets.${varName}.name`),
        dataIndex: varName,
        fixed: true,
      },
    ]
    const items = dataChart.items || []

    items.forEach((el: any) => {
      columns.push({
        title: t([`${SeriesT}.${el}`, el]),
        dataIndex: el,
        render: (val: number) =>
          onlyMoneyGroup
            ? MXN.format(val || 0)
            : formatDecimal.format(val || 0),
      })
    })
    setColumnsSource(columns)
  }
  useEffect(() => {
    if (typeGraph === 'double_axis') {
      changeStructureColumnsDoubleAxis()
    } else structureColumns()
  }, [totalColumns, onlyMoneyGroup, seriesWidget, varName, t, typeGraph])

  useEffect(() => {
    if (downloadTable) {
      let TotalC: any = {
        [varName]: 'Total',
      }
      if (seriesWidget) {
        if (typeGraph !== 'double_axis') {
          totalColumns.forEach((el) => {
            TotalC = { ...TotalC, [el.month]: el.total }
          })
        } else if (typeGraph === 'double_axis') {
          totalDualAxis.forEach((el: any) => {
            TotalC = { ...TotalC, [el.month]: el.total }
          })
        }
      } else {
        let totalSum = 0
        dataSource.forEach((el: any) => {
          totalSum += el.value || 0
        })
        TotalC = {
          ...TotalC,
          value: totalSum,
        }
      }
      const excel = new Excel()
      excel.defaultTheadCellStyle.background = 'FF154c7a'
      excel.defaultTheadCellStyle.color = 'FFFFFF'

      excel
        .addSheet('test')
        .addColumns(columnsSource)
        .addDataSource([...dataSource, TotalC], {
          str2Percent: true,
        })
        .saveAs(`${t(`widgets.${varName}.name`)}.xlsx`)
      setDownloadTable(false)
    }
  }, [downloadTable])

  return (
    <div>
      {openTable && (
        <Table
          dataSource={dataSource}
          columns={columnsSource}
          rowKey={varName}
          style={{ marginTop: '1rem' }}
          scroll={{ x: columnsSource.length * 250, y: 300 }}
          loading={loader}
          summary={(pageData) => {
            let totalSum = 0
            if (!seriesWidget) {
              pageData.forEach((el: any) => {
                totalSum += el.value || 0
              })
            }

            return (
              <Table.Summary fixed={'bottom'}>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  {seriesWidget && (
                    <>
                      {typeGraph === 'double_axis' && (
                        <>
                          {totalDualAxis.map((el: any, index: number) => (
                            <Table.Summary.Cell index={index + 1} key={index}>
                              <Text>
                                {onlyMoneyGroup
                                  ? MXN.format(el.total)
                                  : formatDecimal.format(el.total)}
                              </Text>
                            </Table.Summary.Cell>
                          ))}
                        </>
                      )}
                      {typeGraph !== 'double_axis' && (
                        <>
                          {totalColumns.map((el, index) => (
                            <Table.Summary.Cell index={index + 1} key={index}>
                              <Text>
                                {onlyMoneyGroup
                                  ? MXN.format(el.total)
                                  : formatDecimal.format(el.total)}
                              </Text>
                            </Table.Summary.Cell>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {!seriesWidget && (
                    <Table.Summary.Cell index={1}>
                      <Text>{formatDecimal.format(totalSum)}</Text>
                    </Table.Summary.Cell>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      )}
    </div>
  )
}

export default TableUI
