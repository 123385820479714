/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useContext } from 'react'
import { Button, message, Spin } from 'antd'
import axios from 'axios'
import { Chart as ChartGoogle } from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { Widget } from '../../../../Types'
import Context from '../../context'
import { Card, BodyWrapper } from './styles'
interface Props {
  config: Widget
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

type Data = (string | number)[][]

const Map: React.FC<Props> = ({ config, handleDeleteWidget }) => {
  const { t } = useTranslation('translate')
  const theme = useContext(ThemeContext)
  const { paramsGeneral, setInfoWidget, newWidgetCreate, setNewWidgetCreate } =
    useContext(Context)

  const { widget, id } = config
  const { name, end_point, var_name } = widget
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Data>([
    ['Estado', 'Pagos de multas'],
    ['Aguascalientes', 0],
  ])

  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(end_point, {
        params: {
          ...paramsGeneral,
        },
      })
      setData(res.data)
    } catch (e) {
      setData([])
      message.error(
        `${t('components.common.request_error_graph')} ${t(
          `widgets.${var_name}.name`
        )}`
      )
      // if (axios.isAxiosError(e)) {
      //   const message = e.response?.data.message;
      //   console.log(Object.values(message));
      // }
      // TODO: setup sentry
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_point, var_name, paramsGeneral])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const openInfo = () => {
    setInfoWidget({ active: true, varName: var_name })
  }

  useEffect(() => {
    if (newWidgetCreate !== 0) {
      setTimeout(() => {
        setNewWidgetCreate(0)
      }, 4000)
    }
  }, [newWidgetCreate])

  return (
    <Card
      // title={name}
      title={t(`widgets.${var_name}.name`)}
      extra={
        <>
          <Button
            type="primary"
            shape="circle"
            icon={<i className="fa-solid fa-info" />}
            onClick={() => openInfo()}
          />
          <Button
            type="primary"
            shape="circle"
            danger
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      id={`widget_id_${var_name}_card`}
      shadow={+(newWidgetCreate === id)}
    >
      {loading ? (
        <BodyWrapper
          style={{
            paddingBottom: '4.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin spinning tip={t('components.common.loading-chart')} />
        </BodyWrapper>
      ) : (
        <BodyWrapper>
          <ChartGoogle
            chartType="GeoChart"
            height="100%"
            data={data}
            mapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
            options={{
              region: 'MX',
              resolution: 'provinces',
              backgroundColor: 'transparent',
              datalessRegionColor: 'transparent',
              colorAxis: {
                // values: [100, 200, 300, 400],
                colors: [
                  theme.colors.blue[1],
                  theme.colors.blue[3],
                  theme.colors.blue[5],
                  theme.colors.blue[7],
                ],
              },
            }}
          />
        </BodyWrapper>
      )}
    </Card>
  )
}

export default Map
