import React, { Dispatch, SetStateAction } from 'react'
import { Drawer } from 'antd'
import { useMediaQuery } from 'beautiful-react-hooks'
import Form from './Form'
interface Props {
  visible: boolean
  setDrawerVisible: Dispatch<SetStateAction<boolean>>
}

const DrawerFilter: React.FC<Props> = ({ visible, setDrawerVisible }) => {
  const mobile = useMediaQuery('(max-width: 640px)')

  const handleCloseDrawer = () => {
    setDrawerVisible(false)
  }
  return (
    <Drawer
      placement="right"
      open={visible}
      onClose={handleCloseDrawer}
      width={mobile ? '80%' : '400px'}
      title="Filtros"
      footer={false}
      destroyOnClose
    >
      <Form drawer={true} />
    </Drawer>
  )
}

export default DrawerFilter
