/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import {
  Select,
  DatePicker,
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Space,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getStorage } from '../../../Utilities/Storage'
import Context from '../context'
import { debounce } from './debounce'
import { Actions, MoreFilter, IconMoreFilter, DividerContent } from './styles'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

interface Props {
  drawer: boolean
}
interface DataInput {
  id?: number
  name?: string
  value?: string
}

const typeGraphT = 'components.common.filters'
const Form: React.FC<Props> = ({ drawer }) => {
  const userType = getStorage('currentUser').user_type
  const {
    paramsGeneral,
    setParamsGeneral,
    carBrands,
    setCarBrands,
    carModels,
    setCarModels,
  } = useContext(Context)
  const [clients, setClients] = useState<DataInput[]>([])
  const [userCompanies, setUserCompanies] = useState<DataInput[]>([])
  const [fleetTypes, setFleetTypes] = useState<DataInput[]>([])
  const [divisions, setDivisions] = useState<DataInput[]>([])
  const [locations, setLocations] = useState<DataInput[]>([])

  const [tags, setTags] = useState<DataInput[]>([])
  const [typeTags, setTypeTags] = useState<DataInput[]>([])

  const { id: routeID } = useParams()

  const [openFilter, setOpenFilter] = useState(false)
  const { t } = useTranslation('translate')
  const XS = drawer ? 24 : 24
  const SM = drawer ? 24 : 12
  const MD = drawer ? 24 : 8
  const LG = drawer ? 24 : 6
  const XL = drawer ? 24 : 6

  const saveFilterDashboard = async (name: string, value: string) => {
    try {
      await axios.patch(`/ala/user_dashboards/${routeID}`, {
        filter_list: {
          ...paramsGeneral,
          [name]: value,
        },
      })
    } catch (e) {
      //  setLayouts(oldLayouts)
    }
  }

  const saveFilterDate = async (from: string) => {
    try {
      await axios.patch(`/ala/user_dashboards/${routeID}`, {
        filter_list: {
          ...paramsGeneral,
          from: from || paramsGeneral.from,
          // to: to || paramsGeneral.to,
        },
      })
    } catch (e) {
      //  setLayouts(oldLayouts)
    }
  }
  const getClient = async () => {
    try {
      const res = await axios.get('/v1/clients?active=true')
      if (userType === 'sfleet_user') {
        setClients(res.data)
      } else {
        setParamsGeneral({ ...paramsGeneral, client_id: res.data[0].id })
        saveFilterDashboard('client_id', res.data[0].id)
      }
    } catch {}
  }
  const getUserCompanies = async () => {
    try {
      const res = await axios.get(
        `/v1/user_companies?client_id=${paramsGeneral?.client_id}`
      )
      setUserCompanies(res.data)
    } catch (error) {
      setUserCompanies([])
    }
  }
  const getTypeFleets = async () => {
    try {
      const res = await axios.get(
        `/v1/find_type_fleets?client_id=${paramsGeneral?.client_id}`
      )
      setFleetTypes(res.data)
    } catch (error) {
      setFleetTypes([])
    }
  }
  const getClientCarDivisions = async () => {
    try {
      const res = await axios.get(
        `/v1/client_divisions?client_id=${paramsGeneral?.client_id}`
      )
      setDivisions(res.data)
    } catch (error) {
      setDivisions([])
    }
  }
  const getLocationClientCars = async () => {
    try {
      const res = await axios.get(
        `/v1/find_car_locations/?client_id=${paramsGeneral?.client_id}`
      )
      setLocations(res.data)
    } catch (error) {
      setLocations([])
    }
  }
  const getClientCarStatuses = async () => {
    try {
      const res = await axios.get('/v1/client_car_statuses', {
        params: {
          client_id: paramsGeneral?.client_id,
          client_asset_type_tag_ids: paramsGeneral?.client_asset_type_tag_id,
        },
      })
      setTags(res.data)
    } catch (error) {
      setTags([])
    }
  }

  const getClientAssetTypeTags = async () => {
    try {
      const res = await axios.get(
        `/v1/client_asset_type_tags/?client_id=${paramsGeneral?.client_id}`
      )
      setTypeTags(res.data)
    } catch (error) {
      setTags([])
    }
  }
  const getClientCarBrands = async () => {
    try {
      const res = await axios.get('/ala/car_brands', {
        params: {
          client_id: paramsGeneral?.client_id,
          user_company_id: paramsGeneral?.user_company_id,
        },
      })

      setCarBrands(res.data)
    } catch (error) {
      setCarBrands([])
    }
  }

  const getClientCarModels = async () => {
    try {
      const res = await axios.get('/ala/asset_models', {
        params: {
          car_brand_id: paramsGeneral?.car_brand_id,
          client_id: paramsGeneral?.client_id,
          user_company_id: paramsGeneral?.user_company_id,
        },
      })

      setCarModels(res.data)
    } catch (error) {
      setCarBrands([])
    }
  }

  useEffect(() => {
    getClientCarStatuses()
  }, [paramsGeneral?.client_asset_type_tag_id])

  useEffect(() => {
    if (paramsGeneral?.car_brand_id) {
      getClientCarModels()
    }
  }, [paramsGeneral?.car_brand_id])

  useEffect(() => {
    if (paramsGeneral?.car_brand_id) {
      getClientCarModels()
    }
    getClientCarBrands()
  }, [paramsGeneral?.user_company_id, paramsGeneral?.client_id])

  useEffect(() => {
    if (paramsGeneral?.client_id) {
      getUserCompanies()
      getTypeFleets()
      getClientCarDivisions()
      getLocationClientCars()
      getClientCarStatuses()
    }
    getClientAssetTypeTags()
    getClientCarBrands()
  }, [paramsGeneral?.client_id])

  useEffect(() => {
    getClient()
  }, [userType])

  const handleCalendarChange = (dateStrings: [string, string]) => {
    setParamsGeneral({
      ...paramsGeneral,
      from: dateStrings[0],
      to: dateStrings[1],
    })
    saveFilterDate(dateStrings[0])
  }

  const handleCleanFilters = () => {
    setParamsGeneral({
      client_id:
        userType === 'sfleet_user' ? undefined : paramsGeneral?.client_id,
      user_company_id: undefined,
      fleet_type_id: undefined,
      client_car_division_id: undefined,
      client_car_location_id: undefined,
      client_car_status_id: undefined,
      from: moment().startOf('year').format('DD/MM/YYYY'),
      to: moment().format('DD/MM/YYYY'),
    })
  }

  const handleFilterClient = (value: []) => {
    setParamsGeneral({
      ...paramsGeneral,
      client_id: value.join(),
      user_company_id: undefined,
      fleet_type_id: undefined,
      client_car_division_id: undefined,
      client_car_location_id: undefined,
      client_car_status_id: undefined,
    })

    saveFilterDashboard('client_id', value.join())
  }

  const handleRefreshStatistics = () => {
    setParamsGeneral({ ...paramsGeneral })
  }

  const handleMoreFilters = () => {
    setOpenFilter(!openFilter)
  }

  const handleFilterChangeMultiple = debounce((name: string, value = []) => {
    if (name === 'client_asset_type_tag_id') {
      setParamsGeneral({
        ...paramsGeneral,
        [name]: value.join(),
        client_car_status_id: undefined,
      })
      saveFilterDashboard('client_car_status_id', '')
    } else {
      setParamsGeneral({
        ...paramsGeneral,
        [name]: value.join(),
      })
    }
    saveFilterDashboard(name, value.join())
  }, 1000)

  const addItem = () => {
    setParamsGeneral({
      ...paramsGeneral,
      client_id: clients.map((el) => el.id).join(),
      user_company_id: undefined,
      fleet_type_id: undefined,
      client_car_division_id: undefined,
      client_car_location_id: undefined,
      client_car_status_id: undefined,
    })
  }

  return (
    <Row gutter={[16, 10]}>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.range-picker.label`)}</Text>
        <RangePicker
          style={{ width: '100%', marginTop: '5px' }}
          format="DD/MM/YYYY"
          defaultValue={[
            moment(paramsGeneral.from, 'DD/MM/YYYY'),
            moment(paramsGeneral.to, 'DD/MM/YYYY'),
          ]}
          onChange={(v, date) => handleCalendarChange(date)}
          allowClear={false}
          disabledDate={(currentDate) => currentDate.isAfter(moment().format())}
        />
      </Col>
      {userType === 'sfleet_user' && (
        <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
          <Text>{t(`${typeGraphT}.client.label`)}</Text>
          <Select
            mode="multiple"
            style={{ width: '100%', marginTop: '5px' }}
            value={
              (paramsGeneral?.client_id
                ? paramsGeneral?.client_id?.split(',').map(Number)
                : undefined) as []
            }
            maxTagCount={1}
            onChange={handleFilterClient}
            placeholder={t(`${typeGraphT}.select_all`)}
            allowClear
            showSearch
            filterOption={(input: string, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space align="center" style={{ padding: '0 8px 4px' }}>
                  <Typography.Link
                    onClick={addItem}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Seleccionar todos
                  </Typography.Link>
                </Space>
              </>
            )}
          >
            {clients.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.user_company.label`)}</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.user_company_id
              ? paramsGeneral?.user_company_id.split(',').map(Number)
              : undefined) as []
          }
          disabled={!paramsGeneral?.client_id}
          onChange={(value) =>
            handleFilterChangeMultiple('user_company_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {userCompanies.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.fleet_type.label`)}</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.fleet_type_id
              ? paramsGeneral?.fleet_type_id.split(',').map(Number)
              : undefined) as []
          }
          disabled={!paramsGeneral?.client_id}
          onChange={(value) =>
            handleFilterChangeMultiple('fleet_type_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {fleetTypes.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.value}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.division.label`)}</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.client_car_division_id
              ? paramsGeneral?.client_car_division_id.split(',').map(Number)
              : undefined) as []
          }
          disabled={!paramsGeneral?.client_id}
          onChange={(value) =>
            handleFilterChangeMultiple('client_car_division_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {divisions.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.location.label`)}</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.client_car_location_id
              ? paramsGeneral?.client_car_location_id.split(',').map(Number)
              : undefined) as []
          }
          disabled={!paramsGeneral?.client_id}
          onChange={(value) =>
            handleFilterChangeMultiple('client_car_location_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {locations.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>Tipo de etiqueta</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.client_asset_type_tag_id
              ? paramsGeneral?.client_asset_type_tag_id.split(',').map(Number)
              : undefined) as []
          }
          onChange={(value) =>
            handleFilterChangeMultiple('client_asset_type_tag_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {typeTags.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Text>{t(`${typeGraphT}.car_tag.label`)}</Text>
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '5px' }}
          value={
            (paramsGeneral?.client_car_status_id
              ? paramsGeneral?.client_car_status_id.split(',').map(Number)
              : undefined) as []
          }
          onChange={(value) =>
            handleFilterChangeMultiple('client_car_status_id', value)
          }
          placeholder={t(`${typeGraphT}.select_all`)}
          allowClear
          showSearch
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {tags.map((el: any) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
        <Actions>
          <Button
            shape="circle"
            type="primary"
            icon={<i className="fa-solid fa-rotate" />}
            size="large"
            style={{ margin: '0 5px' }}
            onClick={handleRefreshStatistics}
          />
          <Button
            shape="circle"
            type="primary"
            danger
            icon={<i className="fa-regular fa-trash-alt" />}
            size="large"
            style={{ margin: '0 5px' }}
            onClick={handleCleanFilters}
          />
          {/* <Button
            shape='circle'
            type='primary'
            icon={<i className='fas fa-filter' />}
            size='large'
            style={{ margin: '0 5px' }}
          /> */}
        </Actions>
      </Col>
      <DividerContent onClick={handleMoreFilters}>
        <Divider type="horizontal" plain style={{ marginBottom: '0px' }}>
          Mas Filtros
          <IconMoreFilter
            rotate={+openFilter}
            className="fa-solid fa-angle-down"
            onClick={() => handleMoreFilters()}
          />
        </Divider>
      </DividerContent>
      <MoreFilter open={openFilter}>
        <Row align="middle" gutter={[16, 10]}>
          <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
            <Text>Marca</Text>
            <Select
              mode="multiple"
              showSearch
              value={
                (paramsGeneral?.car_brand_id
                  ? paramsGeneral?.car_brand_id.split(',').map(Number)
                  : undefined) as []
              }
              style={{ width: '100%' }}
              onChange={(value) =>
                handleFilterChangeMultiple('car_brand_id', value)
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
              filterOption={(input: string, option: any) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {carBrands.map((el: any) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
            <Text>Modelo</Text>
            <Select
              mode="multiple"
              showSearch
              value={
                (paramsGeneral?.car_model_id
                  ? paramsGeneral?.car_model_id.split(',').map(Number)
                  : undefined) as []
              }
              style={{ width: '100%' }}
              onChange={(value) =>
                handleFilterChangeMultiple('car_model_id', value)
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
              disabled={!paramsGeneral?.car_brand_id}
              filterOption={(input: string, option: any) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {carModels.map((el: any) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Col>
          {/* <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
            <Text>Año del Vehículo</Text>
            <Select
            // showSearch
            // value={params.year_catalogue || ''}
            // disabled={!params.client}
            // style={{ width: '100%' }}
            // onChange={(value) => handleFilterChange('year_catalogue', value)}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            >
              <Option value="">Todas</Option>
            </Select>
          </Col>

          <Col xs={XS} sm={SM} md={MD} lg={LG} xl={XL}>
            <Text>Tipo de Vehículo</Text>
            <Select
            // showSearch
            // value={params.type_vehicle || ''}
            // disabled={!params.client}
            // style={{ width: '100%' }}
            // onChange={(value) => handleFilterChange('type_vehicle', value)}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            >
              <Option value="">Todas</Option>
            </Select>
          </Col> */}
        </Row>
      </MoreFilter>
    </Row>
  )
}

export default Form
