import { generate } from '@ant-design/colors'
import { DefaultTheme } from 'styled-components'

const red = generate('#c40847')
const green = generate('#149b7f')
const gold = generate('#d5a423')

// TODO: Refactor to use common styles in both themes
const dark: DefaultTheme = {
  isDark: true,
  transitions: {
    durations: {
      short: 0.15,
      normal: 0.25,
    },
  },
  borderRadius: {
    normal: '0.5rem',
  },
  colors: {
    primary: generate('#33a1af'),
    red,
    volcano: generate('#d84a1b'),
    orange: generate('#d58423'),
    gold,
    yellow: generate('#dab705'),
    lime: generate('#8bbb11'),
    green,
    cyan: generate('#3ca7d3'),
    blue: generate('#0768ba'),
    geekblue: generate('#274cc7'),
    purple: generate('#713BC9'),
    magenta: generate('#9e1383'),
    pink: generate('#9e1383'),
    success: green[5],
    warning: gold[5],
    error: red[5],
    menuBackground: '#092134',
    componentBackground: '#092134',
    componentBorder: '#154c7a',
    bodyBackground: '#061623',
    menuBorderColor: '#154c7a',
    textColor: 'rgba(255, 255, 255, 0.85)',
  },
  borderColor: '#154c7a',
}

export default Object.freeze(dark)
