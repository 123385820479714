import styled from 'styled-components'

export const MainSpace = styled.div`
  width: 100%;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (min-width: 0) {
    > * {
      flex: 0 1 100%;
    }

    > :not(:first-child) {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 50rem) {
    > * {
      flex: 0 1 calc(50% - 0.5rem);
    }

    > :nth-child(2) {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 90rem) {
    > * {
      flex: 0 1 calc(25% - 1rem);
    }

    > :nth-last-child(-n + 2) {
      margin-top: 0;
    }
  }
`

export const CardTitle = styled.div`
  padding: 1rem;

  > :first-child {
    color: ${(props) => props?.color || ''};
  }

  div {
    color: #fff;
    opacity: 0.6;
  }
`

export const CardBody = styled.div`
  padding: 0rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .summary {
    p,
    h4 {
      color: ${(props) => props?.color || ''};
      margin: 0;
    }

    p {
      opacity: 0.85;
    }
  }
`

export const PercentageTrend = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 0.25rem;
  align-items: center;

  .fas {
    color: white;
  }
`

export const AffixElement = styled.div`
  position: fixed;
  z-index: 99;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  height: 25vh;
  justify-content: center;
  align-items: center;
`
