import React, { useState, useEffect, useContext } from 'react'
import { Switch, Button, Drawer, Typography, Tooltip } from 'antd'
import { useMediaQuery } from 'beautiful-react-hooks'
// import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import LogoAnalytics from '../../../Assets/Images/analytics.png'
// import { Language } from '../../../Types'
// import { useLanguageCTX } from '../../../Utilities/LanguageCTX'
import ContextGlobal from '../../../context'
import { getStorage } from '../../../Utilities/Storage'
import { useThemeCTX } from '../../../Utilities/ThemeCTX'
import ALAMenu from '../Menu'
import { Logo, ToolbarSpace, LeftSide, RightSide, LogoWrapper } from './styles'
// import ToolbarMenu from './ToolbarMenu'

const { Text } = Typography
const AppLogo = () => {
  return <Logo src={LogoAnalytics} alt="" />
}

const Toolbar = () => {
  const { baseUrl } = useContext(ContextGlobal)

  const [visible, setVisible] = useState(false)
  // const [openKeys, setOpenKeys] = useState<string[]>([''])
  const mobile = useMediaQuery('(max-width: 55em)')
  const { toggleTheme, isDarkTheme } = useThemeCTX()

  const { isDark, colors } = useTheme()
  // const { t, i18n } = useTranslation()
  // const { changeLng, language } = useLanguageCTX()

  useEffect(() => {
    if (!mobile) {
      setVisible(false)
    }
  }, [mobile])

  // const handleMenu = (key: string) => {
  //   switch (key) {
  //     case Language['es-MX']:
  //       changeLng(key)
  //       break
  //     case Language['en-US']:
  //       changeLng(key)
  //       break
  //     default:
  //       break
  //   }
  // }
  // const onOpenChange = (keys: Key[]) => {
  //   setOpenKeys(keys as string[])
  // }

  // const onDrawerClose = () => {
  //   setOpenKeys([])
  //   setVisible(false)
  // }
  const getTypeUser = () => {
    const user = getStorage('currentUser').user_type
    if (user === 'sfleet_user') {
      return '| Usuario operaciones'
    } else if (user === 'client_user') {
      return 'Conductor'
    } else if (user === 'client_admin') {
      return '| Administrador'
    } else if (user === 'provider') {
      return '| Proveedor'
    } else if (user === 'provider_group') {
      return '| Franquicia'
    } else if (user === 'management_agency') {
      return '| Gestor'
    } else if (user === 'client_module') {
      return '| Usuario modular'
    } else {
      return ''
    }
  }

  return (
    <ToolbarSpace>
      {/* {showSearch && (
        <Search
          ref={searchRef}
          className='mobile-searchbar'
          placeholder={t('toolbar.searchbar')}
          onBlur={() => setShowSearch(false)}
          onKeyDown={({ key }) => key === 'Escape' && setShowSearch(false)}
          enterButton
        />
      )} */}
      <>
        <LeftSide>
          {mobile ? (
            <>
              <Button
                type="link"
                shape="circle"
                icon={<i className="fas-solid fa-bars" />}
                size="large"
                onClick={() => setVisible(true)}
              />
              <Drawer
                visible={visible}
                placement="left"
                style={{ height: '100%' }}
                bodyStyle={{
                  padding: 0,
                  background: isDark ? colors.menuBackground : undefined,
                }}
                closable={false}
                onClose={() => {
                  setVisible(false)
                }}
              >
                <LogoWrapper>
                  <AppLogo />
                </LogoWrapper>

                <ALAMenu />
              </Drawer>
            </>
          ) : (
            <>
              <AppLogo />
            </>
          )}
        </LeftSide>
        <RightSide>
          {!mobile && (
            <Text style={{ color: 'rgba(255 255 255 / 85%)' }}>
              {getStorage('currentUser').full_name || ''} {getTypeUser()}
            </Text>
          )}
          <Switch
            checked={isDarkTheme}
            onChange={toggleTheme}
            checkedChildren={<i className="fa-solid fa-moon" />}
            unCheckedChildren={<i className="fa-solid fa-sun" />}
          />
          <Tooltip title="Salir">
            <Button
              shape="circle"
              type="primary"
              icon={<i className="fa-solid fa-power-off" />}
              href={`${baseUrl}/users/sign_out`}
            />
          </Tooltip>
          {/* <Menu
            onClick={(info) => handleMenu(info.key)}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
            // mode='inline'
            selectedKeys={['']}
            className='drawer-menu'
            theme={isDarkMode ? 'dark' : 'light'}
            mode='horizontal'
          >
            <Menu.SubMenu
              key='language'
              icon={<i className='fas fa-language' />}
              title={language}
            >
              <Menu.Item key='es-MX' disabled={i18n.language === 'es-MX'}>
                Español
              </Menu.Item>
              <Menu.Item key='en-US' disabled={i18n.language === 'en-US'}>
                English
              </Menu.Item>
            </Menu.SubMenu> */}
          {/* {showThemeSwitch && (
            <Menu.Item icon={<i className='fas fa-eye' />}>
              <ThemeSwitchSpace>
                {t('toolbar.menu.theme')}
                <Switch
                  checked={isDarkMode}
                  onChange={toggleTheme}
                  checkedChildren={<i className='fas fa-moon' />}
                  unCheckedChildren={<i className='fas fa-sun' />}
                />
              </ThemeSwitchSpace>
            </Menu.Item>
          )} */}
          {/* </Menu> */}
          {/* <ToolbarMenu /> */}
        </RightSide>
      </>
    </ToolbarSpace>
  )
}

export default Toolbar
