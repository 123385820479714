import React, {
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Modal, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { Layouts, WidthProvider, Responsive } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Widget as IWidget } from '../../../Types'
import Context from '../context'
import { SpinnerWrapper } from '../styles'
import Widget from '../Widget'
import { ResizeHandle, WidgetWrapper } from './styles'
import 'react-grid-layout/css/styles.css'
import WidgetInfo from '../WidgetInfo'
const { confirm } = Modal

const ResponsiveReactGridLayout = WidthProvider(Responsive)

interface LayoutProps {
  widgets: IWidget[]
  setWidgets: Dispatch<SetStateAction<IWidget[]>>
  layouts: Layouts
  setLayouts: Dispatch<SetStateAction<Layouts>>
}

const Layout = ({ widgets, setWidgets, layouts, setLayouts }: LayoutProps) => {
  const { paramsGeneral, setParamsGeneral } = useContext(Context)
  const { id: routeID } = useParams()
  const [loading, setLoading] = useState(false)

  const LayoutT = 'views.main.layout'
  const deleteWidgetT = 'components.common.delete_widget'

  const { t } = useTranslation('translate')

  const updateLayouts = async (newLayouts: Layouts) => {
    const oldLayouts = { ...layouts }
    setLayouts(newLayouts)
    try {
      await axios.patch(`/ala/user_dashboards/${routeID}`, {
        structure: newLayouts,
      })
    } catch (e) {
      setLayouts(oldLayouts)
    }
  }

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const {
        data: { data },
      } = await axios.get(`/ala/user_dashboards/${routeID}`)

      setWidgets(data.dashboard_widgets)
      if (data.structure) {
        setLayouts(data.structure)
      }
      if (data.filter_list) {
        setParamsGeneral({
          ...paramsGeneral,
          ...data.filter_list,
          client_id: `${data.filter_list.client_id}`,
          from:
            data.filter_list?.from ||
            moment().startOf('year').format('DD/MM/YYYY'),
          to: data.filter_list?.to || moment().format('DD/MM/YYYY'),
        })
      } else {
        setParamsGeneral({
          from: moment().startOf('year').format('DD/MM/YYYY'),
          to: moment().format('DD/MM/YYYY'),
        })
      }
    } catch (e) {
      return
    } finally {
      setLoading(false)
    }
  }, [routeID, setLayouts, setWidgets])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getDataLayout = (data: { h: number; i: string }[], varName: string) => {
    return data.filter((el) => el.i !== varName)
  }
  const deleteWidget = async (varName: string, name: string, id: number) => {
    try {
      await axios.delete(`/ala/dashboard_widgets/${id}`)
      const newWidgets = widgets.filter((el) => el.id !== id)
      setWidgets(newWidgets)
      let newLayouts = {}

      if (layouts.lg) {
        const lg = getDataLayout(layouts.lg, String(id))
        newLayouts = { ...newLayouts, lg }
      }
      if (layouts.md) {
        const md = getDataLayout(layouts.md, String(id))
        newLayouts = { ...newLayouts, md }
      }
      if (layouts.sm) {
        const sm = getDataLayout(layouts.sm, String(id))
        newLayouts = { ...newLayouts, sm }
      }
      if (layouts.xs) {
        const xs = getDataLayout(layouts.xs, String(id))
        newLayouts = { ...newLayouts, xs }
      }
      if (layouts.xxs) {
        const xxs = getDataLayout(layouts.xxs, String(id))
        newLayouts = { ...newLayouts, xxs }
      }

      await updateLayouts(newLayouts)

      message.success(
        `${t(`widgets.${varName}.name`)} ${t(`${deleteWidgetT}.success`)}`
      )
    } catch (error) {
      message.error(
        `${t(`${deleteWidgetT}.error`)} ${t(`widgets.${varName}.name`)}`
      )
    }
  }
  const handleDeleteWidget = (varName: string, name: string, id: number) => {
    confirm({
      title: `${t(`${deleteWidgetT}.title`)} ${t(`widgets.${varName}.name`)} ?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteWidget(varName, name, id)
      },
      onCancel() {
        return
      },
    })
  }

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spin spinning size="large" tip={t(`${LayoutT}.loading-widgets`)} />
      </SpinnerWrapper>
    )
  }

  return (
    <ResponsiveReactGridLayout
      className="layout"
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={60}
      layouts={layouts}
      // layouts={layoutExample}
      onLayoutChange={(_layout, newLayouts) => {
        updateLayouts(newLayouts)
      }}
      margin={[20, 20]}
      isResizable
      isBounded
      measureBeforeMount
      resizeHandle={<ResizeHandle className="react-resizable-handle" />}
    >
      {widgets.map((config: IWidget) => (
        <WidgetWrapper
          key={config.id}
          contrastResizeHandle={config.widget.widget_type === 'card'}
        >
          <Widget
            config={config}
            layouts={layouts}
            setLayouts={setLayouts}
            handleDeleteWidget={handleDeleteWidget}
          />
        </WidgetWrapper>
      ))}
    </ResponsiveReactGridLayout>
  )
}

export default Layout
