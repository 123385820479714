import { Area, AreaConfig } from '@ant-design/plots'
interface Data {
  x: string
  y: number
}
interface Props {
  data?: Data[]
}

const KPIChart: React.FC<Props> = ({ data }) => {
  const type = 'Area'

  const AreaCFG: AreaConfig = {
    data: data || [],
    xField: 'x',
    yField: 'y',
    yAxis: {
      label: null,
      line: null,
      grid: {
        line: {
          style: {
            stroke: 'transparent',
          },
        },
      },
    },
    xAxis: {
      label: null,
      line: null,
      range: [0, 1],
    },

    tooltip: false,
    color: '#fff',
    legend: false,
    smooth: true,
    autoFit: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  }

  switch (type) {
    case 'Area':
      return <Area {...(AreaCFG as AreaConfig)} />
    default:
      return null
  }
}

export default KPIChart
