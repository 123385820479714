import { Menu } from 'antd'
import styled from 'styled-components'

export const StyledItem = styled(Menu.Item)`
  .menu-item-content {
    transition: color ${({ theme }) => theme.transitions.durations.short}s;
  }

  &:hover {
    .menu-item-content {
      grid-template-columns: repeat(3, min-content);
      color: ${({ theme }) => theme.colors.primary[5]} !important;
    }
  }
`

export const MenuItemContent = styled.div`
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-rows: 1fr;
  user-select: none;

  @media screen and (min-width: 0) {
    width: 100% !important;
    grid-template-columns: min-content min-content 1fr !important;

    > :last-child {
      justify-self: end;
    }
  }

  @media screen and (min-width: 55em) {
    width: auto !important;
    grid-template-columns: repeat(2, min-content);
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 0) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: auto;
  }

  // Oddly specific min-width, but it fixes a UI-breaking bug.

  @media screen and (min-width: 55.05em) {
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }

  background: ${({ theme }) =>
    theme.isDark ? theme.colors.menuBackground : undefined};
`
