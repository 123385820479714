import { Form } from 'antd'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const IconsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
  gap: 0.5rem;
  font-size: 1.25rem;
`

interface IconBoxProps {
  selected?: boolean
}

export const IconBox = styled.div<IconBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.componentBorder};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  cursor: pointer;
  text-align: center;
  transition: transform ${({ theme }) => theme.transitions.durations.short}s;

  background: ${({ theme, selected }) => selected && theme.colors.primary[5]};
  color: ${({ selected }) => selected && 'white'};

  &:hover {
    background: ${({ theme }) => theme.colors.primary[5]};
  }

  &:active {
    transform: scale(0.9);
  }

  &:active,
  &:hover {
    color: white;
  }
`

export const EmptyItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-control-input {
    min-height: 0;
  }
`

export const ResultWrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
