/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, Dispatch, SetStateAction, useEffect } from 'react'
import { Table, Typography } from 'antd'
import { Excel } from 'antd-table-saveas-excel'
import { useTranslation } from 'react-i18next'
import { formatDecimal } from '../../../../Utilities/formatDecimal'
import Context from '../../context'
const { Text } = Typography

interface Props {
  varName: string
  data: { x: string; y: number; series?: string }[]
  loader: boolean
  downloadTable: boolean
  setDownloadTable: Dispatch<SetStateAction<boolean>>
  openTable: boolean
  downloadTableModal?: boolean
}

const TableUI: React.FC<Props> = ({
  varName,
  data,
  loader,
  downloadTable,
  setDownloadTable,
  openTable,
  downloadTableModal,
}) => {
  const { t } = useTranslation('translate')
  const { setModalGeneralVisible } = useContext(Context)

  const widgetCatalogueT = `widgets.${varName}.catalogues`

  const openModal = (endpoint: string, val: number) => {
    setModalGeneralVisible({
      active: true,
      endpoint: endpoint,
      varName: varName,
      totalItem: val,
      downloadTableModal: downloadTableModal,
    })
  }
  const columns = [
    {
      title: 'Valor',
      dataIndex: 'key',
      key: 'key',
      render: (key: string, record: any) => {
        return (
          <Text
            underline
            style={{ cursor: 'pointer' }}
            onClick={() => openModal(record.detail_endpoint, record.val)}
          >
            {t(`${widgetCatalogueT}.${key}`, key)}
          </Text>
        )
      },
    },
    {
      title: '#',
      dataIndex: 'num',
      render: (val: number) => formatDecimal.format(val),
    },
    {
      title: '%',
      dataIndex: 'percent',
      // render: (val: number) => `${Math.round(val)}%`,
    },
  ]
  useEffect(() => {
    if (downloadTable) {
      const excel = new Excel()
      excel.defaultTheadCellStyle.background = 'FF154c7a'
      excel.defaultTheadCellStyle.color = 'FFFFFF'

      excel
        .addSheet('test')
        .addColumns(columns)
        .addDataSource(data, {
          str2Percent: true,
        })
        .saveAs(`${t(`widgets.${varName}.name`)}.xlsx`)
      setDownloadTable(false)
    }
  }, [downloadTable])

  return (
    <>
      {openTable && (
        <Table
          dataSource={data}
          columns={columns}
          rowKey={'key'}
          style={{ marginTop: '1rem' }}
          scroll={{ x: 1000, y: 300 }}
          loading={loader}
        />
      )}
    </>
  )
}

export default TableUI
