import { registerTheme } from '@antv/g2'
import { useTheme } from 'styled-components'

const useChartTheme = () => {
  const theme = useTheme()
  const { colors, isDark } = theme
  const { primary } = colors

  registerTheme('ala-light', {
    background: 'rgba(255, 255, 255, 1)',
    defaultColor: primary[5],
    subColor: 'rgba(187, 144, 144, 0.05)',
    semanticRed: 'rgba(59, 186, 200, 1)',
    semanticGreen: 'rgba(6, 37, 46, 1)',
    padding: 'auto',
    fontFamily:
      '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
    columnWidthRatio: 0.5,
    maxColumnWidth: null,
    minColumnWidth: null,
    roseWidthRatio: 0.9999999,
    multiplePieWidthRatio: 0.7692307692307692,
    colors10: [
      'rgba(7, 118, 213, 1)',
      'rgba(22, 179, 143, 1)',
      'rgba(69, 192, 242, 1)',
      'rgba(255, 211, 0, 1)',
      '#7262fd',
      'rgba(140, 189, 35, 1)',
      'rgba(250, 189, 35, 1)',
      'rgba(250, 151, 35, 1)',
      'rgba(254, 86, 30, 1)',
      'rgba(229, 5, 77, 1)',
    ],
    colors20: [
      primary[5],
      '#CDDDFD',
      '#61DDAA',
      '#CDF3E4',
      '#65789B',
      '#CED4DE',
      '#F6BD16',
      '#FCEBB9',
      '#7262fd',
      '#D3CEFD',
      '#78D3F8',
      '#D3EEF9',
      '#9661BC',
      '#DECFEA',
      '#F6903D',
      '#FFE0C7',
      '#008685',
      '#BBDEDE',
      '#F08BB4',
      '#FFE0ED',
    ],
    sequenceColors: [
      '#e1ebff',
      '#c2d8ff',
      '#a0c5ff',
      '#8ab5fe',
      '#7aa7fc',
      '#6898fa',
      '#558af4',
      '#427de4',
      '#2b6fd5',
      '#0362c5',
    ],
    shapes: {
      point: [
        'hollow-circle',
        'hollow-square',
        'hollow-bowtie',
        'hollow-diamond',
        'hollow-hexagon',
        'hollow-triangle',
        'hollow-triangle-down',
        'circle',
        'square',
        'bowtie',
        'diamond',
        'hexagon',
        'triangle',
        'triangle-down',
        'cross',
        'tick',
        'plus',
        'hyphen',
        'line',
      ],
      line: ['line', 'dash', 'dot', 'smooth'],
      area: ['area', 'smooth', 'line', 'smooth-line'],
      interval: ['rect', 'hollow-rect', 'line', 'tick'],
    },
    sizes: [1, 10],
    geometries: {
      interval: {
        rect: {
          default: { style: { fill: primary[5], fillOpacity: 0.95 } },
          active: { style: { stroke: '#000', lineWidth: 1 } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: {},
        },
        'hollow-rect': {
          default: {
            style: {
              fill: '#FFFFFF',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { stroke: '#000', lineWidth: 2 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { stroke: '#000', lineWidth: 3, strokeOpacity: 1 },
          },
        },
        line: {
          default: {
            style: {
              fill: '#FFFFFF',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { stroke: '#000', lineWidth: 2 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { stroke: '#000', lineWidth: 3, strokeOpacity: 1 },
          },
        },
        tick: {
          default: {
            style: {
              fill: '#FFFFFF',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { stroke: '#000', lineWidth: 2 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { stroke: '#000', lineWidth: 3, strokeOpacity: 1 },
          },
        },
        funnel: {
          default: { style: { fill: primary[5], fillOpacity: 0.95 } },
          active: { style: { stroke: '#000', lineWidth: 1 } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        pyramid: {
          default: { style: { fill: primary[5], fillOpacity: 0.95 } },
          active: { style: { stroke: '#000', lineWidth: 1 } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
      },
      line: {
        line: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        dot: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: null,
              lineJoin: 'round',
              lineDash: [1, 1],
            },
          },
          active: {
            style: { lineWidth: 3, lineCap: null, lineDash: [1, 1] },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
              lineCap: null,
              lineDash: [1, 1],
            },
          },
          selected: {
            style: { lineWidth: 3, lineCap: null, lineDash: [1, 1] },
          },
        },
        dash: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: null,
              lineJoin: 'round',
              lineDash: [5.5, 1],
            },
          },
          active: {
            style: { lineWidth: 3, lineCap: null, lineDash: [5.5, 1] },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
              lineCap: null,
              lineDash: [5.5, 1],
            },
          },
          selected: {
            style: { lineWidth: 3, lineCap: null, lineDash: [5.5, 1] },
          },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        hv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        vh: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        hvh: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        vhv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
      },
      polygon: {
        polygon: {
          default: { style: { fill: primary[5], fillOpacity: 0.95 } },
          active: { style: { stroke: '#000', lineWidth: 1 } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
      },
      point: {
        circle: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        square: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        bowtie: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        diamond: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        hexagon: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        triangle: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        'triangle-down': {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#FFFFFF',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: { style: { stroke: '#000' } },
          inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
          selected: { style: { stroke: '#000', lineWidth: 2 } },
        },
        'hollow-circle': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-square': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-bowtie': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-diamond': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-hexagon': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-triangle': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        'hollow-triangle-down': {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        cross: {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        tick: {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        plus: {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        hyphen: {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
        line: {
          default: {
            style: {
              fill: '#FFFFFF',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: { style: { stroke: '#000', strokeOpacity: 1 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { lineWidth: 2, stroke: '#000', strokeOpacity: 1 },
          },
        },
      },
      area: {
        area: {
          default: {
            style: { fill: primary[5], fillOpacity: 0.25, stroke: null },
          },
          active: { style: { fillOpacity: 0.5 } },
          inactive: { style: { fillOpacity: 0.3 } },
          selected: { style: { fillOpacity: 0.5 } },
        },
        smooth: {
          default: {
            style: { fill: primary[5], fillOpacity: 0.25, stroke: null },
          },
          active: { style: { fillOpacity: 0.5 } },
          inactive: { style: { fillOpacity: 0.3 } },
          selected: { style: { fillOpacity: 0.5 } },
        },
        line: {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { fill: null, lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { fill: null, lineWidth: 3 } },
        },
        'smooth-line': {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { fill: null, lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { fill: null, lineWidth: 3 } },
        },
      },
      schema: {
        candle: {
          default: {
            style: {
              fill: '#FFFFFF',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { stroke: '#000', lineWidth: 2 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { stroke: '#000', lineWidth: 3, strokeOpacity: 1 },
          },
        },
        box: {
          default: {
            style: {
              fill: '#FFFFFF',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { stroke: '#000', lineWidth: 2 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: {
            style: { stroke: '#000', lineWidth: 3, strokeOpacity: 1 },
          },
        },
      },
      edge: {
        line: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        vhv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        arc: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
      },
      violin: {
        violin: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: { style: { lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { lineWidth: 3 } },
        },
        hollow: {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { fill: null, lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { fill: null, lineWidth: 3 } },
        },
        'hollow-smooth': {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: { style: { fill: null, lineWidth: 3 } },
          inactive: { style: { strokeOpacity: 0.3 } },
          selected: { style: { fill: null, lineWidth: 3 } },
        },
      },
    },
    components: {
      axis: {
        common: {
          title: {
            autoRotate: true,
            position: 'center',
            spacing: 12,
            style: {
              fill: '#595959',
              fontSize: 12,
              lineHeight: 12,
              textBaseline: 'middle',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          label: {
            autoRotate: false,
            autoEllipsis: false,
            autoHide: { type: 'equidistance', cfg: { minGap: 6 } },
            offset: 8,
            style: {
              fill: '#8C8C8C',
              fontSize: 12,
              lineHeight: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          line: { style: { lineWidth: 1, stroke: '#BFBFBF' } },
          grid: {
            line: {
              type: 'line',
              style: { stroke: '#D9D9D9', lineWidth: 1, lineDash: null },
            },
            alignTick: true,
            animate: true,
          },
          tickLine: {
            style: { lineWidth: 1, stroke: '#BFBFBF' },
            alignTick: true,
            length: 4,
          },
          subTickLine: null,
          animate: true,
        },
        top: {
          position: 'top',
          grid: null,
          title: null,
          verticalLimitLength: 0.5,
        },
        bottom: {
          position: 'bottom',
          grid: null,
          title: null,
          verticalLimitLength: 0.5,
        },
        left: {
          position: 'left',
          title: null,
          line: null,
          tickLine: null,
          verticalLimitLength: 0.3333333333333333,
        },
        right: {
          position: 'right',
          title: null,
          line: null,
          tickLine: null,
          verticalLimitLength: 0.3333333333333333,
        },
        circle: {
          title: null,
          grid: {
            line: {
              type: 'line',
              style: { stroke: '#D9D9D9', lineWidth: 1, lineDash: null },
            },
            alignTick: true,
            animate: true,
          },
        },
        radius: {
          title: null,
          grid: {
            line: {
              type: 'circle',
              style: { stroke: '#D9D9D9', lineWidth: 1, lineDash: null },
            },
            alignTick: true,
            animate: true,
          },
        },
      },
      legend: {
        common: {
          title: null,
          marker: {
            symbol: 'circle',
            spacing: 8,
            style: { r: 4, fill: primary[5] },
          },
          itemName: {
            spacing: 5,
            style: {
              fill: '#595959',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
              fontSize: 12,
              lineHeight: 12,
              fontWeight: 'normal',
              textAlign: 'start',
              textBaseline: 'middle',
            },
          },
          itemStates: {
            active: { nameStyle: { opacity: 0.8 } },
            unchecked: {
              nameStyle: { fill: '#D8D8D8' },
              markerStyle: { fill: '#D8D8D8', stroke: '#D8D8D8' },
            },
            inactive: {
              nameStyle: { fill: '#D8D8D8' },
              markerStyle: { opacity: 0.2 },
            },
          },
          flipPage: true,
          pageNavigator: {
            marker: {
              style: {
                size: 12,
                inactiveFill: '#000',
                inactiveOpacity: 0.45,
                fill: '#000',
                opacity: 1,
              },
            },
            text: { style: { fill: '#8C8C8C', fontSize: 12 } },
          },
          animate: false,
          maxItemWidth: 200,
          itemSpacing: 24,
          itemMarginBottom: 12,
          padding: [8, 8, 8, 8],
        },
        right: { layout: 'vertical', padding: [0, 8, 0, 8] },
        left: { layout: 'vertical', padding: [0, 8, 0, 8] },
        top: { layout: 'horizontal', padding: [8, 0, 8, 0] },
        bottom: { layout: 'horizontal', padding: [8, 0, 8, 0] },
        continuous: {
          title: null,
          background: null,
          track: {},
          rail: {
            type: 'color',
            size: 12,
            defaultLength: 100,
            style: { fill: '#D9D9D9', stroke: null, lineWidth: 0 },
          },
          label: {
            align: 'rail',
            spacing: 4,
            formatter: null,
            style: {
              fill: '#8C8C8C',
              fontSize: 12,
              lineHeight: 12,
              textBaseline: 'middle',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          handler: {
            size: 10,
            style: { fill: '#F0F0F0', stroke: '#BFBFBF' },
          },
          slidable: true,
          padding: [8, 8, 8, 8],
        },
      },
      tooltip: {
        showContent: true,
        follow: true,
        showCrosshairs: false,
        showMarkers: true,
        shared: false,
        enterable: false,
        position: 'auto',
        marker: {
          symbol: 'circle',
          stroke: '#fff',
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.09)',
          lineWidth: 2,
          r: 4,
        },
        crosshairs: {
          line: { style: { stroke: '#BFBFBF', lineWidth: 1 } },
          text: null,
          textBackground: {
            padding: 2,
            style: {
              fill: 'rgba(0, 0, 0, 0.25)',
              lineWidth: 0,
              stroke: null,
            },
          },
          follow: false,
        },
        domStyles: {
          'g2-tooltip': {
            position: 'absolute',
            visibility: 'hidden',
            zIndex: 8,
            transition:
              'left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s',
            backgroundColor: 'rgb(255, 255, 255)',
            opacity: 0.95,
            boxShadow: '0px 0px 10px #aeaeae',
            borderRadius: '3px',
            color: '#595959',
            fontSize: '12px',
            fontFamily:
              '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            lineHeight: '12px',
            padding: '0 12px 0 12px',
          },
          'g2-tooltip-title': { marginBottom: '12px', marginTop: '12px' },
          'g2-tooltip-list': {
            margin: 0,
            listStyleType: 'none',
            padding: 0,
          },
          'g2-tooltip-list-item': {
            listStyleType: 'none',
            padding: 0,
            marginBottom: '12px',
            marginTop: '12px',
            marginLeft: 0,
            marginRight: 0,
          },
          'g2-tooltip-marker': {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px',
          },
          'g2-tooltip-value': {
            display: 'inline-block',
            float: 'right',
            marginLeft: '30px',
          },
        },
      },
      annotation: {
        arc: {
          style: { stroke: '#D9D9D9', lineWidth: 1 },
          animate: true,
        },
        line: {
          style: { stroke: '#BFBFBF', lineDash: null, lineWidth: 1 },
          text: {
            position: 'start',
            autoRotate: true,
            style: {
              fill: '#595959',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              textAlign: 'start',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
              textBaseline: 'bottom',
            },
          },
          animate: true,
        },
        text: {
          style: {
            fill: '#595959',
            stroke: null,
            lineWidth: 0,
            fontSize: 12,
            textBaseline: 'middle',
            textAlign: 'start',
            fontFamily:
              '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
          animate: true,
        },
        region: {
          top: false,
          style: {
            lineWidth: 0,
            stroke: null,
            fill: '#000',
            fillOpacity: 0.06,
          },
          animate: true,
        },
        image: { top: false, animate: true },
        dataMarker: {
          top: true,
          point: { style: { r: 3, stroke: primary[5], lineWidth: 2 } },
          line: {
            style: { stroke: '#BFBFBF', lineWidth: 1 },
            length: 16,
          },
          text: {
            style: {
              textAlign: 'start',
              fill: '#595959',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          direction: 'upward',
          autoAdjust: true,
          animate: true,
        },
        dataRegion: {
          style: {
            region: { fill: '#000', fillOpacity: 0.06 },
            text: {
              textAlign: 'center',
              textBaseline: 'bottom',
              fill: '#595959',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          animate: true,
        },
      },
      slider: {
        common: {
          padding: [8, 8, 8, 8],
          backgroundStyle: { fill: '#416180', opacity: 0.05 },
          foregroundStyle: { fill: primary[5], opacity: 0.15 },
          handlerStyle: {
            width: 10,
            height: 24,
            fill: '#F7F7F7',
            opacity: 1,
            stroke: '#BFBFBF',
            lineWidth: 1,
            radius: 2,
            highLightFill: '#FFF',
          },
          textStyle: {
            fill: '#000',
            opacity: 0.45,
            fontSize: 12,
            lineHeight: 12,
            fontWeight: 'normal',
            stroke: null,
            lineWidth: 0,
          },
        },
      },
      scrollbar: {
        common: { padding: [8, 8, 8, 8] },
        default: {
          style: {
            trackColor: 'rgba(0,0,0,0)',
            thumbColor: 'rgba(0,0,0,0.15)',
          },
        },
        hover: { style: { thumbColor: 'rgba(0,0,0,0.2)' } },
      },
    },
    labels: {
      offset: 12,
      style: {
        fill: 'rgba(89, 89, 89, 1)',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: 'rgba(255, 255, 255, 1)',
        lineWidth: 0,
      },
      fillColorDark: '#2c3542',
      fillColorLight: '#ffffff',
      autoRotate: true,
    },
    innerLabels: {
      style: {
        fill: '#FFFFFF',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: null,
        lineWidth: 0,
      },
      autoRotate: true,
    },
    overflowLabels: {
      style: {
        fill: '#595959',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: '#FFFFFF',
        lineWidth: 1,
      },
    },
    pieLabels: {
      labelHeight: 14,
      offset: 10,
      labelLine: { style: { lineWidth: 1 } },
      autoRotate: true,
    },
  })

  registerTheme('ala-dark', {
    background: 'rgba(0, 0, 0, 0)',
    defaultColor: primary[5],
    subColor: 'rgba(255,255,255,0.05)',
    semanticRed: 'rgba(51, 161, 175, 1)',
    semanticGreen: 'rgba(227, 245, 245, 1)',
    padding: 'auto',
    fontFamily:
      '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
    columnWidthRatio: 0.5,
    maxColumnWidth: null,
    minColumnWidth: null,
    roseWidthRatio: 0.9999999,
    multiplePieWidthRatio: 0.7692307692307692,
    colors10: [
      'rgba(7, 104, 186, 1)',
      'rgba(20, 155, 127, 1)',
      'rgba(60, 167, 211, 1)',
      'rgba(218, 183, 5, 1)',
      'rgba(113, 59, 201, 1)',
      'rgba(139, 187, 17, 1)',
      'rgba(213, 164, 35, 1)',
      'rgba(213, 132, 35, 1)',
      'rgba(216, 74, 27, 1)',
      'rgba(196, 8, 71, 1)',
    ],
    colors20: [
      primary[5],
      '#CDDDFD',
      '#5AD8A6',
      '#CDF3E4',
      '#5D7092',
      '#CED4DE',
      '#F6BD16',
      '#FCEBB9',
      '#6F5EF9',
      '#D3CEFD',
      '#6DC8EC',
      '#D3EEF9',
      '#945FB9',
      '#DECFEA',
      '#FF9845',
      '#FFE0C7',
      '#1E9493',
      '#BBDEDE',
      '#FF99C3',
      '#FFE0ED',
    ],
    sequenceColors: [
      '#B8E1FF',
      '#9AC5FF',
      '#7DAAFF',
      primary[5],
      '#3D76DD',
      '#085EC0',
      '#0047A5',
      '#00318A',
      '#001D70',
    ],
    shapes: {
      point: [
        'hollow-circle',
        'hollow-square',
        'hollow-bowtie',
        'hollow-diamond',
        'hollow-hexagon',
        'hollow-triangle',
        'hollow-triangle-down',
        'circle',
        'square',
        'bowtie',
        'diamond',
        'hexagon',
        'triangle',
        'triangle-down',
        'cross',
        'tick',
        'plus',
        'hyphen',
        'line',
      ],
      line: ['line', 'dash', 'dot', 'smooth'],
      area: ['area', 'smooth', 'line', 'smooth-line'],
      interval: ['rect', 'hollow-rect', 'line', 'tick'],
    },
    sizes: [1, 10],
    geometries: {
      interval: {
        rect: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 1,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {},
        },
        'hollow-rect': {
          default: {
            style: {
              fill: '#000',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 3,
              strokeOpacity: 1,
            },
          },
        },
        line: {
          default: {
            style: {
              fill: '#000',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 3,
              strokeOpacity: 1,
            },
          },
        },
        tick: {
          default: {
            style: {
              fill: '#000',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 3,
              strokeOpacity: 1,
            },
          },
        },
        funnel: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 1,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        pyramid: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 1,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
      },
      line: {
        line: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        dot: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: null,
              lineJoin: 'round',
              lineDash: [1, 1],
            },
          },
          active: {
            style: {
              lineWidth: 3,
              lineCap: null,
              lineDash: [1, 1],
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
              lineCap: null,
              lineDash: [1, 1],
            },
          },
          selected: {
            style: {
              lineWidth: 3,
              lineCap: null,
              lineDash: [1, 1],
            },
          },
        },
        dash: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: null,
              lineJoin: 'round',
              lineDash: [5.5, 1],
            },
          },
          active: {
            style: {
              lineWidth: 3,
              lineCap: null,
              lineDash: [5.5, 1],
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
              lineCap: null,
              lineDash: [5.5, 1],
            },
          },
          selected: {
            style: {
              lineWidth: 3,
              lineCap: null,
              lineDash: [5.5, 1],
            },
          },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        hv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        vh: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        hvh: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        vhv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
      },
      polygon: {
        polygon: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 1,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
      },
      point: {
        circle: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        square: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        bowtie: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        diamond: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        hexagon: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        triangle: {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        'triangle-down': {
          default: {
            style: {
              fill: primary[5],
              r: 4,
              stroke: '#000',
              lineWidth: 1,
              fillOpacity: 0.95,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
        },
        'hollow-circle': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-square': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-bowtie': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-diamond': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-hexagon': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-triangle': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        'hollow-triangle-down': {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        cross: {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        tick: {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        plus: {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        hyphen: {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
        line: {
          default: {
            style: {
              fill: '#000',
              lineWidth: 1,
              stroke: primary[5],
              strokeOpacity: 0.95,
              r: 4,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 2,
              stroke: '#FFFFFF',
              strokeOpacity: 1,
            },
          },
        },
      },
      area: {
        area: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.25,
              stroke: null,
            },
          },
          active: {
            style: {
              fillOpacity: 0.5,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fillOpacity: 0.5,
            },
          },
        },
        smooth: {
          default: {
            style: {
              fill: primary[5],
              fillOpacity: 0.25,
              stroke: null,
            },
          },
          active: {
            style: {
              fillOpacity: 0.5,
            },
          },
          inactive: {
            style: {
              fillOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fillOpacity: 0.5,
            },
          },
        },
        line: {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
        },
        'smooth-line': {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
        },
      },
      schema: {
        candle: {
          default: {
            style: {
              fill: '#000',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 3,
              strokeOpacity: 1,
            },
          },
        },
        box: {
          default: {
            style: {
              fill: '#000',
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 2,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              stroke: '#FFFFFF',
              lineWidth: 3,
              strokeOpacity: 1,
            },
          },
        },
      },
      edge: {
        line: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        vhv: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        arc: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
      },
      violin: {
        violin: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        smooth: {
          default: {
            style: {
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
              fill: null,
              lineAppendWidth: 10,
              lineCap: 'round',
              lineJoin: 'round',
            },
          },
          active: {
            style: {
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              lineWidth: 3,
            },
          },
        },
        hollow: {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
        },
        'hollow-smooth': {
          default: {
            style: {
              fill: null,
              stroke: primary[5],
              lineWidth: 2,
              strokeOpacity: 1,
            },
          },
          active: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
          inactive: {
            style: {
              strokeOpacity: 0.3,
            },
          },
          selected: {
            style: {
              fill: null,
              lineWidth: 3,
            },
          },
        },
      },
    },
    components: {
      axis: {
        common: {
          title: {
            autoRotate: true,
            position: 'center',
            spacing: 12,
            style: {
              fill: '#A6A6A6',
              fontSize: 12,
              lineHeight: 12,
              textBaseline: 'middle',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          label: {
            autoRotate: false,
            autoEllipsis: false,
            autoHide: {
              type: 'equidistance',
              cfg: {
                minGap: 6,
              },
            },
            offset: 8,
            style: {
              fill: 'rgba(255, 255, 255, 0.85)',
              fontSize: 12,
              lineHeight: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          line: {
            style: {
              lineWidth: 1,
              stroke: 'rgba(12, 44, 70, 1)',
            },
          },
          grid: {
            line: {
              type: 'line',
              style: {
                stroke: 'rgba(21, 76, 122, 1)',
                lineWidth: 1,
                lineDash: null,
              },
            },
            alignTick: true,
            animate: true,
          },
          tickLine: {
            style: {
              lineWidth: 1,
              stroke: 'rgba(12, 44, 70, 1)',
            },
            alignTick: true,
            length: 4,
          },
          subTickLine: null,
          animate: true,
        },
        top: {
          position: 'top',
          grid: null,
          title: {
            style: {
              fill: 'rgba(190, 190, 190, 1)',
            },
          },
          verticalLimitLength: 0.5,
        },
        bottom: {
          position: 'bottom',
          grid: null,
          title: {
            style: {
              fill: 'rgba(190, 190, 190, 1)',
            },
          },
          verticalLimitLength: 0.5,
        },
        left: {
          position: 'left',
          title: {
            style: {
              fill: 'rgba(190, 190, 190, 1)',
            },
          },
          line: null,
          tickLine: null,
          verticalLimitLength: 0.3333333333333333,
        },
        right: {
          position: 'right',
          title: {
            style: {
              fill: 'rgba(190, 190, 190, 1)',
            },
          },
          line: null,
          tickLine: null,
          verticalLimitLength: 0.3333333333333333,
        },
        circle: {
          title: null,
          grid: {
            line: {
              type: 'line',
              style: {
                stroke: 'rgba(21, 76, 122, 1)',
                lineWidth: 1,
                lineDash: null,
              },
            },
            alignTick: true,
            animate: true,
          },
        },
        radius: {
          title: {
            style: {
              fill: 'rgba(0, 0, 0, 0)',
              fontSize: null,
              fontWeight: 'normal',
            },
          },
          grid: {
            line: {
              type: 'circle',
              style: {
                stroke: 'rgba(21, 76, 122, 1)',
                lineWidth: 1,
                lineDash: null,
              },
            },
            alignTick: true,
            animate: true,
          },
        },
      },
      legend: {
        common: {
          title: null,
          marker: {
            symbol: 'circle',
            spacing: 8,
            style: {
              r: 4,
              fill: primary[5],
            },
          },
          itemName: {
            spacing: 5,
            style: {
              fill: 'rgba(235, 235, 235, 0.85)',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
              fontSize: 12,
              lineHeight: 12,
              fontWeight: 'normal',
              textAlign: 'start',
              textBaseline: 'middle',
            },
          },
          itemStates: {
            active: {
              nameStyle: {
                opacity: 0.8,
              },
            },
            unchecked: {
              nameStyle: {
                fill: '#D8D8D8',
              },
              markerStyle: {
                fill: '#D8D8D8',
                stroke: '#D8D8D8',
              },
            },
            inactive: {
              nameStyle: {
                fill: '#D8D8D8',
              },
              markerStyle: {
                opacity: 0.2,
              },
            },
          },
          flipPage: true,
          pageNavigator: {
            marker: {
              style: {
                size: 12,
                inactiveFill: 'rgba(21, 76, 122, 1)',
                inactiveOpacity: 0.45,
                fill: 'rgba(21, 76, 122, 1)',
                opacity: 1,
              },
            },
            text: {
              style: {
                fill: 'rgba(21, 76, 122, 1)',
                fontSize: 12,
              },
            },
          },
          animate: false,
          maxItemWidth: 200,
          itemSpacing: 24,
          itemMarginBottom: 12,
          padding: [8, 8, 8, 8],
        },
        right: {
          layout: 'vertical',
          padding: [0, 8, 0, 8],
        },
        left: {
          layout: 'vertical',
          padding: [0, 8, 0, 8],
        },
        top: {
          layout: 'horizontal',
          padding: [8, 0, 8, 0],
        },
        bottom: {
          layout: 'horizontal',
          padding: [8, 0, 8, 0],
        },
        continuous: {
          title: null,
          background: null,
          track: {},
          rail: {
            type: 'color',
            size: 12,
            defaultLength: 100,
            style: {
              fill: 'rgba(65, 97, 128, 1)',
              stroke: 'rgba(65, 97, 128, 1)',
              lineWidth: 0,
            },
          },
          label: {
            align: 'rail',
            spacing: 4,
            formatter: null,
            style: {
              fill: 'rgba(255, 255, 255, 1)',
              fontSize: 12,
              lineHeight: 12,
              textBaseline: 'middle',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          handler: {
            size: 10,
            style: {
              fill: 'rgba(9, 33, 52, 1)',
              stroke: 'rgba(21, 76, 122, 1)',
            },
          },
          slidable: true,
          padding: [8, 8, 8, 8],
        },
      },
      tooltip: {
        showContent: true,
        follow: true,
        showCrosshairs: false,
        showMarkers: true,
        shared: false,
        enterable: false,
        position: 'auto',
        marker: {
          symbol: 'circle',
          stroke: '#fff',
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.09)',
          lineWidth: 2,
          r: 4,
        },
        crosshairs: {
          line: { style: { stroke: '#404040', lineWidth: 1 } },
          text: null,
          textBackground: {
            padding: 2,
            style: { fill: 'rgba(0, 0, 0, 0.25)', lineWidth: 0, stroke: null },
          },
          follow: false,
        },
        domStyles: {
          'g2-tooltip': {
            position: 'absolute',
            visibility: 'hidden',
            zIndex: 8,
            transition:
              'left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s',
            backgroundColor: '#0a273d',
            opacity: 0.95,
            boxShadow: '0px 2px 4px rgba(0,0,0,.5)',
            borderRadius: '3px',
            color: 'ffffffb1',
            fontSize: '12px',
            fontFamily:
              '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            lineHeight: '12px',
            padding: '0 12px 0 12px',
          },
          'g2-tooltip-title': { marginBottom: '12px', marginTop: '12px' },
          'g2-tooltip-list': { margin: 0, listStyleType: 'none', padding: 0 },
          'g2-tooltip-list-item': {
            listStyleType: 'none',
            padding: 0,
            marginBottom: '12px',
            marginTop: '12px',
            marginLeft: 0,
            marginRight: 0,
          },
          'g2-tooltip-marker': {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px',
          },
          'g2-tooltip-value': {
            display: 'inline-block',
            float: 'right',
            marginLeft: '30px',
          },
        },
      },
      annotation: {
        arc: {
          style: {
            stroke: '#262626',
            lineWidth: 1,
          },
          animate: true,
        },
        line: {
          style: {
            stroke: '#404040',
            lineDash: null,
            lineWidth: 1,
          },
          text: {
            position: 'start',
            autoRotate: true,
            style: {
              fill: '#A6A6A6',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              textAlign: 'start',
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
              textBaseline: 'bottom',
            },
          },
          animate: true,
        },
        text: {
          style: {
            fill: '#A6A6A6',
            stroke: null,
            lineWidth: 0,
            fontSize: 12,
            textBaseline: 'middle',
            textAlign: 'start',
            fontFamily:
              '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
          animate: true,
        },
        region: {
          top: false,
          style: {
            lineWidth: 0,
            stroke: null,
            fill: '#FFFFFF',
            fillOpacity: 0.06,
          },
          animate: true,
        },
        image: {
          top: false,
          animate: true,
        },
        dataMarker: {
          top: true,
          point: {
            style: {
              r: 3,
              stroke: primary[5],
              lineWidth: 2,
            },
          },
          line: {
            style: {
              stroke: '#404040',
              lineWidth: 1,
            },
            length: 16,
          },
          text: {
            style: {
              textAlign: 'start',
              fill: '#A6A6A6',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          direction: 'upward',
          autoAdjust: true,
          animate: true,
        },
        dataRegion: {
          style: {
            region: {
              fill: '#FFFFFF',
              fillOpacity: 0.06,
            },
            text: {
              textAlign: 'center',
              textBaseline: 'bottom',
              fill: '#A6A6A6',
              stroke: null,
              lineWidth: 0,
              fontSize: 12,
              fontFamily:
                '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            },
          },
          animate: true,
        },
      },
      slider: {
        common: {
          padding: [8, 8, 8, 8],
          backgroundStyle: {
            fill: 'rgba(65, 97, 128, 1)',
            opacity: 0.15,
          },
          foregroundStyle: {
            fill: 'rgba(51, 161, 175, 1)',
            opacity: 0.2,
          },
          handlerStyle: {
            width: 10,
            height: 24,
            fill: 'rgba(9, 33, 52, 1)',
            opacity: 1,
            stroke: 'rgba(21, 76, 122, 1)',
            lineWidth: 1,
            radius: 2,
            highLightFill: 'rgba(9, 33, 52, 1)',
          },
          textStyle: {
            fill: 'rgba(255, 255, 255, 1)',
            opacity: 0.45,
            fontSize: 12,
            lineHeight: 12,
            fontWeight: 'normal',
            stroke: null,
            lineWidth: 0,
          },
        },
      },
      scrollbar: {
        common: {
          padding: [8, 8, 8, 8],
        },
        default: {
          style: {
            trackColor: 'rgba(12, 44, 70, 1)',
            thumbColor: 'rgba(21, 76, 122, 1)',
          },
        },
        hover: {
          style: {
            thumbColor: 'rgba(18, 66, 105, 1)',
          },
        },
      },
    },
    labels: {
      offset: 12,
      style: {
        fill: 'rgba(201, 201, 201, 1)',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: null,
        lineWidth: 0,
      },
      fillColorDark: '#2c3542',
      fillColorLight: '#ffffff',
      autoRotate: true,
    },
    innerLabels: {
      style: {
        fill: '#000',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: null,
        lineWidth: 0,
      },
      autoRotate: true,
    },
    overflowLabels: {
      style: {
        fill: '#A6A6A6',
        fontSize: 12,
        fontFamily:
          '"-apple-system", "Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        stroke: '#000',
        lineWidth: 1,
      },
    },
    pieLabels: {
      labelHeight: 14,
      offset: 10,
      labelLine: {
        style: {
          lineWidth: 1,
        },
      },
      autoRotate: true,
    },
    'bottom-axis-subTick-line': false,
  })

  return isDark ? 'ala-dark' : 'ala-light'
}

export default useChartTheme
