import React, { useContext, useEffect } from 'react'
import { Affix, ConfigProvider, message } from 'antd'
import axios from 'axios'
import { useMediaQuery } from 'beautiful-react-hooks'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import ContextGlobal from '../../context'
import { useLanguageCTX } from '../../Utilities/LanguageCTX'
// import ALAMenu from './ALAMenu'
import { clearStorageKey, setStorage } from '../../Utilities/Storage'
import ALAMenu from './Menu'
import { Layout as LayoutAnt, Header, Content } from './styles'
import Toolbar from './Toolbar'

const Layout = () => {
  const { baseUrl } = useContext(ContextGlobal)
  const { locale } = useLanguageCTX()
  const mobile = useMediaQuery('(max-width: 55em)')
  const { t } = useTranslation('translate')

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const res = await axios.get('/ala/current-user')

        const { data } = res
        if (res.status === 401) {
          return window.location.replace(
            `${baseUrl}/users/sign_in?redirect_to=${window.location.href}`
          )
        }

        setStorage('currentUser', data.info)
      } catch (e) {
        message.error(t('current-user.fetch-error'))
        clearStorageKey('currentUser')
      }
    }

    getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider locale={locale}>
      <LayoutAnt>
        <Affix>
          <div>
            <Header toolbar={+true}>
              <Toolbar />
            </Header>
            {!mobile && (
              <Header>
                <ALAMenu />
              </Header>
            )}
          </div>
        </Affix>
        <Content>
          <Outlet />
        </Content>
      </LayoutAnt>
    </ConfigProvider>
  )
}

export default Layout
