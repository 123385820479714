/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Button, Menu, message } from 'antd'
import axios from 'axios'
import { useMediaQuery } from 'beautiful-react-hooks'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Icon } from '../../../Components'
import { DashboardItem } from '../../../Types'
import EditDashboard from '../EditDashboard'
import NewDashboard from '../NewDashboard'
import { MenuItemContent, MenuWrapper, StyledItem } from './styles'

const ALAMenu = () => {
  const [showModal, setShowModal] = useState(false)
  const [showEditDashboard, setShowEditDashboard] = useState(false)
  const [showEditDBID, setShowEditDBID] = useState(0)
  const [editDashboard, setEditDashboard] = useState(0)
  const [dashboardList, setDashboardList] = useState<DashboardItem[]>([])
  const mobile = useMediaQuery('(max-width: 55em)')
  const addNewRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation('translate', { keyPrefix: 'new-dashboard' })
  const { id: routeID } = useParams()
  const navigate = useNavigate()
  const { colors } = useTheme()

  const getUserDashboards = async () => {
    try {
      const { data } = await axios.get('/ala/my_dashboards')
      if (Array.isArray(data)) {
        const dashboard = data.find((d) => d?.id === +`${routeID}`)
        if (!dashboard) {
          if (data.length) {
            navigate(`/dashboard/${data[0].id}`)
          }
        }
        //   if (!dashboard) {
        //     if (data.length) {
        //     } else {
        //       navigate('/welcome')
        //     }
        setDashboardList(data)
      }

      // }
    } catch (e) {
      message.error(t('request-error'))
    }
  }

  useEffect(() => {
    getUserDashboards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDashboardList = useCallback(
    (newDashboard: DashboardItem, operation?: 'update' | 'delete') => {
      switch (operation) {
        case 'update':
          setDashboardList((dL) =>
            dL.map((d) => (d.id === newDashboard.id ? newDashboard : d))
          )
          break
        case 'delete':
          const newDashboards = dashboardList.filter(
            (d) => d.id !== newDashboard.id
          )
          setDashboardList(newDashboards)
          if (routeID === String(newDashboard.id)) {
            if (newDashboards.length) {
              navigate(`/${newDashboards[newDashboards.length - 1].id}`)
            } else {
              navigate('/welcome/get-started')
            }
          }

          break
        default:
          setDashboardList((dL) => [...dL, newDashboard])
          break
      }
    },
    [dashboardList, navigate, routeID]
  )

  const editableDashboard = useMemo(
    () => dashboardList.find((db) => db.id === editDashboard),
    [dashboardList, editDashboard]
  )

  return (
    <MenuWrapper data-tut="reactour__init">
      <Menu
        mode={mobile ? 'inline' : 'horizontal'}
        selectedKeys={[String(routeID)]}
        style={{
          width: mobile
            ? '100%'
            : `calc(100% - ${addNewRef.current?.clientWidth}px)`,
        }}
      >
        {dashboardList.map(({ icon, name, id }) => (
          <StyledItem
            key={id}
            onMouseOver={() => setShowEditDBID(id)}
            onMouseLeave={() => setShowEditDBID(0)}
          >
            <Link to={`/dashboard/${id}`}>
              <MenuItemContent
                className="menu-item-content"
                style={{
                  color: routeID
                    ? +routeID === id
                      ? colors.primary[5]
                      : 'white'
                    : 'white',
                }}
              >
                <Icon className={icon} />
                {name}
                {showEditDBID === id && (
                  <Button
                    icon={<Icon className="fa-solid fa-pencil" />}
                    shape="circle"
                    type="primary"
                    size="small"
                    onClick={(e) => {
                      setShowEditDashboard(true)
                      setEditDashboard(id)
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />
                )}
              </MenuItemContent>
            </Link>
          </StyledItem>
        ))}
      </Menu>
      <Button
        ref={addNewRef}
        icon={<Icon className="fa-solid fa-plus button-icon" />}
        onClick={() => setShowModal(true)}
        type="primary"
        style={{
          margin: mobile ? '1rem' : '0',
          width: mobile ? 'calc(100% - 2rem)' : 'auto',
        }}
        data-tut="reactour__create_newDashboard"
      >
        {t('add-btn')}
      </Button>
      <EditDashboard
        visible={showEditDashboard}
        setVisible={setShowEditDashboard}
        updateDashboardList={updateDashboardList}
        dashboard={editableDashboard}
      />
      <NewDashboard
        showModal={showModal}
        setShowModal={setShowModal}
        updateDashboardList={updateDashboardList}
      />
    </MenuWrapper>
  )
}

export default ALAMenu
