import { Layout as AntLayout, LayoutProps } from 'antd'
import styled from 'styled-components'

const { Header: AntHeader, Content: AntContent, Footer: AntFooter } = AntLayout

export const Layout = styled(AntLayout)<LayoutProps>`
  min-height: 100vh;
`

interface HeaderProps {
  toolbar?: number
}

export const Header = styled(AntHeader)<HeaderProps>`
  border-bottom: ${(props) =>
    props.toolbar && `1px solid ${props.theme.colors.menuBorderColor}`};
  height: ${(props) => props.toolbar && 'auto !important'};
  background: ${(props) => props.theme.colors.menuBackground} !important;

  .ant-menu-horizontal {
    border-bottom-color: transparent;
    background: ${(props) => props.theme.colors.menuBackground};
    color: #fff;
  }

  @media screen and (min-width: 0) {
    padding: ${(props) => (props.toolbar ? '0 1rem' : '0')};
  }

  @media screen and (min-width: 50em) {
    padding: ${(props) => (props.toolbar ? '0 2.5rem 0 3rem' : '0 3rem')};
  }

  @media screen and (min-width: 65em) {
    padding: 0 6rem;
  }
`

export const Content = styled(AntContent)`
  height: 100%;
  min-height: auto !important;
  /* box-sizing: border-box; */

  @media screen and (min-width: 0em) {
    padding: calc(2rem - 20px) calc(1.5rem - 20px);
  }

  @media screen and (min-width: 50em) {
    padding: calc(3rem - 20px);
  }

  @media screen and (min-width: 65em) {
    padding: calc(3rem - 20px) calc(6rem - 20px);
  }
`

export const Footer = styled(AntFooter)`
  text-align: center;
  background: ${(props) => props.theme.colors.menuBackground};
  color: #fff;
`
