import { generate } from '@ant-design/colors'
import { Button as ButtonAntd, ButtonProps } from 'antd'
import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  & .ant-spin-nested-loading {
    height: 100%;
  }
  & .ant-spin-container {
    height: 100%;
  }
`
export const CardTitle = styled.div`
  padding: 1rem;

  > :first-child {
    color: ${(props) => props?.color || ''};
  }
  span,
  i {
    color: ${(props) => props?.color || ''};
  }

  div {
    color: #fff;
    opacity: 0.6;
  }
`

export const CardBody = styled.div`
  margin: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .summary {
    p,
    h5 {
      color: ${(props) => props?.color || ''};
      margin: 0;
    }

    p {
      opacity: 0.85;
    }
  }
`

export const ButtonDelete: typeof ButtonAntd = styled(ButtonAntd)<ButtonProps>`
  position: absolute !important;
  top: 2%;
  right: 1%;
  opacity: 0;
  transition: ${({ theme }) => theme.transitions.durations.normal}s;
`
export const ButtonChange: typeof ButtonAntd = styled(ButtonAntd)<ButtonProps>`
  /* position: absolute;
  bottom: 2%;
  left: 1%; */
`

interface CardProps {
  color: string
  change: boolean
  shadow: number
}

const getBackgroundColor = (color: string) => {
  const colorPalette = generate(color)

  return `linear-gradient(
    to left,
    ${colorPalette[3]} 0%,
    ${colorPalette[5]} 100%
  ) !important;`
}

export const Card = styled.div<CardProps>`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  overflow: hidden;
  background: ${({ color }) => getBackgroundColor(color)};
  height: 100%;
  width: 100%;
  position: relative;
  :hover {
    .buttonDelete {
      opacity: 1;
    }
  }
  /* transform: ${({ change }) =>
    change ? 'rotateY(360deg)' : 'rotateY(0deg)'}; */
  transform: ${({ change }) => (change ? 'rotateY(360deg)' : 'rotateY(0deg)')};
  transition: 0.5s;
  box-shadow: ${({ shadow, theme }) =>
    shadow ? `0 0 20px 5px ${theme.colors.primary[5]}` : ''};
`
