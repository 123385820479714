import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'
import { Locale } from 'antd/lib/locale-provider'
import enUS from 'antd/lib/locale/en_US'
import esEs from 'antd/lib/locale/es_ES'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LanguageCTXValue, Language } from '../Types'
import 'moment/locale/es'

const CTX_DEFAULT_VALUE = {
  language: 'Español',
  locale: esEs,
  changeLng: () => {
    return
  },
}

const LanguageCTX = createContext<LanguageCTXValue>(CTX_DEFAULT_VALUE)

export const useLanguageCTX = () => {
  return useContext(LanguageCTX)
}

export interface Props {
  children: ReactNode
}

// eslint-disable-next-line react/prop-types
const LanguageProvider: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(esEs)
  const { i18n } = useTranslation()

  // const loadResource = useCallback(
  //   (language: Language) => {
  //     i18n.addResourceBundle(language, appNamespace, require(`../Translations/${language}.json`))
  //   },
  //   [i18n, appNamespace]
  // )
  const changeLng = useCallback(
    (lng: Language) => {
      // loadResource(lng)

      i18n.changeLanguage(lng)
      const lang = lng.replace(/['"]+/g, '')

      localStorage.removeItem('i18next')
      localStorage.setItem('i18next', String(lang))
      switch (lang) {
        case Language['es-MX']:
          moment.locale('es')
          setLocale(esEs)
          break
        case Language['en-US']:
          moment.locale('en')
          setLocale(enUS)
          break
        default:
          moment.locale('es')
          setLocale(esEs)
          break
      }
    },
    [i18n]
  )

  const language = useMemo(() => {
    switch (i18n.language) {
      case Language['es-MX']:
        return 'Español'
      case Language['en-US']:
        return 'English'
      default:
        return 'Español'
    }
  }, [i18n.language])

  // Weird workaround to react to language change on another micro front-end
  useEffect(() => {
    changeLng(i18n.language as Language)
  }, [i18n.language, changeLng])

  const value: LanguageCTXValue = {
    language,
    locale,
    changeLng,
  }

  return <LanguageCTX.Provider value={value}>{children}</LanguageCTX.Provider>
}

export default LanguageProvider
