import { useMemo } from 'react'
import { Typography, Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../Components'
import { FormValidationError, Service } from '../../../Types'
import {
  ServiceCardWrapper,
  SelectableItem,
  ListTitle,
  ErrorListWrapper,
  LoadingWrapper,
} from './styles'

const { Text } = Typography
const { Item, ErrorList } = Form

interface Props {
  onServiceSelect(serviceID: string): void
  selectedService: string
  errors: FormValidationError[]
  services: Service[]
  loading: boolean
}

const ServiceList: React.FC<Props> = ({
  onServiceSelect,
  selectedService,
  errors,
  services,
  loading,
}) => {
  const { t } = useTranslation('translate')
  const typeServiceT = 'views.main.new-widget.service-list'
  const serviceErrors = useMemo(
    () => errors.find((item) => item.name[0] === 'service')?.errors,
    [errors]
  )

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin spinning />
      </LoadingWrapper>
    )
  }

  return (
    <Item
      rules={[
        {
          required: true,
          message: t(`${typeServiceT}.form.rules.service.required`),
        },
      ]}
      name="service"
      noStyle
    >
      <div>
        <ListTitle strong>{t(`${typeServiceT}.list-title`)}:</ListTitle>

        <ErrorListWrapper>
          <ErrorList errors={serviceErrors} />
        </ErrorListWrapper>

        <ServiceCardWrapper>
          {services.map(({ id, var_name, icon }) => {
            const serviceID = String(id)
            return (
              <SelectableItem
                key={id}
                vertical
                selected={serviceID === selectedService}
                onClick={() => onServiceSelect(serviceID)}
                type="service"
              >
                <Icon className={`fa-solid fa-${icon}`} />
                <Text>{t(`${typeServiceT}.list-items.${var_name}.name`)}</Text>
              </SelectableItem>
            )
          })}
        </ServiceCardWrapper>
      </div>
    </Item>
  )
}

export default ServiceList
