import React, { createContext, ReactNode } from 'react'
interface Value {
  baseUrl: string
}

const contextDefaultValues: Value = {
  baseUrl: '',
}

const GlobalContext = createContext<Value>(contextDefaultValues)

interface Props {
  children: ReactNode
  baseUrl: string
}

export const GlobalContextProvider: React.FC<Props> = ({
  children,
  baseUrl,
}) => {
  const value: Value = {
    baseUrl,
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export const Consumer = GlobalContext.Consumer
export default GlobalContext
