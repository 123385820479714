import React, { Dispatch, SetStateAction } from 'react'
import { Layouts } from 'react-grid-layout'
import { Widget as IWidget } from '../../../Types'
import Chart from './Chart'
import KPI from './KPI'
import Map from './Map'
import PercentageChart from './PercentageChart'
import Report from './Report'
import ReportTable from './ReportTable'
import StatusCard from './StatusCard'

interface Props {
  config: IWidget
  layouts: Layouts
  setLayouts: Dispatch<SetStateAction<Layouts>>
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const Widget: React.FC<Props> = ({
  config,
  setLayouts,
  layouts,
  handleDeleteWidget,
}) => {
  const { widget } = config
  const { widget_type } = widget

  switch (widget_type) {
    case 'card':
      return <KPI config={config} handleDeleteWidget={handleDeleteWidget} />
    case 'graph':
      return (
        <Chart
          config={config}
          setLayouts={setLayouts}
          layouts={layouts}
          handleDeleteWidget={handleDeleteWidget}
        />
      )
    case 'geographic':
      return <Map config={config} handleDeleteWidget={handleDeleteWidget} />
    case 'status_card':
      return (
        <StatusCard config={config} handleDeleteWidget={handleDeleteWidget} />
      )
    case 'report':
      return <Report config={config} handleDeleteWidget={handleDeleteWidget} />
    case 'percentage_chart':
      return (
        <PercentageChart
          config={config}
          setLayouts={setLayouts}
          layouts={layouts}
          handleDeleteWidget={handleDeleteWidget}
        />
      )
    case 'report_table':
      return (
        <ReportTable config={config} handleDeleteWidget={handleDeleteWidget} />
      )
    default:
      return null
  }
}

export default Widget
