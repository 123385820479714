import { memo } from 'react'
import { Pie, PieConfig } from '@ant-design/charts'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { KeyValue } from '../../../../Types'

interface Props {
  data: { x: string; y: number; series?: string }[]
  params: KeyValue
  typeGraphActive: string
  typeGraph: string
  varName: string
}

const DataDisplay: React.FC<Props> = ({ data, typeGraph, varName }) => {
  const { isDark } = useTheme()
  const { t } = useTranslation('translate')
  const widgetCatalogueT = `widgets.${varName}.catalogues`

  const PieCFG: PieConfig = {
    appendPadding: 10,
    data: data,
    angleField: 'percent',
    colorField: 'key',
    radius: 0.8,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v: number) => v,
      },
    },
    label: {
      type: 'inner',
      autoRotate: false,
      offset: '-50%',
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'white',
      },
    },
    tooltip: {
      formatter: (el) => {
        return {
          name: t(`${widgetCatalogueT}.${el.key}`, el.key),
          value: `${el.percent}%`,
        }
      },
    },
    // autoFit: true,
    statistic: {
      title: false,
      content: false,
      // content: {
      //   style: {
      //     color: isDark ? '#fffff95' : '#00000095',
      //     whiteSpace: 'pre-wrap',
      //     overflow: 'hidden',
      //     textOverflow: 'ellipsis',
      //   },
      // },
    },
    legend: {
      layout: 'vertical',
      position: 'right',
      itemName: {
        formatter: (el) => {
          return t([`${widgetCatalogueT}.${el}`, el])
        },
      },
    },
    theme: isDark ? 'ala-dark' : 'ala-light',
  }

  switch (typeGraph) {
    case 'pie':
      return <Pie {...PieCFG} />
    default:
      return null
  }
}

// memo helps avoid the chart components from unnecessarily re rendering
const MemoizedDataDisplay = memo(DataDisplay)

export default MemoizedDataDisplay
