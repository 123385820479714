/* eslint-disable @typescript-eslint/no-shadow */
import { createContext, useContext, useState, useEffect } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { ThemeProvider } from 'styled-components'
import { ThemeCTXValue } from '../Types'
import { getStorage, setStorage } from './Storage'

const CTX_DEFAULT_VALUE = {
  isDarkTheme: false,
  toggleTheme: () => {},
}

const ThemeCTX = createContext<ThemeCTXValue>(CTX_DEFAULT_VALUE)

export const useThemeCTX = () => {
  return useContext(ThemeCTX)
}

const ThemeCTXProvider: React.FC = ({ children }) => {
  const [isDarkTheme, setisDarkTheme] = useState<boolean>(true)
  const { switcher, themes } = useThemeSwitcher()

  useEffect(() => {
    const darkThemeValue = getStorage('ala-dark-theme')
    let isDarkThemeEnabled = true

    if (darkThemeValue) {
      isDarkThemeEnabled = darkThemeValue === 'true'
    }

    setisDarkTheme(isDarkThemeEnabled)
    switcher({ theme: isDarkThemeEnabled ? themes.dark : themes.light })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleTheme = (isDarkTheme: boolean) => {
    setisDarkTheme(isDarkTheme)
    switcher({ theme: isDarkTheme ? themes.dark : themes.light })
    setStorage('ala-dark-theme', String(isDarkTheme))
  }

  const value: ThemeCTXValue = {
    isDarkTheme,
    toggleTheme,
  }

  return (
    <ThemeProvider
      theme={
        require(`../Theme/${isDarkTheme ? 'dark' : 'light'}_theme`).default
      }
    >
      <ThemeCTX.Provider value={value}>{children}</ThemeCTX.Provider>
    </ThemeProvider>
  )
}

export default ThemeCTXProvider
