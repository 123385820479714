import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useContext,
} from 'react'
import { Modal, Form, Result, Button, message, Popconfirm } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import ContextGlobal from '../../../context'
import { DashboardIcon, DashboardItem } from '../../../Types'
import { deepEqual } from '../../../Types/deepEqual'
import EditDBForm from './Form'
import { ResultWrapper } from './styles'
interface EditDashboardProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  updateDashboardList(
    updatedDashboard: DashboardItem,
    operation: 'update' | 'delete'
  ): void
  dashboard: DashboardItem | undefined
}

const INITIAL_VALUES: DashboardItem = {
  id: 0,
  name: '',
  icon: 'fas fa-archive',
}

const EditDashboard: React.FC<EditDashboardProps> = ({
  visible,
  setVisible,
  updateDashboardList,
  dashboard,
}) => {
  const { baseUrl } = useContext(ContextGlobal)

  const initialValues = dashboard || INITIAL_VALUES
  const { t } = useTranslation('translate', { keyPrefix: 'edit-dashboard' })
  const [form] = Form.useForm<DashboardItem>()
  const [selectedIcon, setSelectedIcon] = useState<DashboardIcon>(
    initialValues.icon
  )
  const [showSuccess, setShowSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [sameValues, setSameValues] = useState(true)
  const [loading, setLoading] = useState(false)
  const { transitions } = useTheme()

  const handleUpdateDashboard = async (values: DashboardItem) => {
    try {
      setLoading(true)
      const rawRes = await fetch(
        `${baseUrl}/api/ala/user_dashboards/${values.id}`,
        {
          credentials: 'include',
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        }
      )

      const { data, status, message: msg } = await rawRes.json()
      if (status === 'success') {
        form.resetFields()
        setSelectedIcon(initialValues.icon)
        setShowSuccess(true)
        updateDashboardList(data, 'update')
      } else {
        throw msg
      }
    } catch (e) {
      message.error(t('request-error'))
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteDashboard = async () => {
    try {
      setLoading(true)
      const rawRes = await fetch(
        `${baseUrl}/api/ala/user_dashboards/${dashboard?.id}`,
        {
          credentials: 'include',
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )

      const { data, status, message: msg } = await rawRes.json()
      if (status === 'success') {
        setShowDeleteSuccess(true)
        updateDashboardList(data, 'delete')
      } else {
        throw msg
      }
    } catch (e) {
      message.error(t('request-error'))
    } finally {
      setLoading(false)
    }
  }

  const onFormSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        handleUpdateDashboard(values)
      })
      .catch(() => {
        // console.log('Validate Failed:', info);
      })
  }

  const onFormCancel = () => {
    setSelectedIcon(initialValues.icon)
    setShowSuccess(false)
    setShowDeleteSuccess(false)
    setVisible(false)
  }

  const onFormValuesChange = useCallback(
    (values: DashboardItem) => {
      setSameValues(deepEqual(values, initialValues))
    },
    [initialValues]
  )

  const onIconSelect = useCallback(
    (icon: DashboardIcon) => {
      setSelectedIcon(icon)
      form.setFieldsValue({ icon })
      onFormValuesChange(form.getFieldsValue(true))
    },
    [form, onFormValuesChange]
  )

  return (
    <Modal
      visible={visible}
      centered
      onCancel={onFormCancel}
      title={t('form-title')}
      destroyOnClose
      footer={
        showSuccess || showDeleteSuccess ? null : (
          <>
            <Button onClick={onFormCancel}>{t('cancel-btn')}</Button>
            <Popconfirm
              title={t('confirm-delete-msg')}
              onConfirm={handleDeleteDashboard}
              okText={t('confirm-delete-ok-btn')}
            >
              <Button type="primary" danger>
                {t('delete-btn')}
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={onFormSubmit}
              loading={loading}
              disabled={sameValues}
            >
              {t('save-btn')}
            </Button>
          </>
        )
      }
    >
      <AnimatePresence>
        {showSuccess && (
          <ResultWrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: transitions.durations.short },
            }}
          >
            <Result
              status="success"
              title={t('success-title')}
              subTitle={t('success-subtitle')}
              extra={[
                <Link
                  to={`/dashboard/${initialValues.id}`}
                  key="open-dashboard"
                >
                  <Button type="primary" onClick={onFormCancel}>
                    {t('go-to-dashboard')}
                  </Button>
                </Link>,
              ]}
            />
          </ResultWrapper>
        )}
        {showDeleteSuccess && (
          <ResultWrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: transitions.durations.short },
            }}
          >
            <Result
              status="success"
              title={t('delete-success-title')}
              subTitle={t('delete-success-subtitle')}
            />
          </ResultWrapper>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false}>
        {!(showSuccess || showDeleteSuccess) && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: transitions.durations.short },
            }}
          >
            <EditDBForm
              form={form}
              initialValues={initialValues}
              selectedIcon={selectedIcon}
              onIconSelect={onIconSelect}
              onFormValuesChange={onFormValuesChange}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default EditDashboard
