import { Card as AntCard } from 'antd'
import styled from 'styled-components'

export const Card = styled(AntCard)``

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  height: 100%;
`
interface IconProps {
  rotate: number
}
interface FilterProps {
  open: boolean
}
export const IconMoreFilter = styled.i<IconProps>`
  ${(props) =>
    props.rotate ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);'}
  transition: .5s;
  cursor: pointer;
  margin-left: 10px;
  /* font-size: 2rem; */
`
export const MoreFilter = styled.div<FilterProps>`
  overflow: hidden;
  transition: 0.5s ease-in-out;
  max-height: ${(props) => (props.open ? '200px' : '0px')};
  width: 100%;
`

export const DividerContent = styled.div`
  cursor: pointer;
  width: 100%;
`
