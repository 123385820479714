import React from 'react'
import { ConfigProvider } from 'antd'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useLanguageCTX } from '../Utilities/LanguageCTX'
import useChartTheme from '../Utilities/useChartTheme'
import Dashboard from './Dashboard'
import Layout from './Layout'
import Tour from './Tour'
const Views = () => {
  const { locale } = useLanguageCTX()
  useChartTheme()

  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard/:id" element={<Dashboard />} />
            <Route path="/welcome" element={<></>} />
          </Route>
          <Route path="*" element={<Navigate to="/welcome" />} />
        </Routes>
        <Tour />
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default Views
