/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import { Button, Tag, Tooltip, Space, Typography } from 'antd'
import moment from 'moment'
import { TFunction } from 'react-i18next'
import { ThemeContext, useTheme } from 'styled-components'
import ContextGlobal from '../../../../context'
import { formatCurrency } from '../../../../Utilities/formatCurrency'
import { formatDecimal } from '../../../../Utilities/formatDecimal'
import { Actions, Icon, ContentActions } from './styles'

const { Text } = Typography

const NO_INFORMATION = '-'

export const columnsMaintenance = (
  canSeePrices = true,
  t: TFunction<'translate', undefined>
) => {
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.maintenance.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.maintenance.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.maintenance.date_create'),
      render: (token: any, record: any) => (
        <Text>{moment(record.created_at).format('DD/MM/YYYY')}</Text>
      ),
    },
    {
      title: t('modals.columns.maintenance.provider'),
      dataIndex: ['provider', 'name'],
    },
    {
      title: t('modals.columns.maintenance.plates'),
      dataIndex: ['client_car', 'plates'],
    },
    {
      title: t('modals.columns.maintenance.brand'),
      dataIndex: ['client_car', 'car', 'car_brand', 'name'],
    },
    {
      title: t('modals.columns.maintenance.model'),
      dataIndex: ['client_car', 'car', 'model'],
    },
    {
      title: t('modals.columns.maintenance.anio'),
      dataIndex: ['client_car', 'car', 'anio'],
    },
    {
      title: t('modals.columns.maintenance.mileaje'),
      dataIndex: ['client_car', 'current_km'],
      render: (val: number) => `${formatDecimal.format(val)} Km`,
    },
    {
      title: t('modals.columns.maintenance.state'),
      dataIndex: ['provider', 'estado'],
    },
    {
      title: t('modals.columns.maintenance.total'),
      dataIndex: 'total',
      render: (val: number) => (
        <Text>
          {canSeePrices
            ? formatCurrency(val).format()
            : t('modals.columns.not_avaliable')}
        </Text>
      ),
    },
    {
      title: t('modals.columns.maintenance.driver'),
      dataIndex: ['client_user', 'full_name'],
    },
    {
      title: t('modals.columns.maintenance.status_name'),
      dataIndex: 'status_name',
    },
    {
      title: '',
      dataIndex: 'folio',
      fixed: 'right',
      align: 'center',
      width: 55,
      render: (val: any) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            <Button
              href={`${baseUrl}/maintenance_service_requests/${val}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}

export const columnsManagement = (
  canSeePrices: boolean,
  t: TFunction<'translate', undefined>
) => {
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.management.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.management.client'),
      dataIndex: ['client_car', 'client_user', 'client', 'name'],
    },
    {
      title: t('modals.columns.management.appointment'),
      render: (token: any, record: any) => (
        <Text>{moment(record.created_at).format('DD/MM/YYYY HH:MM')}</Text>
      ),
    },
    {
      title: t('modals.columns.management.management_agency_name'),
      dataIndex: ['management_agency', 'name'],
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.management.management_agency_location'),
      dataIndex: ['management_agency', 'estado'],
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.management.plates'),
      dataIndex: ['client_car', 'plates'],
    },
    {
      title: t('modals.columns.management.id'),
      dataIndex: ['client_car', 'id'],
    },
    {
      title: t('modals.columns.management.total'),
      dataIndex: 'total',
      render: (val: number) => (
        <Text>
          {canSeePrices
            ? formatCurrency(val).format()
            : t('modals.columns.not_avaliable')}
        </Text>
      ),
    },
    {
      title: t('modals.columns.management.status_name'),
      dataIndex: 'status_name',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'folio',
      fixed: 'right',
      align: 'center',
      width: 55,
      render: (folio: string) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            <Button
              target="_blank"
              href={`${baseUrl}/management_service_requests/${folio}`}
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}

export const columnsFuel = (
  canSeePrices = true,
  t: TFunction<'translate', undefined>
) => {
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.fuel.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.fuel.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.fuel.plates'),
      dataIndex: ['client_car', 'plates'],
    },
    {
      title: t('modals.columns.fuel.mileaje'),
      dataIndex: ['client_car', 'current_km'],
      render: (val: number) => `${formatDecimal.format(val)} Km`,
    },
    {
      title: t('modals.columns.fuel.liters'),
      dataIndex: 'liters',
      render: (val: number) => `${formatDecimal.format(val)} L`,
    },
    {
      title: t('modals.columns.fuel.total_expense'),
      dateIndex: 'total_expense',
      render: (val: number) => (
        <Text>
          {canSeePrices
            ? formatCurrency(val).format()
            : t('modals.columns.not_avaliable')}
        </Text>
      ),
    },
    {
      title: t('modals.columns.fuel.driver'),
      dataIndex: ['client_user', 'full_name'],
      width: 155,
    },
    {
      title: '',
      dataIndex: 'token',
      fixed: 'right',
      align: 'center',
      width: 55,
      render: (token: any) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            <Button
              href={`${baseUrl}/fuel_consumption/show?i=${token}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}

export const columnsToll = (
  canSeePrices = true,
  t: TFunction<'translate', undefined>
) => {
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.toll.tag'),
      dataIndex: 'tag',
    },
    {
      title: t('modals.columns.toll.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.toll.plates'),
      dataIndex: 'client_car_plates',
    },
    {
      title: t('modals.columns.toll.create'),
      dataIndex: 'created_at',
      render: (val: string) => (
        <Text>{moment(val).format('DD/MM/YYYY hh:mm')}</Text>
      ),
    },
    {
      title: t('modals.columns.toll.crossing_date'),
      dataIndex: 'crossing_date',
      render: (val: string) => (
        <Text>{moment(val).format('DD/MM/YYYY hh:mm')}</Text>
      ),
    },
    {
      title: t('modals.columns.toll.stand_toll'),
      dataIndex: 'stand_toll',
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.toll.amount_bill'),
      dataIndex: 'amount_bill',
      render: (val: number) => (
        <Text>
          {canSeePrices
            ? formatCurrency(val).format()
            : t('modals.columns.not_avaliable')}
        </Text>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      fixed: 'right',
      align: 'center',
      width: 55,
      render: (token: any, record: any) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            <Button
              href={`${baseUrl}/service_tolls/show?i=${record.id}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}
export const columnsHolding = (
  canSeePrices = true,
  t: TFunction<'translate', undefined>
) => {
  return [
    {
      title: t('modals.columns.holding.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.holding.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.holding.plates'),
      dataIndex: ['client_car', 'plates'],
    },
    {
      title: t('modals.columns.holding.car'),
      render: (tags: any, record: any) => (
        <Text>
          {record.client_car?.car?.car_brand?.name || ''}{' '}
          {record.client_car?.car?.model || ''}{' '}
          {record.client_car?.car?.anio || ''}
        </Text>
      ),
    },
    {
      title: t('modals.columns.holding.exercise'),
      dataIndex: 'exercise',
    },
    {
      title: t('modals.columns.holding.create'),
      dataIndex: 'created_at',
      render: (val: string) => (
        <Text>{moment(val).format('DD/MM/YYYY hh:mm')}</Text>
      ),
    },
    {
      title: t('modals.columns.holding.status_pay'),
      dataIndex: 'status_pay',
      render: (val: boolean) => <Text>{val ? 'Pagada' : 'No pagada'}</Text>,
    },
    {
      title: t('modals.columns.holding.total'),
      dataIndex: 'total',
      render: (val: number) => (
        <Text>
          {canSeePrices
            ? formatCurrency(val).format()
            : t('modals.columns.not_avaliable')}
        </Text>
      ),
    },
  ]
}
export const columnsFine = (t: TFunction<'translate', undefined>) => {
  return [
    {
      title: t('modals.columns.fine.id'),
      dataIndex: 'id',
      width: 80,
    },
    {
      title: t('modals.columns.fine.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.fine.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.fine.date_start'),
      dataIndex: 'date_start',
      render: (val: string) => <Text>{moment(val).format('DD/MM/YYYY')}</Text>,
    },
    {
      title: t('modals.columns.fine.date_end'),
      dataIndex: 'date_end',
      render: (val: string) => <Text>{moment(val).format('DD/MM/YYYY')}</Text>,
    },
    {
      title: t('modals.columns.fine.police'),
      dataIndex: 'police',
    },
    {
      title: t('modals.columns.fine.car'),
      render: (tags: any, record: any) => (
        <Text>{record.client_car?.full_name || ''}</Text>
      ),
    },
    {
      title: t('modals.columns.fine.driver'),
      dataIndex: ['client_user', 'full_name'],
    },
  ]
}

export const columnsDelivery = (t: TFunction<'translate', undefined>) => {
  const getFuel = (fuel: any) => {
    if (fuel === 'one') return '1/8'
    else if (fuel === 'two') return '2/8'
    else if (fuel === 'three') return '3/8'
    else if (fuel === 'for') return '4/8'
    else if (fuel === 'five') return '5/8'
    else if (fuel === 'six') return '6/8'
    else if (fuel === 'seven') return '7/8'
    else if (fuel === 'eigth') return '8/8'
  }
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.delivery.folio'),
      dataIndex: 'folio',
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.delivery.client'),
      dataIndex: ['client_car', 'client_user', 'client', 'name'],
    },
    {
      title: t('modals.columns.delivery.status'),
      dataIndex: 'active',
      render: (val: boolean) => (val ? 'Activo' : 'Inactivo'),
    },
    {
      title: t('modals.columns.delivery.vehicle'),
      render: (tags: any, record: any) => (
        <p>
          {record.client_car?.car?.car_brand?.name || ''}{' '}
          {record.client_car?.car?.model || ''}{' '}
          {record.client_car?.car?.anio || ''}
        </p>
      ),
    },
    {
      title: t('modals.columns.delivery.fuel'),
      dataIndex: 'load_tank_fuel',
      render: (value: string) => <p>{getFuel(value)}</p>,
    },
    {
      title: t('modals.columns.delivery.person_charge'),
      dataIndex: 'person_charge',
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.delivery.date_start'),
      dataIndex: 'start_date',
      render: (val: string) =>
        val ? moment(val).format('DD/MM/YYYY') : NO_INFORMATION,
    },
    {
      title: t('modals.columns.delivery.date_end'),
      dataIndex: 'end_date',
      render: (val: string) =>
        val ? moment(val).format('DD/MM/YYYY') : NO_INFORMATION,
    },
    {
      title: '',
      dataIndex: 'actions',
      fixed: 'right',
      width: 80,
      render: (tags: any, record: any) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            {/* checar por que esta harcodeado el id */}
            <Button
              href={`${baseUrl}/client_cars/${record.client_car.token}/vehicular_deliveries/${record.id}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}

export const columnsVehicle = (t: TFunction<'translate', undefined>) => {
  const NOT_INFORMATION = '-'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useContext(ThemeContext)
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.vehicle.id'),
      dataIndex: ['id'],
      width: 100,
    },
    {
      title: t('modals.columns.vehicle.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.vehicle.number_economic'),
      dataIndex: ['number_economic'],
      render: (value: any) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.plates'),
      dataIndex: ['plates'],
    },
    {
      title: t('modals.columns.vehicle.plate_origin'),
      dataIndex: ['plate_origin'],
      render: (value: any) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.serial'),
      dataIndex: ['serial'],
      render: (value: any) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.vehicle'),
      dataIndex: ['car'],
      render: (value: any) =>
        `${value?.model || ''} ${value?.version || ''} ${value?.anio || ''}`,
    },
    {
      title: t('modals.columns.vehicle.driver'),
      dataIndex: ['client_user', 'user', 'info', 'full_name'],
      render: (value: string) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.user_company'),
      dataIndex: ['user_company', 'name'],
      render: (value: string) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.engine'),
      dataIndex: ['engine'],
      render: (value: string) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.type_fleet'),
      dataIndex: ['type_fleet', 'value'],
      render: (value: string) => value || NOT_INFORMATION,
    },
    {
      title: t('modals.columns.vehicle.status'),
      dataIndex: ['active'],
      render: (value: boolean) => (
        <Tag
          color={value ? theme.colors.green[6] : theme.colors.red[6]}
          style={{ borderRadius: '40px' }}
        >
          {value ? 'Activo' : 'Inactivo'}
        </Tag>
      ),
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (text: any, record: { token: string }) => (
        <Actions>
          <Tooltip placement="top" title={t('modals.columns.detail')}>
            <Button
              href={`${baseUrl}/client_cars/${record.token}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ]
}

export const columnsIncident = (t: TFunction<'translate', undefined>) => {
  const { baseUrl } = useContext(ContextGlobal)

  return [
    {
      title: t('modals.columns.incident.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.incident.client'),
      dataIndex: ['client', 'name'],
    },
    {
      title: t('modals.columns.incident.plates'),
      dataIndex: ['client_car', 'plates'],
    },
    {
      title: t('modals.columns.incident.vehicle'),
      render: (token: any, record: any) => (
        <Text>
          {record.client_car?.brand || ''} {record?.client_car?.model || ''}{' '}
          {record.client_car?.year || ''}
        </Text>
      ),
    },
    {
      title: t('modals.columns.incident.client_user'),
      dataIndex: ['client_user', 'full_name'],
      width: 200,
    },
    {
      title: t('modals.columns.incident.type_incident'),
      dataIndex: 'type_incident',
      width: 200,
    },
    {
      title: t('modals.columns.incident.comment'),
      dataIndex: 'comment',
      width: 200,
      render: (val: string) => val || NO_INFORMATION,
    },
    {
      title: t('modals.columns.incident.open_date'),
      width: 200,
      dataIndex: 'open_date',
      render: (val: string) => (
        <Text>{moment(val).format('DD/MM/YYYY HH:MM')}</Text>
      ),
    },
    {
      title: t('modals.columns.incident.next_follow'),
      width: 200,
      dataIndex: 'next_follow',
      render: (val: string) => (
        <Text>
          {val === undefined ? '' : moment(val).format('DD/MM/YYYY HH:MM')}
        </Text>
      ),
    },
    {
      title: '',
      dataIndex: 'token',
      width: 80,
      render: (token: string) => (
        <ContentActions>
          <Tooltip title={t('modals.columns.detail')}>
            <Button
              href={`${baseUrl}/vehicle_incidents_requests/${token}`}
              target="_blank"
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon className="fa-solid fa-eye" />}
            />
          </Tooltip>
        </ContentActions>
      ),
    },
  ]
}

export const columnsInsurances = (t: TFunction<'translate', undefined>) => {
  return [
    {
      title: t('modals.columns.insurances.id'),
      dataIndex: 'id',
      width: 80,
    },
    {
      title: t('modals.columns.insurances.folio'),
      dataIndex: 'folio',
    },
    {
      title: t('modals.columns.insurances.date_init'),
      dataIndex: 'date_start',
      render: (val: string) => <Text>{moment(val).format('DD/MM/YYYY')}</Text>,
    },
    {
      title: t('modals.columns.insurances.date_end'),
      dataIndex: 'date_end',
      render: (val: string) => <Text>{moment(val).format('DD/MM/YYYY')}</Text>,
    },
    {
      title: t('modals.columns.insurances.police'),
      dataIndex: 'police',
    },
    {
      title: t('modals.columns.insurances.car'),
      render: (tags: any, record: any) => (
        <Text>{record.client_car.full_name}</Text>
      ),
    },
    {
      title: t('modals.columns.insurances.driver'),
      dataIndex: ['client_car', 'client_user', 'full_name'],
      render: (val: string) => val || NO_INFORMATION,
    },
  ]
}

export const columnsVehicleLabels = (t: TFunction<'translate', undefined>) => {
  const { colors } = useTheme()
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tipo de registro',
      dataIndex: 'type_register',
      key: 'type_register',
      render: (token?: any) => token?.name || NO_INFORMATION,
    },
    {
      title: 'Económico',
      dataIndex: 'number_economic',
      key: 'number_economic',
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
    },
    {
      title: 'Placas',
      dataIndex: 'plates',
      key: 'plates',
    },
    {
      title: 'Etiqueta',
      dataIndex: 'client_car_status',
      key: 'client_car_status',
      render: (token: { color: string; name: string }) =>
        token?.name ? (
          <Tag color={`${colors[token?.color ?? 'primary'] || 'default'} `}>
            {token?.name}
          </Tag>
        ) : (
          NO_INFORMATION
        ),
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Fechas',
      dataIndex: 'dates',
      width: 220,
      render: (
        token: any,
        {
          start_date,
          commitment_date,
          end_date,
        }: { start_date?: string; commitment_date?: string; end_date?: string }
      ) => (
        <Space direction="vertical" size={1} style={{ display: 'flex' }}>
          <Text>
            <Text strong>Fecha de Inicio: </Text>
            {start_date
              ? moment(start_date).format('DD/MM/YYYY')
              : NO_INFORMATION}
          </Text>
          <Text>
            <Text strong>Fecha compromiso: </Text>
            {commitment_date
              ? moment(commitment_date).format('DD/MM/YYYY')
              : NO_INFORMATION}
          </Text>
          <Text>
            <Text strong>Fecha fin: </Text>
            {end_date ? moment(end_date).format('DD/MM/YYYY') : NO_INFORMATION}
          </Text>
        </Space>
      ),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Datos financieros',
      dataIndex: 'financial_data',
      key: 'financial_data',
      width: 450,
      render: (token: any) => (
        <Space direction="vertical" size={1} style={{ display: 'flex' }}>
          {token?.cost_sale_before_taxes && (
            <Text>
              <Text strong>Costo de venta antes de impuestos: </Text>
              {formatCurrency(
                +token.cost_sale_before_taxes,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.installation_cost && (
            <Text>
              <Text strong>Costo de instalación: </Text>
              {formatCurrency(
                +token.installation_cost,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.equipment_cost_before_taxes && (
            <Text>
              <Text strong>Costo del equipo antes impuesto: </Text>
              {formatCurrency(
                +token.equipment_cost_before_taxes,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.cost_before_taxes && (
            <Text>
              <Text strong>Costo antes de impuestos:</Text>
              {formatCurrency(
                token.cost_before_taxes,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.discount && (
            <Text>
              <Text strong>Descuento: </Text>
              {formatCurrency(
                +token.discount,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.taxes && (
            <Text>
              <Text strong>Impuestos: </Text>
              {formatCurrency(
                +token.taxes,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
          {token?.total && (
            <Text>
              <Text strong>Total: </Text>
              {formatCurrency(
                +token.total,
                token.currency?.symbol || '$'
              ).format()}{' '}
              {token.currency?.code || ''}
            </Text>
          )}
        </Space>
      ),
    },
    {
      title: 'Proveedor',
      dataIndex: 'provider',
      key: 'provider',
      render: (token?: string) => token || NO_INFORMATION,
    },
    {
      title: 'Ubicación de proveedor',
      dataIndex: 'provider_location',
      key: 'provider_location',
      render: (token?: string) => token || NO_INFORMATION,
    },
    {
      title: 'Ubicación de auto',
      dataIndex: 'car_location',
      key: 'car_location',
      render: (token?: string) => token || NO_INFORMATION,
    },
  ]
}
