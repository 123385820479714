import React, { useMemo } from 'react'
import { Typography, Form, Empty, Spin, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import Exclamation from '../../../Assets/Images/exclamation-circle-solid.png'
import { FormValidationError, WidgetBase } from '../../../Types'
import {
  SelectableColumnItem,
  WidgetWrapper,
  TextSpace,
  ListTitle,
  ErrorListWrapper,
  EmptyWrapper,
  LoadingWrapper,
  Icon,
} from './styles'

const { Text, Paragraph } = Typography
const { Item, ErrorList } = Form

interface Props {
  onWidgetSelect(service: string): void
  selectedWidget: string
  selectedService: string
  errors: FormValidationError[]
  widgets: WidgetBase[]
  loading: boolean
}

const WidgetList: React.FC<Props> = ({
  onWidgetSelect,
  selectedWidget,
  errors,
  widgets,
  selectedService,
  loading,
}) => {
  const { t } = useTranslation('translate')

  const widgetListT = 'views.main.new-widget.widget-list'
  const widgetErrors = useMemo(
    () => errors.find((item) => item.name[0] === 'widget')?.errors,
    [errors]
  )

  const getIcon = (widgetType: string, activeDashboard?: boolean) => {
    if (widgetType === 'graph') {
      return (
        <Icon
          className="fa-solid fa-chart-column"
          activeDashboard={activeDashboard}
        />
      )
    } else if (widgetType === 'card') {
      return (
        <Icon
          className="fa-solid fa-money-check-dollar"
          activeDashboard={activeDashboard}
        />
      )
    } else if (widgetType === 'status_card') {
      return (
        <Icon
          className="fa-solid fa-clipboard-list"
          activeDashboard={activeDashboard}
        />
      )
    } else if (widgetType === 'percentage_chart') {
      return (
        <Icon
          className="fa-solid fa-chart-pie"
          activeDashboard={activeDashboard}
        />
      )
    } else if (widgetType === 'report_table') {
      return (
        <Icon className="fa-solid fa-table" activeDashboard={activeDashboard} />
      )
    } else {
      return (
        <Icon
          className={'fa-solid fa-braille'}
          activeDashboard={activeDashboard}
        />
      )
    }
  }
  const getColumn = (
    widgetID: string,
    var_name: string,
    widget_type: string,
    activeDashboard?: boolean,
    id?: number
  ) => {
    return (
      <SelectableColumnItem
        selected={widgetID === selectedWidget}
        onClick={() => onWidgetSelect(widgetID)}
        activeDashboard={activeDashboard}
        key={id}
      >
        {getIcon(widget_type, activeDashboard)}
        <TextSpace>
          <Text strong>{t(`widgets.${var_name}.name`)}</Text>
          <Paragraph ellipsis={{ rows: 2 }}>
            {t(`widgets.${var_name}.description`)}
          </Paragraph>
        </TextSpace>
      </SelectableColumnItem>
    )
  }
  const confirm = (widgetID: string) => {
    onWidgetSelect(widgetID)
  }
  return (
    <Item
      name="widget"
      rules={[
        {
          required: true,
          message: t(`${widgetListT}.form.rules.widget.required`),
        },
      ]}
      noStyle
    >
      <div>
        <ListTitle strong>{t(`${widgetListT}.list-title`)}:</ListTitle>
        <ErrorListWrapper>
          <ErrorList errors={widgetErrors} />
        </ErrorListWrapper>
        {/* <ListSearch placeholder={t('search')} /> */}

        {loading ? (
          <LoadingWrapper>
            <Spin spinning />
          </LoadingWrapper>
        ) : (
          <>
            <WidgetWrapper>
              {widgets.map(({ id, var_name, widget_type, activeDashboard }) => {
                const widgetID = String(id)

                if (activeDashboard) {
                  return (
                    <Popconfirm
                      placement="top"
                      title={t('views.main.new-widget.widget-duplicate')}
                      onConfirm={() => confirm(widgetID)}
                      okText="Si"
                      cancelText="No"
                      key={id}
                      disabled={widgetID === selectedWidget}
                    >
                      <SelectableColumnItem
                        selected={widgetID === selectedWidget}
                        activeDashboard={activeDashboard}
                      >
                        {getIcon(widget_type, activeDashboard)}
                        <TextSpace>
                          <Text strong>{t(`widgets.${var_name}.name`)}</Text>
                          <Paragraph ellipsis={{ rows: 2 }}>
                            {t(`widgets.${var_name}.description`)}
                          </Paragraph>
                        </TextSpace>
                      </SelectableColumnItem>
                    </Popconfirm>
                  )
                } else {
                  return getColumn(
                    widgetID,
                    var_name,
                    widget_type,
                    activeDashboard,
                    id
                  )
                }
              })}
              {!widgets.length && (
                <EmptyWrapper>
                  <Empty
                    image={selectedService ? undefined : Exclamation}
                    description={
                      selectedService
                        ? t(`${widgetListT}.form.no-widgets-available`)
                        : t(`${widgetListT}.form.select-a-service`)
                    }
                  />
                </EmptyWrapper>
              )}
            </WidgetWrapper>
          </>
        )}
      </div>
    </Item>
  )
}

export default WidgetList
