import Tour from 'reactour'
import styled from 'styled-components'

export const TourWidget = styled(Tour)`
  .reactour__close {
    top: 16px;
    right: 16px;
    color: ${({ theme }) => theme.colors.textColor};
  }
  background-color: ${({ theme }) => theme.colors.componentBackground};
  /* border: 1px solid ${({ theme }) => theme.borderColor}; */
`
