import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Button, Table, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Widget as IWidget, KeyValue } from '../../../../Types'
import Context from '../../context'
import { Card, ContentTitle, Title as TitleCard, Content } from './styles'
import Filters from './filters'

interface Props {
  config: IWidget
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const Report: React.FC<Props> = ({ config, handleDeleteWidget }) => {
  const { widget, id } = config
  const { name, end_point, var_name, download_endpoint } = widget
  const [params, setParams] = useState<KeyValue>({})

  const { t } = useTranslation('translate')

  // const [data, setData] = useState<DataType[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const [tableHeight, setTableHeight] = useState<number>(0)

  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const { paramsGeneral, newWidgetCreate } = useContext(Context)

  const parentRef = useRef<HTMLDivElement>(null)
  const [data, setData] = useState<any>({})
  const changeSize = () => {
    const parentHeight = parentRef.current?.clientHeight
    setTableHeight(parentHeight ?? 0)
  }

  useEffect(() => {
    changeSize()
  }, [parentRef.current?.clientHeight])

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${end_point}`, {
        params: {
          ...paramsGeneral,
          ...params,
        },
      })
      setData(res.data)
    } catch (e) {
      message.error('error')
    } finally {
      setLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_point, var_name, paramsGeneral, params])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const downloadInfo = async () => {
    try {
      setLoader(true)
      const res = await axios.get(end_point, {
        params: {
          ...paramsGeneral,
          ...params,
          download: true,
        },
        responseType: 'blob',
      })
      if (res.data.type === 'application/json') {
        message.info(
          'Descarga en segundo plano solicitada. Verifique el resultado en IAN'
        )
      } else {
        var FileDownload = require('js-file-download')
        FileDownload(
          res.data,
          `${t(`widgets.${var_name}.name`)}-${moment().format('L')}.xlsx`
        )
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  return (
    <Card
      id={`widget_id_${var_name}_card`}
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      title={
        <ContentTitle>
          <TitleCard>{name}</TitleCard>
        </ContentTitle>
      }
      extra={
        <>
          {download_endpoint && (
            <Button
              disabled={loader}
              type="primary"
              shape="circle"
              icon={<i className="fa-solid fa-download" />}
              onClick={downloadInfo}
            />
          )}
          <Button
            type="primary"
            danger
            shape="circle"
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      shadow={+(newWidgetCreate === id)}
    >
      <Content ref={parentRef}>
        <Filters
          params={params}
          setParams={setParams}
          filters={data.filters || []}
        />
        <Table
          rowKey={'key'}
          columns={data?.columns || []}
          dataSource={data?.data || []}
          pagination={false}
          scroll={{
            y: tableHeight - 200,
          }}
          loading={loader}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {data?.columns?.map((el: any, index: number) => (
                  <Table.Summary.Cell index={+el + 1} key={index}>
                    {data.total?.[el.dataIndex]}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Content>
    </Card>
  )
}

export default Report
