/* eslint-disable import/named */
import { Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../Components'
import {
  DashboardIcon,
  NewDashboardItem,
  DASHBOARD_ICONS,
} from '../../../Types'
import { IconBox, IconsGrid, EmptyItem } from './styles'

const { Item } = Form

interface Props {
  form: FormInstance<NewDashboardItem>
  initialValues: NewDashboardItem
  selectedIcon: DashboardIcon
  onIconSelect(icon: DashboardIcon): void
}

const NewDBForm: React.FC<Props> = ({
  form,
  initialValues,
  selectedIcon,
  onIconSelect,
}) => {
  const { t } = useTranslation('translate', { keyPrefix: 'new-dashboard' })

  return (
    <Form
      form={form}
      layout="vertical"
      name="new_dashboard_form"
      initialValues={initialValues}
      preserve={false}
      scrollToFirstError
    >
      <Item
        name="name"
        label={t('form.name.text')}
        rules={[
          {
            required: true,
            message: t('form.name.message'),
          },
        ]}
      >
        <Input />
      </Item>
      <EmptyItem
        name="icon"
        label={t('form.icon.text')}
        rules={[
          {
            required: true,
            message: t('form.icon.message'),
          },
        ]}
      >
        <Input style={{ display: 'none' }} />
      </EmptyItem>
      <IconsGrid>
        {DASHBOARD_ICONS.map((icon) => (
          <IconBox
            key={icon}
            selected={icon === selectedIcon}
            onClick={() => onIconSelect(icon)}
          >
            <Icon className={icon} />
          </IconBox>
        ))}
      </IconsGrid>
    </Form>
  )
}

export default NewDBForm
