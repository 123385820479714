import React from 'react'
import Form from './Form'
import { Card } from './styles'

const Filters = () => {
  return (
    <Card
      bordered={false}
      style={{ background: 'transparent' }}
      data-tut="reactour__filter_general"
    >
      <Form drawer={false} />
    </Card>
  )
}

export default Filters
