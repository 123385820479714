/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Typography, message, Spin, Empty } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { Layouts } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { KeyValue, Widget as IWidget } from '../../../../Types'
import Context from '../../context'
import DataDisplay from './DataDisplay'
import Filters from './Filters'
import { BodyWrapper, Card, SeeTable, ContentEmpty } from './styles'
import TableUI from './Table'
interface Props {
  config: IWidget
  layouts: Layouts
  setLayouts: Dispatch<SetStateAction<Layouts>>
  handleDeleteWidget(var_name: string, name: string, id: number): void
}

const { Link } = Typography
const Chart: React.FC<Props> = ({
  config: propsConfig,
  setLayouts,
  layouts,
  handleDeleteWidget,
}) => {
  const { t } = useTranslation('translate')
  const theme = useContext(ThemeContext)
  const { paramsGeneral, setInfoWidget, newWidgetCreate, setNewWidgetCreate } =
    useContext(Context)

  // TODO: Properly type config object
  const { widget, id, open_table, group_by_default, graph_default } =
    propsConfig
  const {
    name,
    widget_groups = [],
    var_name,
    end_point,
    download_endpoint,
    date_parameter,
    status_msr,
    id: idWdiget,
  } = widget
  const [chartHeight, setChartHeight] = useState<number>(0)
  // TODO: Properly type params object
  const [typeGraph, setTypeGraph] = useState<string>(graph_default || 'column')
  const [loader, setLoader] = useState<boolean>(false)
  const [params, setParams] = useState<KeyValue>({})
  const [typeGraphActive, setTypeGraphActive] = useState<string>('agruped')
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [totalColumns, setTotalColumns] = useState([])

  const [disabledOpenTable, setDisabledOpenTable] = useState(false)
  const [onlyMoneyGroup, setOnlyMoneyGroup] = useState(true)
  const [seriesGroup, setSeriesGroup] = useState(false)
  const [dataChart, setDataChart] = useState({})
  const [openTable, setOpenTable] = useState<boolean>(open_table || false)
  const [downloadTable, setDownloadTable] = useState<boolean>(false)
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false)

  const parentRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement>(null)

  const cardElement = document?.getElementById(`widget_id_${var_name}_card`)
  const cardHeaderHeight = cardElement?.firstElementChild?.clientHeight || 0

  const changeSize = () => {
    const parentHeight = parentRef.current?.clientHeight || 0
    const filtersHeight = filtersRef.current?.clientHeight || 140
    const tableHeight = tableRef.current?.clientHeight || 0
    const tableH = tableHeight > 460 ? 460 : tableHeight
    const height = parentHeight - filtersHeight - 12 - tableH
    setChartHeight(height)
  }
  useEffect(() => {
    changeSize()
  }, [
    parentRef.current?.clientHeight,
    filtersRef.current?.clientHeight,
    tableRef.current?.clientHeight,
  ])

  const [groupsWidget, setGroupsWidget] = useState<any>([])

  const getWidgetGroups = async () => {
    try {
      const res = await axios.get(`/ala/widget_groups?widget_id=${idWdiget}`)
      setGroupsWidget(res.data)
      const widgetGroupData: any = res.data
      // let serie = false

      // if (widgetGroupData.length > 0) {
      //   let group = widgetGroupData.filter(
      //     (el: any) => el.var_name === params.group_by
      //   )

      //   if (group.length > 0) {
      //     setOnlyMoneyGroup(group[0].only_money || false)
      //     serie = group[0].series || false

      //     setSeriesGroup(group[0].series || false)
      //   } else {
      //     setOnlyMoneyGroup(true)
      //   }
      // }

      widgetGroupData.forEach((el: any) => {
        if (el.var_name === group_by_default) {
          setTypeGraph(el.graph_default)
        }
      })
      const indexGroupBy = widgetGroupData.findIndex(
        (el: any) => el.var_name === group_by_default
      )

      setParams({
        ...params,
        group_by: group_by_default
          ? group_by_default
          : widgetGroupData[indexGroupBy === -1 ? 0 : indexGroupBy]?.var_name
          ? widgetGroupData[indexGroupBy === -1 ? 0 : indexGroupBy]?.var_name
          : '',
        frequency:
          widgetGroupData.length >= 1
            ? widgetGroupData[indexGroupBy === -1 ? 0 : indexGroupBy]
                ?.frequency?.[0]
            : undefined,
        filter_range_date: date_parameter ? 'date_close_provider' : undefined,
        status_msr: status_msr ? 'total' : undefined,
      })
    } catch (error) {}
  }

  useEffect(() => {
    getWidgetGroups()
  }, [])
  // useEffect(() => {
  //   widget_groups.forEach((el) => {
  //     if (el.var_name === group_by_default) {
  //       setTypeGraph(el.graph_default)
  //     }
  //   })
  // }, [])

  // useEffect(() => {
  //   const indexGroupBy = widget_groups.findIndex(
  //     (el) => el.var_name === group_by_default
  //   )

  //   setParams({
  //     ...params,
  //     group_by: group_by_default
  //       ? group_by_default
  //       : widget_groups[indexGroupBy === -1 ? 0 : indexGroupBy]?.var_name
  //       ? widget_groups[indexGroupBy === -1 ? 0 : indexGroupBy]?.var_name
  //       : '',
  //     frequency:
  //       widget_groups.length >= 1
  //         ? widget_groups[indexGroupBy === -1 ? 0 : indexGroupBy]
  //             ?.frequency?.[0]
  //         : undefined,
  //     filter_range_date: date_parameter ? 'date_close_provider' : undefined,
  //     status_msr: status_msr ? 'total' : undefined,
  //   })
  // }, [])

  const openTableWidget = async (newLayouts: Layouts) => {
    setDisabledOpenTable(true)
    try {
      await axios.patch(`/ala/dashboard_widgets/${id}`, {
        open_table: !openTable,
      })
      setOpenTable(!openTable)
      setLayouts(newLayouts)
    } catch {
      return
    } finally {
      setDisabledOpenTable(false)
    }
  }

  const getDataLayout = (Data: { h: number; i: string }[]) => {
    const newData = Data.map((el) => {
      if (el.i === String(id)) {
        return {
          ...el,
          h: openTable ? el.h - 6 : el.h + 6,
          isResizable: openTable ? true : false,
        }
      }
      return el
    })
    return newData
  }
  const handleOpenTable = () => {
    let newLayouts = {}

    if (layouts.lg) {
      const lg = getDataLayout(layouts.lg)
      newLayouts = { ...newLayouts, lg }
    }
    if (layouts.md) {
      const md = getDataLayout(layouts.md)
      newLayouts = { ...newLayouts, md }
    }
    if (layouts.sm) {
      const sm = getDataLayout(layouts.sm)
      newLayouts = { ...newLayouts, sm }
    }
    if (layouts.xs) {
      const xs = getDataLayout(layouts.xs)
      newLayouts = { ...newLayouts, xs }
    }
    if (layouts.xxs) {
      const xxs = getDataLayout(layouts.xxs)
      newLayouts = { ...newLayouts, xxs }
    }

    openTableWidget(newLayouts)
  }

  const downloandInfo = async () => {
    setDownloadLoader(true)
    try {
      setLoader(true)
      if (download_endpoint) {
        const res = await axios.get(end_point, {
          params: {
            ...paramsGeneral,
            ...params,
            download: true,
          },
          responseType: 'blob',
        })
        var FileDownload = require('js-file-download')
        FileDownload(
          res.data,
          `${t(`widgets.${var_name}.name`)}-${moment().format('L')}.xlsx`
        )
      }
    } catch (e) {
      message.error(
        `Ocurrió un error al descargar un archivo, intente nuevamente ${t(
          `widgets.${var_name}.name`
        )}`
      )
    } finally {
      setDownloadTable(true)
      setLoader(false)
      setDownloadLoader(false)
    }
  }
  const getGraphic = useCallback(async () => {
    let serie = false

    // if (widget_groups.length > 0) {
    //   let group = widget_groups.filter((el) => el.var_name === params.group_by)

    //   if (group.length > 0) {
    //     setOnlyMoneyGroup(group[0].only_money || false)
    //     serie = group[0].series || false

    //     setSeriesGroup(group[0].series || false)
    //   } else {
    //     setOnlyMoneyGroup(true)
    //   }
    // }
    if (groupsWidget.length > 0) {
      let group = groupsWidget.filter(
        (el: any) => el.var_name === params.group_by
      )

      if (group.length > 0) {
        setOnlyMoneyGroup(group[0]?.only_money || false)
        serie = group[0]?.series || false

        setSeriesGroup(group[0]?.series || false)
      } else {
        setOnlyMoneyGroup(true)
      }
    }

    try {
      setLoader(true)
      const res = await axios.get(end_point, {
        params: {
          ...paramsGeneral,
          ...params,
        },
      })

      setDataChart(res.data)
      if (res.data.chart) setData(res.data.chart)
      else setData(res.data)

      if (serie) {
        if (res.data.total_columns) setTotalColumns(res.data.total_columns)
        else setTotalColumns([])
      } else {
        if (res.data.total) setTotal(res.data.total)
      }

      setOpenTable(openTable || false)
    } catch (e) {
      setData([])
      message.error(
        `${t('components.common.request_error_graph')} ${t(
          `widgets.${var_name}.name`
        )}`
      )

      // if (axios.isAxiosError(e)) {
      //   const messageError = e.response?.data.message;
      //   console.log(Object.values(messageError));
      // }
    } finally {
      setLoader(false)
    }
  }, [params, paramsGeneral])

  useEffect(() => {
    if (params.group_by) {
      getGraphic()
    }
  }, [getGraphic, params])

  const openInfo = () => {
    setInfoWidget({ active: true, varName: var_name })
  }

  useEffect(() => {
    if (newWidgetCreate !== 0) {
      setTimeout(() => {
        setNewWidgetCreate(0)
      }, 4000)
    }
  }, [newWidgetCreate])

  return (
    <Card
      title={t(`widgets.${var_name}.name`)}
      // title={name}
      extra={
        <>
          <Button
            disabled={loader}
            type="primary"
            shape="circle"
            icon={
              downloadLoader ? (
                <LoadingOutlined />
              ) : (
                <i className="fa-solid fa-download" />
              )
            }
            onClick={() => downloandInfo()}
          />
          <Button
            type="primary"
            shape="circle"
            style={{ marginLeft: '5px' }}
            icon={<i className="fa-solid fa-info" />}
            onClick={() => openInfo()}
          />
          <Button
            danger
            type="primary"
            shape="circle"
            icon={<i className="fa-regular fa-trash-can" />}
            onClick={() => handleDeleteWidget(var_name, name, id)}
            style={{ marginLeft: '5px' }}
          />
        </>
      }
      // Feels more intuitive than passing value through styled props and then targetting a class.
      bodyStyle={{ height: `calc(100% - ${cardHeaderHeight}px)` }}
      id={`widget_id_${var_name}_card`}
      shadow={+(newWidgetCreate === id)}
    >
      <BodyWrapper ref={parentRef}>
        <div ref={filtersRef}>
          <Filters
            loader={loader}
            typeGraph={typeGraph}
            setTypeGraph={setTypeGraph}
            services={groupsWidget}
            params={params}
            setParams={setParams}
            typeGraphActive={typeGraphActive}
            setTypeGraphActive={setTypeGraphActive}
            varName={var_name}
            idWidget={id}
            setOnlyMoneyGroup={setOnlyMoneyGroup}
            dateParameter={date_parameter}
            statusMsr={status_msr}
          />
        </div>

        {/* Parents of Antd charts MUST have a SET height, otherwise y-axis responsiveness won't work as of @ant-design/charts v1.2.13 */}
        <Spin tip={t('components.common.loading_graph')} spinning={loader}>
          <div
            // 24 refers to the hardcoded padding value for ant desing's card component
            style={{
              height: chartHeight - 24,
            }}
          >
            {!data.length && (
              <ContentEmpty>
                <Empty />
              </ContentEmpty>
            )}
            {data.length > 0 && (
              <DataDisplay
                typeGraph={typeGraph}
                data={data}
                params={params}
                typeGraphActive={typeGraphActive}
                varName={var_name}
                onlyMoneyGroup={onlyMoneyGroup}
                dataChart={dataChart}
              />
            )}
          </div>
        </Spin>
        <SeeTable>
          <Link
            onClick={handleOpenTable}
            disabled={loader || disabledOpenTable}
          >
            {t('components.common.see_table')}{' '}
            <i
              className="fa-solid fa-angle-down"
              style={{
                transform: openTable ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: `${theme.transitions.durations.normal}s}`,
              }}
            />
          </Link>
        </SeeTable>
        <div ref={tableRef}>
          <TableUI
            openTable={openTable}
            dataChart={dataChart}
            name={name}
            params={params}
            varName={var_name}
            data={data}
            seriesWidget={seriesGroup}
            loader={loader}
            total={total}
            onlyMoneyGroup={onlyMoneyGroup}
            totalColumns={totalColumns}
            typeGraph={typeGraph}
            downloadTable={downloadTable}
            setDownloadTable={setDownloadTable}
          />
        </div>
      </BodyWrapper>
    </Card>
  )
}

export default Chart
