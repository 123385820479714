import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Button, Divider, Drawer, Form, message, Result } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import axios from 'axios'
import { useMediaQuery } from 'beautiful-react-hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { Layouts } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  FormValidationError,
  NewWidgetForm,
  Service,
  WidgetBase,
  Widget,
} from '../../../Types'
import Context from '../context'
import {
  CARDLAYOUT,
  GRAPHLAYOUT,
  MAPLAYOUT,
  STATUSCARD,
  PERCENTAGECHART,
  REPORTCARD,
  REPORTTABLECARD,
} from './ConfigWidgets'
import ServiceList from './ServiceList'
import { FooterButtons, ResultWrapper } from './styles'
import WidgetList from './WidgetList'
const INITIAL_VALUES: NewWidgetForm = {
  service: '',
  widget: '',
}

interface Layout {
  w: number
  h: number
  x: number
  y: number
  minH: number
  minW: number
}
interface Props {
  visible: boolean
  setDrawerVisible: Dispatch<SetStateAction<boolean>>
  setDashboardWidgets: Dispatch<SetStateAction<Widget[]>>
  layouts: Layouts
  setLayouts: Dispatch<SetStateAction<Layouts>>
  listWidgets: Widget[]
}

const NewWidget: React.FC<Props> = ({
  visible,
  setDrawerVisible,
  setDashboardWidgets,
  layouts,
  setLayouts,
  listWidgets,
}) => {
  const { setNewWidgetCreate } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [loadingWidgets, setLoadingWidgets] = useState(false)
  const [loadingServices, setLoadingServices] = useState(false)
  const [services, setServices] = useState<Service[]>([])
  const [widgets, setWidgets] = useState<WidgetBase[]>([])
  const [selectedService, setSelectedService] = useState('')
  const [selectedWidget, setSelectedWiget] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [errors, setErrors] = useState<FormValidationError[]>([])
  const [form] = useForm<NewWidgetForm>()
  const { t } = useTranslation('translate')
  const newWidget = 'views.main.new-widget'
  const mobile = useMediaQuery('(max-width: 640px)')
  const tablet = useMediaQuery('(max-width: 1023px)')
  const laptop = useMediaQuery('(max-width: 1439px)')
  const laptopL = useMediaQuery('(max-width: 1980px)')
  const { transitions } = useTheme()
  const { id } = useParams()

  useEffect(() => {
    const getServiceModules = async () => {
      setLoadingServices(true)
      try {
        const { data } = await axios.get('/ala/service_modules?options=alable')
        setServices(data)
      } catch (e) {
        message.error(t(`${newWidget}.request-error`))
      } finally {
        setLoadingServices(false)
      }
    }
    getServiceModules()
  }, [t])

  useEffect(() => {
    const getServiceWidgets = async () => {
      setLoadingWidgets(true)
      try {
        const { data } = await axios.get(
          `/ala/widgets?service_module_id=${selectedService}`
        )
        const NewLists = data.map((el: WidgetBase) => {
          if (listWidgets.some((i) => i.widget.id === el.id)) {
            return {
              ...el,
              activeDashboard: true,
            }
          }
          return {
            ...el,
            activeDashboard: false,
          }
        })
        setWidgets(NewLists)
      } catch (e) {
        message.error(t(`${newWidget}.request-error`))
      } finally {
        setLoadingWidgets(false)
      }
    }

    if (services.length && selectedService) {
      getServiceWidgets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, selectedService, t])

  const cleanupSelectedItems = () => {
    setSelectedWiget('')
    setSelectedService('')
    setWidgets([])
    form.resetFields()
  }

  const handleCloseDrawer = () => {
    setDrawerVisible(false)
    cleanupSelectedItems()
    setShowSuccess(false)
  }

  const changeLayout = (
    newLayout: Layouts,
    layout: Layout,
    idWidget: string
  ) => {
    newLayout.lg = [...newLayout.lg, { ...layout, i: idWidget }]
    newLayout.md = [...newLayout?.md, { ...layout, i: idWidget }]
    newLayout.sm = [...newLayout?.sm, { ...layout, i: idWidget }]
    newLayout.xs = [...newLayout?.xs, { ...layout, i: idWidget }]
    newLayout.xxs = [...newLayout?.xxs, { ...layout, i: idWidget }]
    return newLayout
  }
  const saveLayout = async (data: Widget) => {
    let newLayouts: Layouts = { ...layouts }
    if (data.widget.widget_type === 'card') {
      newLayouts = changeLayout(newLayouts, CARDLAYOUT, String(data.id))
    } else if (data.widget.widget_type === 'graph') {
      newLayouts = changeLayout(newLayouts, GRAPHLAYOUT, String(data.id))
    } else if (data.widget.widget_type === 'geographic') {
      newLayouts = changeLayout(newLayouts, MAPLAYOUT, String(data.id))
    } else if (data.widget.widget_type === 'status_card') {
      newLayouts = changeLayout(newLayouts, STATUSCARD, String(data.id))
    } else if (data.widget.widget_type === 'percentage_chart') {
      newLayouts = changeLayout(newLayouts, PERCENTAGECHART, String(data.id))
    } else if (data.widget.widget_type === 'report') {
      newLayouts = changeLayout(newLayouts, REPORTCARD, String(data.id))
    } else if (data.widget.widget_type === 'report_table') {
      newLayouts = changeLayout(newLayouts, REPORTTABLECARD, String(data.id))
    }
    setLayouts(newLayouts)
  }

  const handleAddWidget = async (values: NewWidgetForm) => {
    try {
      setLoading(true)
      const {
        data: { data, status },
      } = await axios.post('/ala/dashboard_widgets', {
        widget_id: +values.widget,
        user_dashboard_id: id,
      })
      if (status === 'success') {
        cleanupSelectedItems()
        setShowSuccess(true)
        setDashboardWidgets((dW) => [...dW, data])

        setNewWidgetCreate(data.id)
        saveLayout(data)
      } else {
        return
      }
    } catch (e) {
      message.error(t(`${newWidget}.request-error`))
    } finally {
      setLoading(false)
    }
  }

  const onFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleAddWidget(values)
      })
      .catch((info) => {
        if ('errorFields' in info) {
          setErrors(info.errorFields)
        }
      })
  }

  const onServiceSelect = (service: string) => {
    setSelectedService(service)
    form.setFieldsValue({ service })
    setErrors((e) => e.filter((i) => i.name[0] !== 'service'))
  }

  const onWidgetSelect = (widget: string) => {
    setSelectedWiget(widget)
    form.setFieldsValue({ widget })
    setErrors((e) => e.filter((i) => i.name[0] !== 'widget'))
  }

  return (
    <Drawer
      data-tut="reactour__createwidget--observe"
      placement="right"
      open={visible}
      onClose={handleCloseDrawer}
      width={
        mobile
          ? '80%'
          : tablet
          ? '70%'
          : laptop
          ? '50%'
          : laptopL
          ? '40%'
          : '30%'
      }
      title={t(`${newWidget}.add-new`)}
      footer={
        !showSuccess && (
          <FooterButtons>
            <Button onClick={handleCloseDrawer}>
              {t(`${newWidget}.cancel-btn`)}
            </Button>
            <Button type="primary" loading={loading} onClick={onFormSubmit}>
              {t(`${newWidget}.add-btn`)}
            </Button>
          </FooterButtons>
        )
      }
      // bodyStyle={{ overflow: 'hidden' }}
    >
      <AnimatePresence initial={false}>
        {showSuccess ? (
          <ResultWrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { delay: transitions.durations.short },
            }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: { duration: transitions.durations.normal },
            }}
          >
            <Result
              status="success"
              title={t(`${newWidget}.success-msg.title`)}
              subTitle={t(`${newWidget}.success-msg.subtitle`)}
              extra={[
                <Button key="close" onClick={handleCloseDrawer}>
                  {t(`${newWidget}.success-msg.close-btn`)}
                </Button>,
                <Button
                  type="primary"
                  key="create-another"
                  onClick={() => setShowSuccess(false)}
                >
                  {t(`${newWidget}.success-msg.add-another-btn`)}
                </Button>,
              ]}
            />
          </ResultWrapper>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { delay: transitions.durations.short },
            }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: { duration: transitions.durations.normal },
            }}
          >
            <Form form={form} initialValues={INITIAL_VALUES}>
              <ServiceList
                errors={errors}
                onServiceSelect={onServiceSelect}
                selectedService={selectedService}
                services={services}
                loading={loadingServices}
              />
              <Divider />
              <WidgetList
                widgets={widgets}
                errors={errors}
                onWidgetSelect={onWidgetSelect}
                selectedWidget={selectedWidget}
                selectedService={selectedService}
                loading={loadingWidgets}
              />
            </Form>
          </motion.div>
        )}
      </AnimatePresence>
    </Drawer>
  )
}

export default NewWidget
