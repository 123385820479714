import { Input, Typography } from 'antd'
import { motion } from 'framer-motion'
import styled from 'styled-components'

interface IconProps {
  activeDashboard?: boolean
}

export const Icon = styled.i<IconProps>`
  text-align: center !important;

  color: ${({ activeDashboard, theme }) =>
    activeDashboard && theme.colors.primary[5]};
`

const { Search } = Input
const { Text } = Typography

export const ServiceCardWrapper = styled.div`
  display: grid;
  padding: 1rem 0;
  overflow-x: auto;
  gap: 1rem;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  /* padding: 0 1rem 0 0; */
  overflow-y: hidden;
  gap: 1rem;
  /* height: 50vh; */
`

interface SelectableItemProps {
  selected?: boolean
  vertical?: boolean
  type?: string
  activeDashboard?: boolean
}

export const SelectableItem = styled.div<SelectableItemProps>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  justify-content: ${({ vertical }) => (vertical ? 'center' : 'flex-start')};
  align-items: center;
  padding: 1rem;
  border: 1px solid
    ${({ theme, activeDashboard }) =>
      activeDashboard ? theme.colors.primary[5] : theme.colors.componentBorder};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  cursor: pointer;
  text-align: center;
  transition: transform ${({ theme }) => theme.transitions.durations.short}s;
  background: ${({ theme, selected }) => selected && theme.colors.primary[5]};
  color: ${({ selected }) => selected && 'white'};
  opacity: 1;

  &:hover {
    /* background: ${({ theme }) => theme.colors.primary[5]}; */
    border-color: ${({ theme }) => theme.colors.primary[5]};
    i {
      color: ${({ theme, selected }) =>
        selected ? 'white' : theme.colors.primary[5]};
    }
    span {
      color: ${({ theme, type, selected }) =>
        type === 'service'
          ? selected
            ? 'white'
            : theme.colors.primary[5]
          : selected
          ? 'white'
          : theme.colors.textColor};
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &:active {
    color: white;
  }

  i {
    font-size: 1.5rem;
    margin: ${({ vertical }) => (vertical ? '0 0 0.5rem 0' : '0 1rem 0 0')};
    color: ${({ selected }) => selected && 'white'};
  }
  span {
    color: ${({ selected }) => selected && 'white'};
  }
`

export const TextSpace = styled.div`
  margin-top: 1rem;
  text-align: start;
  color: inherit;

  > * {
    color: inherit;
  }
`

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`

export const SelectableColumnItem = styled(SelectableItem)`
  padding: 0 1rem;
`

export const ListSearch = styled(Search)`
  margin-top: 1rem;
`

export const ListTitle = styled(Text)`
  &::before {
    content: '*';
    color: ${({ theme }) => theme.colors.red[6]};
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun, sans-serif;
  }
`

export const ErrorListWrapper = styled.div`
  margin-bottom: -0.5rem;
  color: ${({ theme }) => theme.colors.red[5]};
`

export const ResultWrapper = styled(motion.div)`
  height: 75%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EmptyWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`
