/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Modal, Row, Col, Input, Table, message, Button } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Context from '../context'
import {
  columnsDelivery,
  columnsFine,
  columnsFuel,
  columnsHolding,
  columnsMaintenance,
  columnsManagement,
  columnsToll,
  columnsVehicle,
  columnsIncident,
  columnsInsurances,
  columnsVehicleLabels,
} from '../ModalWidget/Columns'

const { Search } = Input

interface Params {
  page: number
  per_page: number
  q?: string
}
const ModalWidget = () => {
  const { t } = useTranslation('translate')

  const {
    modalGeneralVisible,
    setModalGeneralVisible,
    canSeePrices,
    paramsGeneral,
  } = useContext(Context)
  const [data, setData] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [params, setParams] = useState<Params>({
    page: 1,
    per_page: 10,
    q: undefined,
  })
  const [total, setTotal] = useState(0)
  const [downloadLoader, setDownloadLoader] = useState(false)
  const [loading, setLoading] = useState(true)
  const { active, endpoint, varName, totalItem, downloadTableModal } =
    modalGeneralVisible
  let columns: any = []
  if (
    varName === 'maintenance_logs' ||
    varName === 'maintenance_days' ||
    varName === 'maintenance_model_expenses' ||
    varName === 'total_maintenance_services' ||
    varName === 'maintenance_expenses_status_card' ||
    varName === 'maintenance_opened_expenses_card' ||
    varName === 'maintenance_closed_expenses_card'
  ) {
    columns = columnsMaintenance(canSeePrices, t)
  } else if (
    varName === 'management_logs' ||
    varName === 'total_management_services'
  ) {
    columns = columnsManagement(canSeePrices, t)
  } else if (
    varName === 'fuel_logs' ||
    varName === 'total_fuel_load' ||
    varName === 'fuel_charges_status' ||
    varName === 'fuel_liters' ||
    varName === 'fuel_kilometers'
  ) {
    columns = columnsFuel(canSeePrices, t)
  } else if (varName === 'total_toll_charges') {
    columns = columnsToll(canSeePrices, t)
  } else if (varName === 'total_vehicle_holdings') {
    columns = columnsHolding(canSeePrices, t)
  } else if (varName === 'total_vehicle_fines') {
    columns = columnsFine(t)
  } else if (varName === 'vehicular_delivery_logs') {
    columns = columnsDelivery(t)
  } else if (varName === 'vehicle_logs') {
    columns = columnsVehicle(t)
  } else if (
    varName === 'vehicle_incident_logs' ||
    varName === 'total_vehicle_incident'
  ) {
    columns = columnsIncident(t)
  } else if (varName === 'total_insurances') {
    columns = columnsInsurances(t)
  } else if (varName === 'client_car_status_histories') {
    columns = columnsVehicleLabels(t)
  }

  const getData = async () => {
    try {
      const res = await axios.get(`${endpoint}`, {
        params: {
          ...params,
          ...paramsGeneral,
          sort: 'created_at:desc',
        },
      })
      setData(res.data)
      setTotal(+res?.headers?.total || 0)
      setLoading(false)
    } catch {
      setLoading(false)
      setData([])
      message.error('No se pudo cargar la lista de la tabla')
    }
  }
  const downloandInfo = async () => {
    setDownloadLoader(true)

    try {
      const res = await axios.get(`${endpoint}`, {
        params: {
          ...params,
          ...paramsGeneral,
          sort: 'created_at:desc',
          download: true,
        },
        responseType: 'blob',
      })
      var FileDownload = require('js-file-download')
      FileDownload(
        res.data,
        `${t(`widgets.${varName}.name`)}-${moment().format('L')}.xlsx`
      )
    } catch {
      message.error(
        'Ocurrió un error al descargar un archivo, intente nuevamente'
      )
    } finally {
      setDownloadLoader(false)
    }
  }
  useEffect(() => {
    if (endpoint !== '') {
      setLoading(true)
      getData()
    }
  }, [params, modalGeneralVisible])

  const handleClickCloseModal = () => {
    setModalGeneralVisible({
      active: false,
      endpoint: '',
      varName: '',
      totalItem: 0,
    })
    setValueSearch('')
    setParams({
      page: 1,
      per_page: 10,
      q: undefined,
    })
  }

  const onChangeParams = ({ current = 1, pageSize = 1 }) => {
    setParams({
      ...params,
      page: current,
      per_page: pageSize,
    })
  }

  const onSearch = (value: string) => {
    setParams({
      ...params,
      q: value,
      page: 1,
    })
    setValueSearch(value || '')
  }
  const onChangeSearch = (value: string) => {
    if (value?.length === 0) {
      setParams({
        ...params,
        q: undefined,
        page: 1,
      })
    }
    setValueSearch(value || '')
  }

  return (
    <Modal
      title={t(`widgets.${varName}.name`)}
      visible={active}
      onCancel={handleClickCloseModal}
      footer={false}
      width="80%"
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={20} sm={12} md={6} lg={6} xl={6}>
          <Search
            placeholder="Buscar"
            onSearch={(value) => onSearch(value.trim())}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChangeSearch(e.target.value)
            }
            style={{ width: '100%' }}
            value={valueSearch}
          />
        </Col>
        {downloadTableModal && (
          <Button
            disabled={downloadLoader}
            type="primary"
            shape="circle"
            icon={
              downloadLoader ? (
                <LoadingOutlined />
              ) : (
                <i className="fa-solid fa-download" />
              )
            }
            onClick={() => downloandInfo()}
          />
        )}
      </Row>
      <div style={{ marginTop: '10px' }}>
        <Table
          scroll={{ x: 1900, y: 450 }}
          dataSource={data}
          rowKey="id"
          columns={columns}
          pagination={{
            total: total,
            pageSizeOptions: ['10', '50', '100'],
            showSizeChanger: true,
            current: params.page,
            pageSize: params.per_page,
          }}
          loading={loading}
          onChange={onChangeParams}
        />
      </div>
    </Modal>
  )
}

export default ModalWidget
