import { Drawer as AntDrawer } from 'antd'
import styled from 'styled-components'

export const ToolbarSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4.5rem;

  .ant-switch {
    background: ${(props) => props.theme.colors.menuBorderColor};
  }
  .ant-switch-checked {
    background: ${(props) => props.theme.colors.primary[5]};
  }
  .ant-badge-dot {
    box-shadow: none;
  }
  .ant-input-group .ant-input {
    background: transparent;
  }

  .ant-input {
    border-color: ${(props) => props.theme.colors.menuBorderColor};
  }

  .ant-input::placeholder {
    color: #aaa;
  }

  .mobile-searchbar {
    margin-right: 0.5rem;
  }
`

export const Logo = styled.img`
  user-select: none;
  height: 2.5rem;

  @media screen and (min-width: 0em) {
    margin: 1.25rem 0 1.1rem;
  }

  @media screen and (min-width: 50em) {
    margin: 1rem 0;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.menuBackground} !important;
`

export const LeftSide = styled.div`
  display: grid;

  grid-gap: 3rem;
  align-items: center;

  @media screen and (min-width: 0em) {
    grid-template-columns: auto;
  }
`

export const RightSide = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  justify-items: start;

  @media screen and (min-width: 0em) {
    grid-gap: 0.5rem;
  }

  @media screen and (min-width: 25em) {
    grid-gap: 1rem;
  }

  .badge {
    margin-right: 12px;
  }

  .ant-badge {
    color: inherit !important;
  }
`

export const Drawer = styled(AntDrawer)`
  .ant-drawer-close {
    padding: 1rem 14px;
  }

  .drawer-menu {
    .button-icon {
      margin-right: 0.5rem;
    }

    border-right-color: transparent;
  }
`
