export const DASHBOARD_ICONS = Object.freeze([
  'fa-solid fa-gauge',
  'fa-solid fa-box-archive',
  'fa-solid fa-box-open',
  'fa-solid fa-calendar-days',
  'fa-solid fa-car',
  'fa-solid fa-car-crash',
  'fa-solid fa-chart-column',
  'fa-solid fa-clipboard-check',
  'fa-solid fa-desktop',
  'fa-solid fa-circle-dollar-to-slot',
  'fa-solid fa-gas-pump',
  'fa-solid fa-hand-holding-dollar',
  'fa-solid fa-hourglass',
  'fa-solid fa-map-location-dot',
  'fa-solid fa-money-check-dollar',
  'fa-solid fa-coins',
  'fa-solid fa-tags',
  'fa-solid fa-users',
  'fa-solid fa-file-invoice-dollar',
  'fa-solid fa-filter-circle-dollar',
  'fa-solid fa-money-bill-1',
  'fa-solid fa-thumbs-up',
  'fa-solid fa-thumbs-down',
  'fa-solid fa-wrench',
])

export type DashboardIcon = typeof DASHBOARD_ICONS[number]

export interface NewDashboardItem {
  icon: string
  name: string
}

export interface DashboardItem {
  id: number
  icon: string
  name: string
}
