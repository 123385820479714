/* eslint-disable @typescript-eslint/no-shadow */
import React, { memo, useEffect, useState } from 'react'
import {
  Column,
  ColumnConfig,
  Line,
  LineConfig,
  Bar,
  BarConfig,
  Area,
  AreaConfig,
  Pie,
  PieConfig,
  DualAxes,
  DualAxesConfig,
} from '@ant-design/plots'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { KeyValue } from '../../../../Types'
import { formatCurrency } from '../../../../Utilities/formatCurrency'
import { formatDecimal } from '../../../../Utilities/formatDecimal'
const MXN = Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
})

interface Props {
  data: { x: string; y: number; series?: string }[]
  params: KeyValue
  typeGraphActive: string
  typeGraph: string
  varName: string
  onlyMoneyGroup: boolean
  dataChart: any
}

const DataDisplay: React.FC<Props> = ({
  data,
  params,
  typeGraph,
  typeGraphActive,
  varName,
  onlyMoneyGroup,
  dataChart,
}) => {
  const { isDark } = useTheme()
  const { t } = useTranslation('translate')
  const widgetCatalogueT = `widgets.${varName}.catalogues.${params.group_by}`
  const [dualAxesCFG, setDualAxesCFG] = useState<any>({
    data: [[], []],
    xField: 'time',
    yField: dataChart?.items ? dataChart?.items : [],
  })
  const LineCFG: LineConfig = {
    data: data,
    xField: 'x',
    yField: 'y',
    seriesField: params.frequency ? 'series' : undefined,
    meta: {
      series: {
        formatter: (v: string) => {
          return t(`${widgetCatalogueT}.options.${v}`, v)
        },
      },
      x: {
        formatter: (v: string) => {
          if (params.frequency) {
            const x = v.split(' ')
            const value = t(`components.common.datesTable.${x[0]}`, x[0])
            return `${value} ${x[1] || ''}`
          } else {
            return t([`${widgetCatalogueT}.options.${v}`, v])
          }
        },
      },
      y: {
        formatter: (v: number) =>
          onlyMoneyGroup
            ? formatCurrency(+v, '$', 0).format()
            : formatDecimal.format(v),
      },
    },
    tooltip: {
      fields: ['x', 'y', 'percent', 'series'],
      formatter: (el) => {
        const percent = el.percent ? `(${el.percent}%)` : ''
        return {
          name: params.frequency
            ? t([`${widgetCatalogueT}.options.${el.series}`, el.series])
            : t([`${widgetCatalogueT}.options.${el.x}`, el.x]),
          value: onlyMoneyGroup
            ? `${MXN.format(+el.y)} ${percent}`
            : `${formatDecimal.format(el.y)} ${percent}`,
        }
      },
    },
    legend: { position: 'bottom' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
    autoFit: true,
    theme: isDark ? 'ala-dark' : 'ala-light',
  }

  const ColumnCFG: ColumnConfig = {
    data: data,
    xField: 'x',
    yField: 'y',
    seriesField: params.frequency ? 'series' : undefined,
    meta: {
      series: {
        formatter: (v: string) => {
          return `${t(`${widgetCatalogueT}.options.${v}`, v)}`
        },
      },
      x: {
        formatter: (v: string) => {
          if (params.frequency) {
            const x = v.split(' ')
            const value = t(`components.common.datesTable.${x[0]}`, x[0])
            return `${value} ${x[1] || ''}`
          } else {
            return t([`${widgetCatalogueT}.options.${v}`, v])
          }
        },
      },
      y: {
        formatter: (v: number) =>
          onlyMoneyGroup
            ? formatCurrency(+v, '$', 0).format()
            : formatDecimal.format(v),
      },
    },
    tooltip: {
      fields: ['x', 'y', 'percent', 'series'],
      formatter: (el) => {
        const percent = el.percent ? `(${el.percent}%)` : ''

        return {
          name: params.frequency
            ? t([`${widgetCatalogueT}.options.${el.series}`, el.series])
            : t([`${widgetCatalogueT}.options.${el.x}`, el.x]),
          value: onlyMoneyGroup
            ? `${MXN.format(+el.y)} ${percent}`
            : `${formatDecimal.format(el.y)} ${percent}`,
        }
      },
    },
    legend: { position: 'bottom' },
    slider: data.length > 60 ? { start: 0, end: 1 } : undefined,
    isGroup: typeGraphActive === 'agruped',
    isStack: typeGraphActive === 'stack',
    theme: isDark ? 'ala-dark' : 'ala-light',
    autoFit: true,
    interactions: [
      {
        type: 'active-region',
        enable: typeGraphActive === 'agruped',
      },
    ],
    connectedArea: {
      style: (oldStyle) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        }
      },
    },
  }
  const BarCFG: BarConfig = {
    data: data,
    height: 200,
    // isGroup: typeGraphActive === 'agruped',
    isStack: true,
    xField: 'y',
    yField: 'x',
    seriesField: params.frequency ? 'series' : undefined,
    tooltip: {
      fields: ['x', 'y', 'percent', 'series'],
      formatter: (el) => {
        const percent = el.percent ? `(${el.percent}%)` : ''
        return {
          name: params.frequency
            ? t([`${widgetCatalogueT}.options.${el.series}`, el.series])
            : t([`${widgetCatalogueT}.options.${el.x}`, el.x]),
          value: onlyMoneyGroup
            ? `${MXN.format(+el.y)} ${percent}`
            : `${formatDecimal.format(el.y)} ${percent}`,
        }
      },
    },
    meta: {
      series: {
        formatter: (v: string) => {
          return t(`${widgetCatalogueT}.options.${v}`, v)
        },
      },
      x: {
        formatter: (v: string) => {
          if (params.frequency) {
            const x = v.split(' ')
            const value = t(`components.common.datesTable.${x[0]}`, x[0])
            return `${value} ${x[1] || ''}`
          } else {
            return t([`${widgetCatalogueT}.options.${v}`, v])
          }
        },
      },
      y: {
        formatter: (v: number) =>
          onlyMoneyGroup
            ? formatCurrency(+v, '$', 0).format()
            : formatDecimal.format(v),
      },
    },

    legend: { position: 'bottom' },
    theme: isDark ? 'ala-dark' : 'ala-light',
    autoFit: true,
    scrollbar: { type: 'vertical' },
    barWidthRatio: 0.5,
    barStyle: {
      lineWidth: 1,
      radius: [2, 2, 0, 0],
    },
  }
  const AreaCFG: AreaConfig = {
    data: data,
    xField: 'x',
    yField: 'y',
    seriesField: params.frequency ? 'series' : undefined,
    meta: {
      series: {
        formatter: (v: string) => {
          return t(`${widgetCatalogueT}.options.${v}`, v)
        },
      },
      x: {
        formatter: (v: string) => {
          if (params.frequency) {
            const x = v.split(' ')
            const value = t(`components.common.datesTable.${x[0]}`, x[0])
            return `${value} ${x[1] || ''}`
          } else {
            return t([`${widgetCatalogueT}.options.${v}`, v])
          }
        },
      },
      y: {
        formatter: (v: number) =>
          onlyMoneyGroup
            ? formatCurrency(+v, '$', 0).format()
            : formatDecimal.format(v),
      },
    },
    legend: { position: 'bottom' },
    // smooth: true,
    tooltip: {
      fields: ['x', 'y', 'percent', 'series'],
      formatter: (el) => {
        const percent = el.percent ? `(${el.percent}%)` : ''
        return {
          name: params.frequency
            ? t([`${widgetCatalogueT}.options.${el.series}`, el.series])
            : t([`${widgetCatalogueT}.options.${el.x}`, el.x]),
          value: onlyMoneyGroup
            ? `${MXN.format(+el.y)} ${percent}`
            : `${formatDecimal.format(el.y)} ${percent}`,
        }
      },
    },
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
    theme: isDark ? 'ala-dark' : 'ala-light',
    autoFit: true,
  }
  const PieCFG: PieConfig = {
    appendPadding: 10,
    data: data,
    angleField: 'y',
    colorField: params.frequency ? 'series' : 'x',
    radius: 0.8,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v: number) =>
          onlyMoneyGroup ? MXN.format(+v) : formatDecimal.format(v),
      },
    },
    label: {
      type: 'inner',
      autoRotate: false,
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'white',
      },
      content: (el) => {
        return `${
          onlyMoneyGroup ? MXN.format(+el.y) : formatDecimal.format(el.y)
        }`
      },
    },
    tooltip: {
      fields: ['x', 'y', 'percent', 'series'],
      formatter: (el) => {
        const percent = el.percent ? `(${el.percent}%)` : ''
        return {
          name: params.frequency
            ? t([`${widgetCatalogueT}.options.${el.series}`, el.series])
            : t([`${widgetCatalogueT}.options.${el.x}`, el.x]),
          value: onlyMoneyGroup
            ? `${MXN.format(+el.y)} ${percent}`
            : `${formatDecimal.format(el.y)} ${percent}`,
        }
      },
    },
    // autoFit: true,
    statistic: {
      title: false,
      // content: {
      //   style: {
      //     color: isDark ? '#fffff95' : '#00000095',
      //     whiteSpace: 'pre-wrap',
      //     overflow: 'hidden',
      //     textOverflow: 'ellipsis',
      //   },
      // },
    },
    legend: {
      layout: 'vertical',
      position: 'right',
      itemName: {
        formatter: (el) => {
          return t([`${widgetCatalogueT}.options.${el}`, el])
        },
      },
    },
    theme: isDark ? 'ala-dark' : 'ala-light',
  }

  useEffect(() => {
    if (typeGraph === 'double_axis') {
      const metaDualAxesCFG: any = {}
      const items = dataChart?.items || []

      if (items.length > 0) {
        items.forEach((el: string) => {
          metaDualAxesCFG[el] = {
            formatter: (v: number) =>
              onlyMoneyGroup
                ? formatCurrency(+v, '$', 0).format()
                : formatDecimal.format(v),
          }
        })
        const dataDualAxesCFG: DualAxesConfig = {
          data: [data, data],
          xField: 'time',
          yField: items,
          meta: {
            //
            time: {
              formatter: (v: string) => {
                if (params.frequency) {
                  const x = v.split(' ')
                  const value = t(`components.common.datesTable.${x[0]}`, x[0])
                  return `${value} ${x[1] || ''}`
                } else {
                  return t([`${widgetCatalogueT}.options.${v}`, v])
                }
              },
            },
            ...metaDualAxesCFG,
          },
          tooltip: {
            customItems: (originalItems: any) => {
              originalItems = originalItems.map((el: any) => {
                return {
                  ...el,
                  name: t([`${widgetCatalogueT}.options.${el.name}`, el.name]),
                }
              })
              return originalItems
            },
          },
          legend: {
            position: 'bottom',
            itemName: {
              formatter: (el) => {
                return t([`${widgetCatalogueT}.options.${el}`, el])
              },
            },
          },
          slider: data.length > 60 ? { start: 0, end: 1 } : undefined,
          theme: isDark ? 'ala-dark' : 'ala-light',
          autoFit: true,
          interactions: [
            {
              type: 'active-region',
              enable: typeGraphActive === 'agruped',
            },
          ],
          geometryOptions: [
            {
              geometry: 'column',
            },
            {
              geometry: 'line',
              lineStyle: {
                lineWidth: 2,
              },
            },
          ],
        }
        setDualAxesCFG(dataDualAxesCFG)
      }
    }
  }, [typeGraph, dataChart, data])

  switch (typeGraph) {
    case 'line':
      return <Line {...LineCFG} />
    case 'column':
      return <Column {...ColumnCFG} />
    case 'bar':
      return <Bar {...BarCFG} />
    case 'pie':
      return <Pie {...PieCFG} />
    case 'area':
      return <Area {...AreaCFG} />
    case 'double_axis':
      return <DualAxes {...dualAxesCFG} />
    default:
      return null
  }
}

// memo helps avoid the chart components from unnecessarily re rendering
const MemoizedDataDisplay = memo(DataDisplay)

export default MemoizedDataDisplay
