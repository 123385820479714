import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react'
import { Select, Row, Col, Typography } from 'antd'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { KeyValue, WidgetGroups, SpecialFilter } from '../../../../Types'
import Context from '../../context'
import { FiltersContainer, IconGraph } from './styles'

const { Option } = Select
const { Text } = Typography
interface Props {
  params: KeyValue
  setParams: Dispatch<SetStateAction<KeyValue>>
  typeGraph: string
  setTypeGraph: Dispatch<SetStateAction<string>>
  typeGraphActive: string
  setTypeGraphActive: Dispatch<SetStateAction<string>>
  loader: boolean
  specialFilter: SpecialFilter
  dateParameter?: boolean
  statusMsr?: boolean
  varName?: string
}

export interface Info {
  id: string
  name?: string
  model?: string
}
interface DataInput {
  id?: number
  name?: string
  value?: string
}

const Filters: React.FC<Props> = ({
  params,
  setParams,
  typeGraph,
  setTypeGraph,
  loader,
  specialFilter,
  dateParameter,
  statusMsr,
  varName,
}) => {
  const [dataSpecialFilter, setDataSpecialFilter] = useState([])
  const { carBrands, carModels, paramsGeneral } = useContext(Context)
  const [models, setModels] = useState<DataInput[]>([])
  const [typeLabels, setTypeLabels] = useState<DataInput[]>([])
  const getClientCarModels = async () => {
    try {
      const res = await axios.get('/ala/asset_models', {
        params: {
          car_brand_id: paramsGeneral?.car_brand_id,
          client_id: paramsGeneral?.client_id,
          user_company_id: paramsGeneral?.user_company_id,
        },
      })

      setModels(res.data)
    } catch (error) {
      setModels([])
    }
  }
  useEffect(() => {
    setModels([...carModels])
  }, [carModels])

  useEffect(() => {
    if (params?.car_brand_id) {
      getClientCarModels()
    }
  }, [params?.car_brand_id])

  const { t } = useTranslation('translate')
  const typeGraphT = 'components.common.filters'
  // const typeGraphT = (val: string) => {
  //   return t(`components.common.filters.${val}`);
  // };
  // const widgetsT = (val: string) => {
  //   return t(`widgets.${varName}.catalogues.${val}`);
  // };

  const getInfoFilter = async (endpoint: string, q = '') => {
    try {
      const res = await axios.get(`/v1${endpoint}?q=${q}`)
      setDataSpecialFilter(res.data)
    } catch (error) {
      setDataSpecialFilter([])
    }
  }

  useEffect(() => {
    if (specialFilter.endpoint) {
      getInfoFilter(specialFilter.endpoint)
    }
  }, [specialFilter])

  const getGraphs = () => {
    return (
      <>
        <Option value="pie" disabled={true}>
          <div>
            <IconGraph className={'fa-solid fa-chart-pie'} />
            {t(`${typeGraphT}.type_graphic.options.pie`)}
          </div>
        </Option>
      </>
    )
  }

  const onSearchFilter = (val: string) => {
    if (specialFilter.endpoint) {
      getInfoFilter(specialFilter.endpoint, val)
    }
  }

  const handleFilterChangeMultiple = (name: string, value = []) => {
    setParams({
      ...params,
      [name]: value.join(),
    })
  }

  const getClientAssetTypeTag = async () => {
    try {
      const res = await axios.get('/v1/client_asset_type_tags')
      setTypeLabels(res.data)
    } catch {
    } finally {
    }
  }

  useEffect(() => {
    getClientAssetTypeTag()
  }, [params])

  return (
    <FiltersContainer>
      <Row gutter={[16, 10]}>
        {/* {specialFilter && (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={specialFilter ? 8 : 12}
            xl={specialFilter ? 8 : 12}
          >
            <Text>{t(`${typeGraphT}.${specialFilter.name}.label`)}</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params[`${specialFilter.params}`] || ''}
              disabled={loader}
              onChange={(v) =>
                setParams((p) => ({ ...p, [`${specialFilter.params}`]: v }))
              }
              placeholder={t(`${typeGraphT}.${specialFilter.name}.placeholder`)}
              showSearch
              onSearch={onSearchFilter}
              allowClear
              filterOption={false}
            >
              {dataSpecialFilter.map((el: Info) => (
                <Option key={el.id} value={el.id}>
                  {el.name || el.model}
                </Option>
              ))}
            </Select>
          </Col>
        )} */}
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={specialFilter ? 8 : 12}
          xl={specialFilter ? 8 : 12}
        >
          <Text>{t(`${typeGraphT}.type_graphic.label`)}</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            value={typeGraph}
            onSelect={(v: string) => setTypeGraph(v)}
            disabled
          >
            {getGraphs()}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Text>Marca</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            mode="multiple"
            showSearch
            value={
              (params?.car_brand_id
                ? params.car_brand_id.split(',').map(Number)
                : undefined) as []
            }
            onChange={(value) =>
              handleFilterChangeMultiple('car_brand_id', value)
            }
            placeholder={t(`${typeGraphT}.select_all`)}
            allowClear
            filterOption={(input: string, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {carBrands.map((el: any) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Text>Modelo</Text>
          <Select
            style={{ width: '100%', marginTop: '5px' }}
            mode="multiple"
            showSearch
            value={
              (params?.car_model_id
                ? params.car_model_id.split(',').map(Number)
                : undefined) as []
            }
            onChange={(value) =>
              handleFilterChangeMultiple('car_model_id', value)
            }
            placeholder={t(`${typeGraphT}.select_all`)}
            allowClear
            disabled={!params?.car_brand_id}
            filterOption={(input: string, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {models.map((el: any) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Col>
        {dateParameter && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>Parámetro de fecha</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params?.filter_range_date}
              onChange={(value) =>
                setParams((p) => ({ ...p, filter_range_date: value }))
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
            >
              <Option value="date_close_provider">Fecha de cierre</Option>
              <Option value="created_at">Fecha de creación</Option>
            </Select>
          </Col>
        )}
        {statusMsr && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>Estatus del folio</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              value={params?.status_msr}
              onChange={(value) =>
                setParams((p) => ({ ...p, status_msr: value }))
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
            >
              <Option value="total">Todos</Option>
              <Option value="open">Abiertos</Option>
              <Option value="closed">Cerrados</Option>
            </Select>
          </Col>
        )}
        {varName === 'client_car_status_histories' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Text>Tipo de etiqueta</Text>
            <Select
              mode="multiple"
              style={{ width: '100%', marginTop: '5px' }}
              value={
                (params?.client_car_status_id
                  ? params.client_car_status_id.split(',').map(Number)
                  : undefined) as []
              }
              onChange={(value) =>
                handleFilterChangeMultiple('client_car_status_id', value)
              }
              placeholder={t(`${typeGraphT}.select_all`)}
              allowClear
            >
              {typeLabels.map((el) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
    </FiltersContainer>
  )
}

export default Filters
