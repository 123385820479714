import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'
import moment from 'moment'
interface Params {
  client_id?: string
  user_company_id?: string
  fleet_type_id?: string
  client_car_division_id?: string
  client_car_location_id?: string
  client_car_status_id?: string
  car_brand_id?: string
  car_model_id?: string
  client_asset_type_tag_id?: string
  from: string
  to: string
}
interface ModalT {
  active: boolean
  varName: string
  endpoint: string
  totalItem?: number
  downloadTableModal?: boolean
}

interface InfoWidget {
  active: boolean
  varName: string
}
interface DataInput {
  id?: number
  name?: string
  value?: string
}
interface Value {
  paramsGeneral: Params
  setParamsGeneral: Dispatch<SetStateAction<Params>>
  modalGeneralVisible: ModalT
  setModalGeneralVisible: Dispatch<SetStateAction<ModalT>>
  canSeePrices: boolean
  setCanSeePrices: Dispatch<SetStateAction<boolean>>
  infoWidget: InfoWidget
  setInfoWidget: Dispatch<SetStateAction<InfoWidget>>
  newWidgetCreate: number
  setNewWidgetCreate: Dispatch<SetStateAction<number>>
  carBrands: DataInput[]
  setCarBrands: Dispatch<SetStateAction<DataInput[]>>
  carModels: DataInput[]
  setCarModels: Dispatch<SetStateAction<DataInput[]>>
}
interface Props {
  children: ReactNode
}

const paramsGeneralData: Params = {
  from: moment().startOf('year').format('DD/MM/YYYY'),
  to: moment().format('DD/MM/YYYY'),
}

const modalData: ModalT = {
  active: false,
  varName: '',
  endpoint: '',
}
const infoWidgetData: InfoWidget = {
  active: false,
  varName: '',
}

const contextDefaultValues: Value = {
  paramsGeneral: paramsGeneralData,
  modalGeneralVisible: modalData,
  canSeePrices: true,
  setParamsGeneral: () => {},
  setModalGeneralVisible: () => {},
  setCanSeePrices: () => {},
  infoWidget: infoWidgetData,
  setInfoWidget: () => {},
  newWidgetCreate: 0,
  setNewWidgetCreate: () => {},
  carBrands: [],
  setCarBrands: () => {},
  carModels: [],
  setCarModels: () => {},
}

const Context = createContext<Value>(contextDefaultValues)
export const Provider: React.FC<Props> = ({ children }) => {
  const [canSeePrices, setCanSeePrices] = useState(false)
  const [newWidgetCreate, setNewWidgetCreate] = useState(0)

  const [infoWidget, setInfoWidget] = useState(infoWidgetData)
  const [carBrands, setCarBrands] = useState<DataInput[]>([])
  const [carModels, setCarModels] = useState<DataInput[]>([])

  const [modalGeneralVisible, setModalGeneralVisible] = useState(modalData)
  const [paramsGeneral, setParamsGeneral] = useState({
    from: moment().startOf('year').format('DD/MM/YYYY'),
    to: moment().format('DD/MM/YYYY'),
  })
  const value: Value = {
    paramsGeneral,
    setParamsGeneral,
    modalGeneralVisible,
    setModalGeneralVisible,
    canSeePrices,
    setCanSeePrices,
    infoWidget,
    setInfoWidget,
    newWidgetCreate,
    setNewWidgetCreate,
    carBrands,
    setCarBrands,
    carModels,
    setCarModels,
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
export const Consumer = Context.Consumer
export default Context
