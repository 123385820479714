import { Card as AntCard } from 'antd'
import styled from 'styled-components'

interface CardProps {
  shadow: number
}

export const Card = styled(AntCard)<CardProps>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: ${({ shadow, theme }) =>
    shadow ? `0 0 10px 5px ${theme.colors.primary[5]}` : ''};
  transition: 0.5s;
`

export const BodyWrapper = styled.div`
  height: 100%;
`
