import { Tag as AntTag, Card as AntCard } from 'antd'
import styled from 'styled-components'
const { CheckableTag } = AntTag

export const FiltersContainer = styled.div`
  margin-bottom: 1rem;
`

export const IconGraph = styled.i`
  margin-right: 0.8rem;
`
export const TypeGraph = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  margin-top: 1rem;
`

export const TopWrapper = styled.div`
  display: grid;

  @media screen and (min-width: 0) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  @media screen and (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  @media screen and (min-width: 65em) {
    max-width: 45rem;
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  margin-top: 1rem;
  > :first-child {
    margin-right: 0.5rem;
  }
`

interface SubMenuIconProps {
  noMargin?: boolean
}

export const SubMenuIcon = styled.i<SubMenuIconProps>`
  margin-left: ${({ noMargin }) => (noMargin ? 0 : '0.5rem')};
  font-size: 0.6rem;
`

interface TagProps {
  hasSub?: boolean
}

export const Tag = styled(CheckableTag)<TagProps>`
  user-select: none;

  /* ${({ hasSub }) =>
    hasSub &&
    `
    &:active {
      background: transparent;
    }
  `} */
`

export const ItemContent = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface CardProps {
  shadow: number
}

export const Card = styled(AntCard)<CardProps>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: ${({ shadow, theme }) =>
    shadow ? `0 0 10px 5px ${theme.colors.primary[5]}` : ''};
  transition: 0.5s;
`
export const BodyWrapper = styled.div`
  height: 100%;
`

export const SeeTable = styled.div`
  text-align: center;
  margin-top: 1rem;
`
