export const CARDLAYOUT = {
  w: 3,
  h: 2,
  x: 0,
  y: Infinity,
  minH: 2,
  minW: 3,
}
export const MAPLAYOUT = {
  w: 4,
  h: 5,
  x: 0,
  y: Infinity,
  minW: 4,
  minH: 4,
}
export const GRAPHLAYOUT = {
  w: 6,
  h: 9,
  x: 0,
  y: Infinity,
  minW: 6,
  minH: 7,
}
export const PERCENTAGECHART = {
  w: 12,
  h: 8,
  x: 0,
  y: Infinity,
  minW: 6,
  minH: 6,
}
export const STATUSCARD = {
  w: 5,
  h: 3,
  x: 0,
  y: Infinity,
  minH: 3,
  minW: 4,
}

export const REPORTCARD = {
  w: 12,
  h: 10,
  x: 0,
  y: Infinity,
  minH: 3,
  minW: 4,
}

export const REPORTTABLECARD = {
  w: 12,
  h: 8,
  x: 0,
  y: Infinity,
  minH: 3,
  minW: 4,
}
