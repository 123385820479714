import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Modal, Form, Result, Button, message } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import ContextGlobal from '../../../context'
import { DashboardIcon, DashboardItem, NewDashboardItem } from '../../../Types'
import NewDBForm from './Form'
import { ResultWrapper } from './styles'
interface INewMenu {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  updateDashboardList(
    newDashboard: DashboardItem,
    operation?: 'update' | 'delete'
  ): void
}

const INITIAL_VALUES: NewDashboardItem = { name: '', icon: 'fas fa-archive' }

const NewDashboardForm: React.FC<INewMenu> = ({
  showModal,
  setShowModal,
  updateDashboardList,
}) => {
  const { baseUrl } = useContext(ContextGlobal)
  const { t } = useTranslation('translate', { keyPrefix: 'new-dashboard' })
  const [form] = Form.useForm<NewDashboardItem>()
  const [selectedIcon, setSelectedIcon] = useState<DashboardIcon>(
    INITIAL_VALUES.icon
  )
  const [newDashboardID, setNewDashboardID] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { transitions } = useTheme()

  const handleCreateDashboard = async (values: NewDashboardItem) => {
    try {
      setLoading(true)
      const rawRes = await fetch(`${baseUrl}/api/ala/user_dashboards`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const { data } = await rawRes.json()
      form.resetFields()
      setSelectedIcon(INITIAL_VALUES.icon)
      setNewDashboardID(data.id)
      setShowSuccess(true)
      updateDashboardList(data)
    } catch (e) {
      message.error(t('request-error'))
    } finally {
      setLoading(false)
    }
  }

  const onFormSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        handleCreateDashboard(values)
      })
      .catch(() => {
        // console.log('Validate Failed:', info);
      })
  }

  const onFormCancel = () => {
    setSelectedIcon(INITIAL_VALUES.icon)
    setShowSuccess(false)
    setShowModal(false)
    setNewDashboardID(0)
  }

  const onIconSelect = (icon: DashboardIcon) => {
    setSelectedIcon(icon)
    form.setFieldsValue({ icon })
  }

  return (
    <Modal
      data-tut="reactour__create_newDashboard--observe"
      visible={showModal}
      centered
      onCancel={onFormCancel}
      title={t('form-title')}
      onOk={onFormSubmit}
      okButtonProps={{ loading }}
      destroyOnClose
      okText={t('submit-btn')}
      footer={showSuccess ? null : undefined}
    >
      <AnimatePresence>
        {showSuccess && (
          <ResultWrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: transitions.durations.short },
            }}
          >
            <Result
              status="success"
              title={t('success-title')}
              subTitle={t('success-subtitle')}
              extra={[
                <Link to={`/dashboard/${newDashboardID}`} key="open-dashboard">
                  <Button type="primary" onClick={onFormCancel}>
                    {t('go-to-dashboard')}
                  </Button>
                </Link>,
                <Button
                  key="create-another"
                  onClick={() => setShowSuccess(false)}
                >
                  {t('create-another')}
                </Button>,
              ]}
            />
          </ResultWrapper>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false}>
        {!showSuccess && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: transitions.durations.short },
            }}
          >
            <NewDBForm
              form={form}
              initialValues={INITIAL_VALUES}
              selectedIcon={selectedIcon}
              onIconSelect={onIconSelect}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default NewDashboardForm
