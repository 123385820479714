import styled, { DefaultTheme } from 'styled-components'

interface WidgetWrapperProps {
  contrastResizeHandle: boolean
}

const getBorderColor = (contrastResizeHandle: boolean, theme: DefaultTheme) => {
  if (contrastResizeHandle) {
    return '#FFF'
  }
  return theme.colors.primary[5]
}

export const WidgetWrapper = styled.div<WidgetWrapperProps>`
  &::after {
    cursor: se-resize;
    content: '';
    position: absolute;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 0 0 1rem 0 !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-right: 3px solid
      ${({ contrastResizeHandle, theme }) =>
        getBorderColor(contrastResizeHandle, theme)} !important;
    border-bottom: 3px solid
      ${({ contrastResizeHandle, theme }) =>
        getBorderColor(contrastResizeHandle, theme)} !important;
    opacity: 0;
    transition: opacity ${({ theme }) => theme.transitions.durations.normal}s;
  }

  &:hover::after,
  &:active::after {
    opacity: 1;
  }
`

export const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  &::after {
    z-index: 2;
    cursor: se-resize;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 0 0 1rem 0 !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-color: transparent !important;
  }
`
