import React, { useContext } from 'react'
import { Drawer } from 'antd'
import { useMediaQuery } from 'beautiful-react-hooks'
import { useSSR, useTranslation } from 'react-i18next'
import Context from '../context'

const WidgetInfo = () => {
  const { infoWidget, setInfoWidget } = useContext(Context)
  const { t } = useTranslation('translate')
  const mobile = useMediaQuery('(max-width: 640px)')
  const handleCloseDrawer = () => {
    setInfoWidget({ active: false, varName: '' })
  }

  return (
    <Drawer
      placement="right"
      open={infoWidget.active}
      onClose={handleCloseDrawer}
      width={mobile ? '80%' : '400px'}
      title={t(`widgets.${infoWidget.varName}.name`)}
      footer={false}
      destroyOnClose
    >
      <p>{t(`widgets.${infoWidget.varName}.information`)}</p>
    </Drawer>
  )
}

export default WidgetInfo
